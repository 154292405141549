import { ToastContainer } from 'react-toastify';
import './App.css';
import LandingPage from './pages/landing-page/landing-page';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import SignPage from './pages/Signup/sign-page';
import OTP from './pages/Signup/otp/otp';
import Email from './pages/Signup/emailpage/email';
import DashBoard from './pages/Dashboard/dashboard';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Login from './pages/Signup/loginpage/login';
import Terms from './pages/Signup/terms/terms';
import Privacypolicy from './pages/Signup/privacy_policy/privacy_policy';
import Boostscore from './pages/boost-score/boost-score';
import NavigateSetter from './config/navigation_service';
import ConfirmAccount from './pages/Signup/confirm-account/confirm-account';
import ForgotPassword from './pages/Signup/forgot-password/forgot-password'
import ResetPassword from './pages/Signup/forgot-password/reset-password'
import EmailPassword from './pages/Signup/forgot-password/email-password'
import EmailVerification from './pages/Signup/email-verification/email-verification'
import Integration from './pages/Dashboard/integration/integration';
import Developer from './pages/Dashboard/developer/developer';
import PasswordChanged from './pages/Signup/forgot-password/password-changed';
import Users from './pages/Dashboard/users/users'
import ProdUsers from './pages/Dashboard/Prod-Users/prod-users';
import CredDharma from './pages/creddharma/creddharma';
import ProdDeveloper from './pages/Dashboard/developer/prod-developer';
import BillingPage from './pages/Billing/billingpage';
import Profile from './pages/Dashboard/profile/profile';
import ChangePassword from './pages/Dashboard/profile/change_password/change_password';
import Demo from './pages/demo/demo';
import Download from './pages/demo/download';
import MyCustomers from './pages/Dashboard/my-customers/my-customers';


function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter >
            <NavigateSetter />
            <Routes>
              <Route path='/' element={<SignPage />}></Route>
              <Route path='/confirm-account' element={<ConfirmAccount />}></Route>
              <Route path='/forgot-password' element={<ForgotPassword />}></Route>
              <Route path='/email-password' element={<EmailPassword />}></Route>
              <Route path='/reset-password' element={<ResetPassword />}></Route>
              <Route path='/integration' element={<Integration />}></Route>
              <Route path='/email-verification' element={<EmailVerification />}></Route>
              <Route path='/password-changed' element={<PasswordChanged />}></Route>
              <Route path='/register' element={<SignPage />}></Route>
              <Route path='/dashboard' element={<DashBoard />}></Route>
              <Route path='/developer' element={<Developer />}></Route>
              <Route path='/prod-developer' element={<ProdDeveloper />}></Route>
              <Route path='/login' element={<Login />}></Route>
              <Route path='/terms' element={<Terms />}></Route>
              <Route path='/privacypolicy' element={<Privacypolicy />}></Route>
              <Route path='/users' element={<Users />}></Route>
              <Route path='/prod-users' element={<ProdUsers />}></Route>
              <Route path='/boostscore' element={<Boostscore />}></Route>
              <Route path='/creditdharma' element={<CredDharma />}></Route>
              <Route path='/uat-creditdharma' element={<CredDharma />}></Route>
              <Route path='/billing' element={<BillingPage />}></Route>
              {/* <Route path='/banking' element={<Banking />}></Route> */}
              <Route path='/profile' element={<Profile />}></Route>
              <Route path='/change-password' element={<ChangePassword />}></Route>
              <Route path='/demo' element={<Demo />}></Route>
              <Route path='/uat-demo' element={<Demo />}></Route>
              <Route path='/uat-download' element={<Download />}></Route>
              <Route path='/download' element={<Download />}></Route>
              <Route path='/my-customers' element={<MyCustomers />}></Route>

              {/* <Route path='/otp' element={<OTP />}></Route> */}
              {/* <Route path='/emailverification' element={<Email />}></Route> */}
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
