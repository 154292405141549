import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import SvgCopyIcon from '../../Component/svg-copy-icon';

const copied = () => {
    toast("Copied!", {
        toastId: 100, progressStyle: {
            background: '#4243F1', // Change this to the desired color
        }, icon: SvgCopyIcon,
        position: toast.POSITION.BOTTOM_RIGHT, className: 'toast-message'
    });
}
const ShareURLDialogContent = ({ link }) => (
    <>
        <p className='p-0 m-0 my-2 ps-1' style={{ fontWeight: 'bold', color: "#344054" }}>Share URL</p>
        <div className="info-details-box row p-0 m-0 px-2 py-2"
            style={{
                overflowX: 'scroll',
                overflowY: 'scroll'
            }}>
            <p className='p-0 m-0 ps-1' style={{ fontWeight: 'light', color: "#727272" }}>{link}</p>
        </div>
        <div className=" px-1 mt-4 mb-2">
            <CopyToClipboard
                onCopy={copied}
                text={link}>
                <div className="upload-statement-btn text-center cursor-pointer">Copy URL</div>
            </CopyToClipboard>
        </div>
    </>
);

export default ShareURLDialogContent;
