import React, { useState, useRef, useEffect } from "react";

export default function withClickOutside(WrappedComponent) {
    const Component = (props) => {
        const [showDropDown, setShowDropDown] = useState(false);

        const ref = useRef();

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref != null && ref.current != null && event != null) {
                    if (!ref.current.contains(event.target)) {
                        setShowDropDown(false);
                    }
                }

            };
            document.addEventListener("mousedown", handleClickOutside);
        }, [ref]);

        return <WrappedComponent showDropDown={showDropDown} setShowDropDown={setShowDropDown} ref={ref} {...props} />;
    };

    return Component;
}
