import React from 'react'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate,Link } from 'react-router-dom';
import '../../../App.css';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import '../forgot-password/forgot-password.css'
import { resendEmail } from '../../../redux/actions/user_action';
const Logo = require("../../../images/assets/Logo.png");

const PasswordChanged = (props) => {
    const [loader, setLoader] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();

    const login = () => {
        navigate('/login')
    }


    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo}  className='img-fluid'/></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Login now with your new password
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mt-2 pb-3'>
                                        Password Changed
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        You have successfully changed your password!
                                    </p>
                                    <p className='fw-semibold mt-md-2'>
                                        <span className='fw-bold'>Login</span>  with your new password to get started with our services
                                    </p>

                                    <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                        <div className='row g-2 align-items-center'>
                                        
                                            <div className='col-12 col-md-8 order-1 order-md-2 text-end'>
                                                <div className='text-start my-2 my-sm-0'> 
                                                    <button type='button' className='next-button' onClick={() => {
                                                        login()
                                                    }}>Login</button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default PasswordChanged