import httpClientB2BProd from "../../config/prod-b2b";

const b2bProdLogin = data =>
    new Promise((resolve, reject) => {
        httpClientB2BProd({
            method: 'POST',
            url: 'api/v1/b2b/login',
            data: data,
           })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error);
            });
    });

export {
    b2bProdLogin
}