import React from 'react'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import '../../../App.css';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import '../forgot-password/forgot-password.css'
import { resendEmail } from '../../../redux/actions/user_action';
import ErrorMessage from '../../Dashboard/error-message/error-message';
const Logo = require("../../../images/assets/Logo.png");

const EmailVerification = (props) => {
    const [loader, setLoader] = useState(false);
    const [userExists, setUserExists] = useState();
    const [backLogin, setBackLogin] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [showError, setShowError] = useState(false);
    const [emailVerify, setEmailVerify] = useState("");

    const login = () => {
        navigate('/login')
    }

    const sendEmail = () => {
        setUserExists('');

        setShowError(false)
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (state?.email === '') {
            setShowError(true)
        } else if (!rex.test(state?.email)) {
            setEmailVerify('Please enter valid email address');
        } else {
            setLoader(true);
            const param = {
                email: state.email
            }
            resendEmail(param).then(resp => {
                setLoader(false);
                if (resp.statusCode === "SUCCESS") {
                    setMessage({ "success": resp.statusMessage })
                }
            }).catch(error => {
                if (error?.response.data.errorCode === "B2B_RESEND_EMAIL_409") {
                    //setUserExists(error?.response.data.errorMessage)
                    setMessage({ "error": error?.response.data.errorMessage })
                    setBackLogin(true)
                }
                setLoader(false);
            })
        }
    }

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Secure and safe registration
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mt-2 pb-3'>
                                        Email Verification
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        We have sent an email to <span className='fw-bold'>{state?.email}</span>
                                    </p>
                                    <div className='form-input-box login-box'>
                                        <section>
                                            <ErrorMessage message={message} />
                                        </section>

                                        <p className='fw-semibold mt-md-2'>
                                            Just click <span className='fw-bold'>Verify my email</span> button in the email to complete your sign up.
                                        </p>

                                        <p className='fw-semibold mt-md-2'>
                                            If you don’t see the email, you may need to <span className='fw-bold'>check your spam folder.</span>
                                        </p>
                                        {(showError && state?.email === '') && <span className="demo-tetx">Email id not found!</span>}
                                        {emailVerify !== '' && <span className="demo-tetx mt-2">{emailVerify}</span>}

                                        <div className='form-inner-box form-button-box mt-1 mt-md-5 cursor-pointer'>
                                            <div className='align-items-center p-0 m-0 d-flex flex-row justify-content-between 
                                            px-1 px-md-0 px-sm-0 px-lg-0'>
                                                {!backLogin && <div className='order-1 '>
                                                    <div className='text-center text-sm-start my-2 my-sm-0'>
                                                        <span>
                                                            <span className='new-user-text '>Didn’t get the link?</span>
                                                        </span>
                                                    </div>
                                                </div>}
                                                {!backLogin && <div className=' order-2 text-end'>
                                                    <div className='text-start my-2 my-sm-0'>
                                                        <button type='button' className='next-button' onClick={() => {
                                                            sendEmail()
                                                        }}>Resend Link</button>
                                                    </div>
                                                </div>}

                                                {backLogin && <div className='col-12 col-md-8 order-1 order-md-2 text-end'>
                                                    <div className='text-start my-2 my-sm-0'>
                                                        <button type='button' className='next-button' onClick={() => {
                                                            login()
                                                        }}>Back To Login</button>
                                                    </div>
                                                </div>}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default EmailVerification