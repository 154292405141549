import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { store } from "../redux/store";
import { Navigate } from "react-router-dom";
import { logOut } from "../redux/actions/auth_action";
import React from "react";
import History from "./history";
import config from './../config.json'


export const navigationRef = React.createRef();

const httpClientUser = axios.create({
    baseURL: config.apiUrl,
});

// export const setAuthHeader = async (token = "") => {
    
//     const localToken =
//         token == ""
//             ? (await AsyncStorage.getItem("token"))
//                 ? `${await AsyncStorage.getItem("token")}`
//                 : ""
//             : token;
//     if (localToken != "") {
//         httpClient.defaults.headers = { Authorization: `Bearer ${localToken}` };
//     } else {
//         httpClient.defaults.headers = {};
//     }
// };

httpClientUser.interceptors.response.use(
    (response) => response,
    async (error) => {
        
        if (error && error.response && error.response.status === 401) {
            // store.dispatch(logOut(""));
            // History.navigate("/login");
        }
        return Promise.reject(error);
    }
);

// setAuthHeader();

export default httpClientUser;
