import { ToastContainer } from 'react-toastify';
import '../../App.css';
import './sign-page.css';
import CreateAccount from './createanaccount/createanaccount';
function SignPage() {

    return (
        <>
            <CreateAccount />
            <ToastContainer />
        </>
    );
}

export default SignPage;