import axios from "axios";
import React from "react";
import config from './../config.json'
import { store } from "../redux/store";
import { Navigate } from "react-router-dom";
import { logOut } from "../redux/actions/auth_action";
export const navigationRef = React.createRef();


const url = new URL(window.location.href)
const pathname = url.pathname;

const httpClientDemoB2B = axios.create({
    baseURL: pathname === "/download" ? config.prodApiUrl : config.apiUrl,
});

export const setDemoSDKAuthHeader = async () => {

    let url = new URL(window.location.href);
    let searchParams = url.searchParams;

    const tokenParam = searchParams.get('token')
    const statusParam = tokenParam ? tokenParam.split('?status=') : ""
    const token = statusParam[0] ? statusParam[0] : "";

    const localToken =
        token == ""
            ? (token)
                ? `${token}`
                : ""
            : token;
    if (localToken != "") {
        httpClientDemoB2B.defaults.headers = { Authorization: `Bearer ${localToken}` };
    } else {
        httpClientDemoB2B.defaults.headers = {};
    }
};

httpClientDemoB2B.interceptors.response.use(
    (response) => response,
    async (error) => {

        if (error && error.response && error.response.data && error.response.data.errorCode === "B2B_UNAUTHORIZED_401") {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        if (error && error.code && (error.code === "ERR_NETWORK" || error.code === "B2B_UNAUTHORIZED_401")) {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        if (error && error.response && error.response.status === 401) {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        return Promise.reject(error);
    }
);

setDemoSDKAuthHeader();

export default httpClientDemoB2B;
