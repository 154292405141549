import { Link } from 'react-router-dom';
import './header.css';
import '../../../App.css'
import { useSelector } from 'react-redux';
const Logo = require("../../../images/assets/MobileViewLogo.png");

function Header() {

    const { user } = useSelector(state => {
        return state.authReducer;
    });
    return (
        <>
            <div className='container'>
                <nav className="navbar navbar-expand-lg mt-1 mt-md-3 pt-3 pt-md-2 justify-content-between" aria-label="Eleventh navbar example">
                    <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
                        <img src={Logo} className='img-fluid' />
                    </a>
                    <div className='signup-button-box'>
                        <Link className='btn-solid-sm dashboadrd-buttons-text' to='/register'>{user?.access_token ? 'Dashboard' : 'Sign Up/Login'}</Link>
                        <Link className='btn-solid-sm mobile-buttons-text' to='/register'>{user?.access_token ? 'Dashboard' : 'Sign up for Early Access '}</Link>
                    </div>
                </nav>

            </div>
        </>
    );
}

export default Header;