import React from 'react';
import './score-range-dailog.css'
import '../../../../../App.css'
import 'react-dropdown/style.css';

const ScoreDetails = (props) => {

    return (
        <>
            <div className='container'>
                <div className='score-detail-range-main-box'>
                    <div className='row text-center my-2 g-2'>
                        <div className='col-12'>
                            <div className='sore-range-main-boxes excellent-score-range-main-box'>
                                <div className='d-flex align-items-start justify-content-between'>
                                    <div className='d-flex align-items-start justify-content-between'>
                                        <i className="bi bi-circle-fill excellent-score-range-icon circle-range-icon"></i>
                                        <p className='score-range-texts excellent-score-range-text ms-2'>Excellent</p>
                                    </div>
                                    <div>
                                        <p className='score-range-numbers'>Range: 780-900</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='sore-range-main-boxes good-score-range-main-box'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex justify-content-between'>
                                        <i className="bi bi-circle-fill good-score-range-icon circle-range-icon"></i>
                                        <p className='score-range-texts good-score-range-text ms-2'>Good</p>
                                    </div>
                                    <div>
                                        <p className='score-range-numbers'>Range: 706-779</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='sore-range-main-boxes average-score-range-main-box'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex justify-content-between'>
                                        <i className="bi bi-circle-fill average-score-range-icon circle-range-icon"></i>
                                        <p className='score-range-texts average-score-range-text ms-2'>Average</p>
                                    </div>
                                    <div>
                                        <p className='score-range-numbers'>Range: 631-705</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='sore-range-main-boxes poor-score-range-main-box'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex justify-content-between'>
                                        <i className="bi bi-circle-fill poor-score-range-icon circle-range-icon"></i>
                                        <p className='score-range-texts poor-score-range-text ms-2'>Poor</p>
                                    </div>
                                    <div>
                                        <p className='score-range-numbers'>Range: 300-630</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='mt-3 mb-0'>
                                <button className='btn gotit-buttons' onClick={() => { props.closeScoreDetails() }}>
                                    OK, Got it.
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScoreDetails;