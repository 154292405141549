import React from 'react'
import '../../../App.css';
import Header from '../../landing-page/header/header';
import Footer from '../../landing-page/Footer/Footer';
const Logo = require("../../../images/assets/Logo.png");

const Refundpolicy = () => {
    return (
        <>
            <Header></Header>
            <div className="container">

                <h2 className="mt-5 mb-4">Boost.Money (FinBoost Software Private Limited) Refund Policy</h2>

                <p>Due to the nature of our software as a service (SaaS), once activated, our subscriptions are generally
                non-refundable. Our subscription service is prepaid in most cases with credits applied towards usage.</p>
                <p>For any usage failures, credits would not be utilized. If erroneous credits are utilized then they will be
                duly re-credit back to the account during reconciliation process.</p>
                <p>Further Refunds may be considered on a case-by-case basis for exceptional circumstances, at the sole discretion of FinBoost Software
                Private Limited.</p>
           </div>
            <Footer className="mt-5"></Footer>
        </>
    )
}

export default Refundpolicy