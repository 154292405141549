import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../App.css'
import './menubar.css'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../../redux/actions/auth_action';
import ProfileDropdown from '../profile/profile_component/profile_dropdown';
import Profile from '../profile/profile';
// const Logo = require("../../../images/assets/Logo.png");
const smLogo = require("../../../images/assets/smlogo.png");
const Avatar_logo = require("../../../images/avatar-profile.png");
const Logo = require("../../../images/assets/MobileViewLogo.png");



function MenuBar() {
    const [currentRoute, setCurrentRoute] = useState('/');
    const [showProfile, setShowProfile] = useState(false);
    const [showInt, setShowInt] = useState(false);
    const [showUAT, setShowUAT] = useState(false);
    const [showProd, setShowProd] = useState(false);
    const [showCustomer, setShowMyCustomer] = useState(false);
    const [isConfingShowProd, setConfigShowProd] = useState(true);
    const [isConfigShowScurbData, setConfigShowScurbData] = useState(false);
    const [isConfigShowScurbProdData, setConfigShowScurbProdData] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var fullName = localStorage.getItem("fullName")

    useEffect(() => {
        setShowProfile(false)
        setCurrentRoute(window.location.pathname);
        setShowUAT(false)
        setShowInt(false)
        setShowProd(false)
        setConfigShowProd(JSON.parse(localStorage.getItem('allConfiguration'))?.productionEnable);
        setConfigShowScurbData(JSON.parse(localStorage.getItem('allConfiguration'))?.scrubDataEnable)
        setConfigShowScurbProdData(JSON.parse(localStorage.getItem('allConfigurationProd'))?.scrubDataEnable)
        const isShowInt = window.location.pathname.includes('integration');
        if (isShowInt) {
            setShowInt(true)
        }
        const isShowUAT = window.location.pathname === '/users' || window.location.pathname.startsWith('/users/')
            || window.location.pathname === '/developer' || window.location.pathname.startsWith('/developer/')
            || (window.location.pathname === '/my-customers' && window.location.search === '?uat') ||
            (window.location.pathname.startsWith('/my-customers') && window.location.search.startsWith('?uat'));
        if (isShowUAT) {
            if (window.location.search === '?uat') {
                setCurrentRoute(window.location.pathname + window.location.search);
            }
            setShowUAT(true)
        }
        const isShowProd = window.location.pathname === '/prod-users' || window.location.pathname.startsWith('/prod-users/')
            || window.location.pathname === '/prod-developer' || window.location.pathname.startsWith('/prod-developer/')
            || (window.location.pathname === '/my-customers' && window.location.search === '?prod') ||
            (window.location.pathname.startsWith('/my-customers') && window.location.search.startsWith('?prod'));
        if (isShowProd) {
            if (window.location.search === '?prod') {
                setCurrentRoute(window.location.pathname + window.location.search);
            }
            setShowProd(true)
        }
        const isShowMyCustomer = window.location.pathname === '/my-customers' || window.location.pathname.startsWith('/my-customers/')
            || window.location.pathname === '/my-customers' || window.location.pathname.startsWith('/my-customers/');
        if (isShowMyCustomer) {
            setShowMyCustomer(true)
        }
    }, []);

    // const getFullName = async () => {
    //     var fullName = await 
    //     console.log(fullName)
    //     // if (fullName) {

    //     // }
    // }

    // const ScoreBoost = () => {
    //     navigate('/boostscore')
    // }
    // const ScoreCredit = () => {
    //     navigate('/dashboard')
    // }
    const handleOutsideClick = (event) => {
        if (!event.target.closest('.profile-click')) {
            if (!event.target.closest('.pro-dropdown-avatar')) {
                setShowProfile(false); // Close the profile dropdown if clicked outside
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // T

    const ScoreBoost = () => {
        navigate('/dashboard')
    }
    const ScoreCredit = () => {
        navigate('/dashboard')
    }


    const Integration = () => {
        navigate('/integration')
    }

    const Developer = () => {
        navigate('/developer')
    }

    const ProdDeveloper = () => {
        navigate('/prod-developer')
    }

    const B2BUsers = () => {
        navigate('/users')
    }
    const ProdUsers = () => {
        navigate('/prod-users')
    }

    const MyCustomers = (fromWhere) => {
        if (fromWhere === 'prod') {
            navigate('/my-customers?prod')
        } else {
            navigate('/my-customers?uat')
        }
    }

    const logout = async () => {
        var result = await dispatch(logOut());
        if (result) {
            navigate('/login')
        }
    }

    const profileLink = () => {
        if (showProfile) {
            setShowProfile(false)
        } else {
            setShowProfile(true)
        }
    }

    return (
        <>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light" aria-label="Light offcanvas navbar">
                <div className="container-fluid">
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='d-flex align-items-center'>
                            <button className="navbar-toggler mx-2 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className='ms-0 '>
                                <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
                                    <img src={Logo} className='ms-2 head-img-logo' />
                                    <img src={smLogo} className='img-fluid sm-logo' width="23" />
                                </a>

                                {/* <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
                                    <img src={Logo} className='head-img-logo' />
                                    <div className='sm-logo-img-box'>
                                        <img src={smLogo} className='img-fluid sm-logo' width="23" />
                                    </div>
                                </a> */}
                            </div>
                        </div>
                        <div className='d-flex align-right-center'>


                            {/* <Link className='profile-txt' onClick={() => { profileLink() }}> Profile</Link> */}
                            <div className="pro-dropdown-circular me-3" onClick={() => { profileLink() }}>
                                <div className="pro-dropdown-avatar">{fullName ? fullName.charAt(0).toUpperCase() : ''}</div>
                            </div>
                            {showProfile &&
                                <div className='profile-click'><ProfileDropdown setShowProfile={setShowProfile}></ProfileDropdown></div>}
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-start" tabndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <button type="button" className="btn text-light nav_icon" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                            <h5 className="offcanvas-title" id="offcanvasNavbarLightLabel"></h5>
                        </div>
                        <div className="offcanvas-body align-self-end canvas-body-box">
                            <ul className="navbar-nav mt-5">
                                <div className='menu-box my-3 mt-3'>
                                    <ul>

                                        <li>
                                            <a className={currentRoute === "/users" || currentRoute === "/developer"
                                                || currentRoute === "/my-customers?uat" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { B2BUsers() }} data-bs-toggle="collapse" href="#collapseExampleTest" role="button" aria-expanded="false" aria-controls="collapseExampleTest">
                                                <div className="flex-grow-1">
                                                    <i className="bi bi-send nav_icon"></i>
                                                    <span className="nav_name ps-2">Trial Sandbox</span>
                                                </div>
                                                <i className="bi bi-chevron-down nav_icon nav-icons"></i>
                                            </a>
                                            <div className={`collapse ${showUAT ? 'show' : ''}`} id="collapseExampleTest">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="users#sdk">SDK</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="users#userslist">Customers</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" data-bs-toggle="collapse" href="#" onClick={() => { Developer() }}>Keys</a>
                                                        </li>
                                                        {isConfigShowScurbData && <li>
                                                            <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { MyCustomers('uat') }}>Scurb Users</a>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>


                                        {/* <li>
                                            <a className={currentRoute === "/dashboard" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { ScoreBoost() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-expanded="false" aria-controls="collapseExampleTwo">
                                                <div className="flex-grow-1">
                                                    <i className="bi bi-send nav_icon"></i>
                                                    <span className="nav_name ps-2">Getting Started</span>
                                                </div>
                                            </a>
                                            <div className="collapse" id="collapseExampleTwo">
                                                <div className="card card-body">
                                                </div>
                                            </div>
                                        </li> */}
                                        <li>
                                            <a className={currentRoute === "/integration" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { Integration() }} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                <div className="flex-grow-1">
                                                    <i className="bi bi-code-slash nav_icon"></i>
                                                    <span className="nav_name ps-2">Documentation</span>
                                                </div>
                                                <i className="bi bi-chevron-down nav_icon nav-icons"></i>

                                            </a>
                                            <div className={`collapse ${showInt ? 'show' : ''}`} id="collapseExample">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="#loginapi">Login API</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#websdk">Web SDK Redirection</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#callbackapi">Callback API</a>
                                                        </li>
                                                        {/* <li>
                                                            <a className="nav_links" href="#javasciptpost">Javascript PostMessage</a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>


                                        {isConfingShowProd && <li>
                                            <a className={currentRoute === "/prod-users" || currentRoute === "/my-customers?prod" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { ProdUsers() }} data-bs-toggle="collapse" href="#collapseExampleProd" role="button" aria-expanded="false" aria-controls="collapseExampleProd">
                                                <div className="flex-grow-1">
                                                    <i className="bi bi-gear nav_icon"></i>
                                                    <span className="nav_name ps-2">Production</span>
                                                </div>
                                                <i className="bi bi-chevron-down nav_icon nav-icons "></i>
                                            </a>
                                            <div className={`collapse ${showProd ? 'show' : ''}`} id="collapseExampleProd">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="prod-users#sdk">SDK</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="prod-users#userslist" >Customers</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" data-bs-toggle="collapse" href="#" onClick={() => { ProdDeveloper() }}>Keys</a>
                                                        </li>
                                                        {isConfigShowScurbProdData && <li>
                                                            <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { MyCustomers('prod') }}>Scurb Users</a>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>}


                                        {/* {isConfigShowScurbData && <li>
                                            <a className={currentRoute === "/my-customers" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { MyCustomers() }} data-bs-toggle="collapse" href="#collapseExampleCustomer" role="button" aria-expanded="false" aria-controls="collapseExampleCustomer">
                                                <div className="flex-grow-1">
                                                    <i className="bi bi-people nav_icon"></i>
                                                    <span className="nav_name ps-2">Customers</span>
                                                </div>
                                                <i className="bi bi-chevron-down nav_icon nav-icons "></i>
                                            </a>
                                            <div className={`collapse ${showCustomer ? 'show' : ''}`} id="collapseExampleProd">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="my-customers#customerlist">Customer List</a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </li>} */}



                                        {/* <li>
                                            <a className={currentRoute === "/login" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { logout() }}>
                                                <i className="bi bi-box-arrow-right nav_icon"></i><span className="nav_name" >Logout</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default MenuBar;