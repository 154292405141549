import React from 'react'
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../../../App.css';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import '../forgot-password/forgot-password.css'
import ErrorMessage from '../../Dashboard/error-message/error-message';
import { resendEmail, fogotPassword } from '../../../redux/actions/user_action';
const Logo = require("../../../images/assets/Logo.png");

const ResetPassword = () => {
    const [loader, setLoader] = useState(false);
    const [userExists, setUserExists] = useState();
    const { state } = useLocation();
    const [message, setMessage] = useState();

    const sendEmail = () => {
        setLoader(true);
        const param = {
            email: state.email
        }
        fogotPassword(param).then(resp => {
            setLoader(false);
            if (resp.statusCode === "SUCCESS") {
                setMessage({ "success": resp.statusMessage })
            }
        }).catch(error => {
            if (error?.response.data.errorCode === "B2B_RESEND_EMAIL_409") {
                setMessage({ "error": error?.response.data.errorMessage })
            }
            setLoader(false);
        })
    }

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Reset your password using email
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <section>
                                    <ErrorMessage message={message} />
                                </section>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className='text-start mt-2 pb-3'>
                                        Reset Password link
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        We have sent you the reset password link on <span className='fw-bold'>{state?.email}</span>
                                    </p>
                                    <div className='form-input-box login-box'>

                                        <p className='fw-semibold mt-md-2'>
                                            Just click <span className='fw-bold'>Reset your password</span> button in the email to complete your password reset.
                                        </p>

                                        <p className='fw-semibold mt-md-2'>
                                            If you don’t see the email, you may need to <span className='fw-bold'>check your spam folder.</span>
                                        </p>
                                        {/* {userExists !== '' && <p className="demo-tetx mt-2">{userExists}</p>} */}

                                        <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                            <div className='align-items-center p-0 m-0 d-flex flex-row justify-content-end 
                                                         px-1 px-md-0 px-sm-0 px-lg-0'>
                                                <div className='order-1'>
                                                    <div className='text-center text-sm-start my-2 my-sm-0'>
                                                        <span>
                                                            {/* <span className='new-user-text'>Didn’t get the link?</span> */}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='order-2text-end'>
                                                    <div className='text-start my-2 my-sm-0'>
                                                        <button type='button' className='next-button mx-0' onClick={() => {
                                                            sendEmail()
                                                        }}>Resend Link</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default ResetPassword