import React, { useState, useRef, forwardRef } from "react";
import withClickOutside from "./WithClickOutside";
import './download-report-btn.css'
import DLReportOptionItems from "./dl-report-option-items";

const DL_IC = require('../../../images/assets/dl_ic.png');
const DOC_IC = require('../../../images/assets/docs-ic.png');
const SHARE_IC = require('../../../images/assets/share_ic.png');
const DropdownHamburger = require("../../../images/assets/dropdown-hamburger.png");

const DownloadReportBtn = forwardRef(({ showDropDown, setShowDropDown, btnLabel, clickOnDownload, clickOnExcel
    , clickOnDownloadDocs, isShowSupportingDocs
}, ref) => {
    const clickDownload = (e) => {
        setShowDropDown(!showDropDown);
        clickOnDownload();
    }
    const clickExcel = (e) => {
        setShowDropDown(!showDropDown);
        clickOnExcel();
    }
    const clickDocsDownload = (e) => {
        setShowDropDown(!showDropDown);
        clickOnDownloadDocs();
    }

    return (
        <section ref={ref}>
            <div className="dropdown">
                {/* <div className='download-btn-dropdown dropdown-toggle'
                    onClick={() => setShowDropDown(!showDropDown)}
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}>
                    {btnLabel}
                </div> */}

                <div className="">
                    <img src={DropdownHamburger} alt="DropdownHamburger"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}
                        id="dropdownMenuButton"
                        onClick={() => setShowDropDown(!showDropDown)}
                        className="drop-down-hamburger" />

                </div>
                <div className={"dropdown-menu " + (showDropDown ? " show" : "")} aria-labelledby="dropdownMenuButton">
                    <div className="dl-report-options">
                        <div className="dl-report-item-group"
                            onClick={clickDownload}>
                            <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Boost Report
                                </p>
                                <p className="dl-report-subline">
                                    Consolidated credit and boost
                                    report in PDF format.
                                </p>
                            </div>
                        </div>
                        <div className="dl-break-line"></div>
                        <div className="dl-report-item-group"
                            onClick={clickExcel}>
                            <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Excel Report
                                </p>
                                <p className="dl-report-subline">
                                    List of all your bank transaction
                                    in Excel format.
                                </p>
                            </div>
                        </div>
                        {isShowSupportingDocs && <div className="dl-break-line"></div>}
                        {isShowSupportingDocs && <div className="dl-report-item-group"
                            onClick={() => clickDocsDownload()}>
                            <img src={DOC_IC} alt="DOC_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Supporting Documents
                                </p>
                                <p className="dl-report-subline">
                                    List of documents required for loan application
                                </p>
                            </div>
                        </div>}
                       

                        {/* USE BELOW COMMON COMPONENT INSTEAD OF ABOVE OPTIONS */}
                        {/* <DLReportOptionItems icon={DL_IC} label="Download Docs" subline="Share credit report with banks
                            and lenders."
                            setClickOnItem={() => {
                                /// event for click
                            }}>
                        </DLReportOptionItems> */}

                    </div>

                </div>
            </div>
        </section>
    );
});

export default withClickOutside(DownloadReportBtn);