import httpClientB2B from "../../config/b2b";

const b2bLogin = data =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/login',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
});

export {
    b2bLogin
}