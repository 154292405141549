import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { store } from "../redux/store";
import { Navigate } from "react-router-dom";
import { logOut } from "../redux/actions/auth_action";
import React from "react";
import History from "./history";
import config from './../config.json'


export const navigationRef = React.createRef();

const httpClientBSAB2B = axios.create({
    baseURL: config.bsaUrl,
});

export const setBSAAuthHeader = async (token = "") => {

    const localToken =
        token == ""
            ? (await AsyncStorage.getItem("bsatoken"))
                ? `${await AsyncStorage.getItem("bsatoken")}`
                : ""
            : token;


    if (localToken !== "") {
        httpClientBSAB2B.defaults.headers = { Authorization: `Bearer ${localToken}` };
    } else {
        httpClientBSAB2B.defaults.headers = {};
    }
};

httpClientBSAB2B.interceptors.response.use(
    (response) => response,
    async (error) => {

        if (error && error.response && error.response.data && error.response.data.errorCode === "B2B_UNAUTHORIZED_401") {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        if (error && error.code && (error.code === "ERR_NETWORK" || error.code === "B2B_UNAUTHORIZED_401")) {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        if (error && error.response && error.response.status === 401) {
            store.dispatch(logOut(""));
            History.navigate("/login");
        }

        return Promise.reject(error);
    }
);

setBSAAuthHeader();

export default httpClientBSAB2B;
