import httpClientB2B from "../../config/b2b";

const createCustomer = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/create/test/user',
      data: data,

    },)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const scoreByUploadStatement = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/score/uploadStatement',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const multipleUploadStatement = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/multiple/uploadStatements',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });


const creditReportService = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/credit/report/upload',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


const documentUploadedList = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/lending/document/get/uploaded/list',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });


const downloadLendingDocumentAPI = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/lending/document/download',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const reportDownload = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/boost/report/get',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const downloadExcelReport = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/boost/report/get/excel',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const downloadExcelAnalysisReport = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/excel/analysis/download',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const getBankList = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: '/api/v1/b2b/get/banks',
      data: data
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error.response.data);
    });
  });

const getInvoiceData = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/billing/invoice/get',
      data: data
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error);
    });
  });

const filterInvoiceData = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      url: 'api/v1/b2b/billing/invoice/filter',
      data: data
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error);
    });
  });

const downloadInvoice = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/invoice/download',
      data: data
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error);
    });
  });
const getClientConfiguration = () =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'GET',
      url: '/api/v1/b2b/sdk/get/client/configuration',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const getTokenApiBoostAI = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST', data: data,
      url: '/api/v1/b2b/user/token/get',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const gstUploadApi = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST', data: data,
      url: '/api/v1/b2b/gst/upload',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const gstExcelDownloadApi = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      responseType: 'blob',
      method: 'POST', data: data,
      url: '/api/v1/b2b/gst/download/excel',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const sendCopilateApi = (data) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'POST', data: data,
      url: '/api/v1/b2b/lending/get/link',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const getAllTemplateApi = (bureau) =>
  new Promise((resolve, reject) => {
    httpClientB2B({
      method: 'GET',
      url: 'api/v1/b2b/lending/get/all/templates',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

export {
  createCustomer,
  scoreByUploadStatement,
  creditReportService,
  reportDownload,
  downloadExcelReport,
  getBankList,
  getInvoiceData,
  filterInvoiceData,
  downloadInvoice,
  multipleUploadStatement,
  getClientConfiguration,
  downloadExcelAnalysisReport,
  getTokenApiBoostAI,
  gstUploadApi,
  gstExcelDownloadApi,
  downloadLendingDocumentAPI,
  documentUploadedList,
  sendCopilateApi,
  getAllTemplateApi
}