import httpClient from "../../config/api";

const creditReport = (bureau) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/credit/report/get' + (bureau !== '' ? '?bureauName=' + bureau : ''),
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const boostScoreReport = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/client/get',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const getConfiguration = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/client/get',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const getUserList = (page, sizePerPage) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: `api/v1/get/test-users?page=${page ?? 0}&sizePerPage=${sizePerPage ?? 5}`
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const getCustomerList = (page, sizePerPage) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: `api/v1/get/user/list?page=${page ?? 0}&sizePerPage=${sizePerPage ?? 5}`
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const getScrubDataDetail = (reqParam,) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      data: reqParam,
      url: `api/v1/get/scrub/data/json`
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


const scoreByUploadStatement = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/score/uploadStatement',
      data: data, headers: { "Content-Type": "multipart/form-data" }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });


const createCallBackUrl = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/client/configure',
      data: data, headers: { "Content-Type": "application/json" }
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getCallBackUrl = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/client/configuration',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const creditReportService = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/credit/report/upload',
      data: data, headers: { "Content-Type": "multipart/form-data" }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {

        reject(error.response.data);
      });
  });


const getSDKUrl = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/b2b/jwt/sdk/url',
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error);
      });
  });

const getProfileData = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/get/user/details',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const changePassword = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/change/user-password',
      data: data, headers: { "Content-Type": "application/json" }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });





export {
  creditReport,
  boostScoreReport,
  scoreByUploadStatement,
  creditReportService,
  getConfiguration,
  getUserList,
  createCallBackUrl,
  getCallBackUrl,
  getSDKUrl,
  getProfileData,
  changePassword,
  getCustomerList,
  getScrubDataDetail,


}