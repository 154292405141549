import React, { useState, useRef, forwardRef } from "react";
import withClickOutside from "../../Component/download-report-btn/WithClickOutside";
import '../../Component/download-report-btn/download-report-btn.css'


const DL_IC = require('../../../images/assets/share_link.png');
const SHARE_IC = require('../../../images/assets/share_link.png');
const FILE_IC = require('../../../images/assets/file.png');
const DOC_IC = require('../../../images/assets/docs-ic.png');
const DropdownHamburger = require("../../../images/assets/dropdown-hamburger.png");

const DownloadOptions = forwardRef(({ showDropDown, setShowDropDown, showBTNDownload, showAICopilet, showBTNDownloadExcel, showBTNDocs, isRegisterUser, clientAllConfig, commonOnClick
}, ref) => {

    const commonClick = (clickFrom) => {
        setShowDropDown(!showDropDown);
        commonOnClick(clickFrom);
    }

    return (
        <section ref={ref}>
            <div className="dropdown">
                <div className="p-0 m-0">
                    <img src={DropdownHamburger} alt="DropdownHamburger"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}
                        id="dropdownMenuButton"
                        onClick={() => setShowDropDown(!showDropDown)}
                        className="drop-down-hamburger" />
                </div>

                <div className={"dropdown-menu " + (showDropDown ? " show" : "")}
                    aria-labelledby="dropdownMenuButton">
                    <div className="dl-report-options">

                        {/* <div className="dl-break-line"></div> */}
                        <div className="dl-report-item-group justify-content-start"
                            onClick={() => commonClick("DOWNLOAD_EXCEL")}>
                            <img src={DL_IC} alt="DL_IC" className="dl-report-ic dwn-ic-small " />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Excel Report
                                </p>
                                <p className="dl-report-subline">
                                    Detailed bank statement analysis in Excel format.
                                </p>
                            </div>
                        </div>

                        <div className="dl-break-line"></div>
                        <div className="dl-report-item-group justify-content-start"
                            onClick={() => commonClick("DOWNLOAD_ANALYTICS_PDF")}>
                            <img src={FILE_IC} alt="DL_IC" className="dl-report-ic dwn-ic-small" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Fraud Analytics PDF
                                </p>
                                <p className="dl-report-subline">
                                    In-depth fraud pattern analysis in PDF format.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
});

export default withClickOutside(DownloadOptions);