import React from 'react'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../../App.css';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import '../forgot-password/forgot-password.css'
import { confirmAccount } from '../../../redux/actions/user_action'
import ErrorMessage from '../../Dashboard/error-message/error-message';
const Logo = require("../../../images/assets/Logo.png");

const ConfirmAccount = () => {
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState();
    const navigate = useNavigate();


    useEffect(() => {
      
        var url = new URL(window.location.href);
        var searchParams = url.searchParams;
        var tokenAc = searchParams.get('token');
        if (tokenAc) {
            const param = {
                token: tokenAc
            }
            setLoader(true);
            confirmAccount(param).then(resp => {
                if (resp.statusCode === "SUCCESS") {
                    setMessage({ "success": resp.statusMessage })
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000)
                }
            }).catch(error => {
                setLoader(false);
                if (error && error?.response && error?.response.data) {
                    setMessage({ "error": error?.response.data.errorMessage })
                } else {
                    setMessage("Something went wrong!")
                }
            })
        }
    }, []);

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Hey! we are Verifying your email Address
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <section>
                                        <ErrorMessage message={message} />
                                    </section>
                                    <h1 className=' text-start mt-2 pb-3'>
                                        Verifying Email
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        Please wait while we are verifying your email
                                        {/* <span className='fw-bold'></span> */}
                                    </p>
                                    <div className='form-input-box login-box'>

                                        <p className='fw-semibold mt-md-2'>
                                            You’ll be redirected shortly after email verification.
                                        </p>

                                        {/* <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-12 col-md-6 order-2 order-md-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span>
                                                                <span className='new-user-text'>Didn’t get the link?</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 order-1 order-md-2 text-end'>
                                                        <div className='text-start my-2 my-sm-0'>
                                                            <button type='button' className='next-button'>Resend Link</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default ConfirmAccount