import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";

const plans = [
    {
        title: "Standard",
        credits: "100",
        price: "₹5,000",
        buttonText: "Buy Now",
        description: [
            "100 AI Lending Copilot Journeys",
            "Includes Boost Report",
            "Includes Excel Transaction Report",
        ],
    },
    {
        title: "Premium",
        credits: "500",
        price: "₹20,000",
        buttonText: "Buy Now",
        description: [
            "500 AI Lending Copilot Journeys",
            "Includes Boost Report",
            "Includes Excel Transaction Report",
        ],
    },
    {
        title: "Custom",
        credits: "--",
        price: "Based on credits",
        buttonText: "Customise Now",
        description: [
            "Plan based on your needs",
            "Includes Boost Report",
            "Includes Excel Transaction Report",
        ],
    },
];



const PricingCards = () => {
    const [selectedPlan, setSelectedPlan] = useState(1);
    const navigate = useNavigate();

    const rediretLink = () =>{
        //navigate('/register')
        window.open('/register','_blank')
    }

    return (
        <div className=" m-0 p-0">
            <div className="price-row cursor-pointer p-0 m-0 py-2">
                {plans.map((plan, index) => (
                    <div
                        key={index}
                        className={`col-lg-3 col-md-4 col-sm-6 col-12 pricing-card mx-3 my-3 ${selectedPlan === index ? "selected" : ""}`}
                        onClick={() => setSelectedPlan(index)}
                    >
                        <h4 className="plan-title p-0 m-0 pt-2">{plan.title}</h4>
                         {plan?.credits !== '--' &&
                         <Fragment>
                         <h2 className="plan-credits m-0 p-0 pt-2">{plan.credits}</h2>
                         <p className="plan-credits-line m-0 p-0">Credits</p></Fragment>}
                         {plan?.credits === '--' &&
                         <div className="mt-45"></div>}

                        <ul className="plan-description p-0 m-0">
                            <li className="p-0 m-0 my-1" >1 credit is used per user journey</li>
                        </ul>
                        <p className="plan-price">{plan.price}</p>
                        <button
                            onClick={rediretLink}
                            className={`${selectedPlan === index ? "plan-button" : "plan-button-selected"}`}
                        >{plan?.credits === '--' ? "Register" :  plan.buttonText} </button>


                        <div className="d-flex flex-row justify-content-center">
                            <div className='full-hr-line'></div>
                        </div>
                        <ul className="plan-description">
                            {plan.description.map((desc, i) => (
                                <li className="p-0 m-0 mt-2" key={i}>{desc}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default PricingCards;
