import './download-report-btn.css'

const DLReportOptionItems = (props) => {
    return (
        <div className="dl-report-item-group"
            onClick={(e) => props.setClickOnItem(e)}>
            <img src={props.icon} alt="DL_IC" className="dl-report-ic" />
            <div className="ps-2">
                <p className="dl-report-label">
                    {props.label}
                </p>
                <p className="dl-report-subline">
                    {props.subline}
                </p>
            </div>
        </div>

    );
};

export default DLReportOptionItems;