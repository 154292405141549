import '../../../App.css'
import './Footer.css'

function Footer() {
    return (
        <>
            <div className="copyright">
                <div className="container-lg container-fluid">
                    <div className="row">
                        <div className="col-sm-12 py-2 privacy-block row g-2">
                            <div className="col-sm-6 term">
                                <a className="p-small statement" href='/terms'>Terms & Conditions</a>
                                <a className="p-small statement" href='/privacypolicy'>  |  Privacy Policy</a>
                                <a className="p-small statement" href='/refundpolicy'>  |  Refund Policy</a>
                            </div>
                            <div className="col-sm-6 copyright-state">
                                <a className="p-small statement">Copyright © {new Date().getFullYear()} Boost.Money</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;