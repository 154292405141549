import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../redux/actions/auth_action";
import "./sidebar.css";
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
const Sidebar = () => {
    const [showNav, setShowNav] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentRoute, setCurrentRoute] = useState("/");
    const [showInt, setShowInt] = useState(false);
    const [showUAT, setShowUAT] = useState(false);
    const [showProd, setShowProd] = useState(false);
    const [showPricingPage, setPricingPage] = useState(false);
    const [showCustomer, setShowMyCustomer] = useState(false);
    const [isConfigShowScurbData, setConfigShowScurbData] = useState(false);
    const [isConfigShowScurbProdData, setConfigShowScurbProdData] = useState(true);

    const [isConfingShowProd, setConfigShowProd] = useState(true);
    /// for bank statments
    const [isOpenBankStatement, setOpenBankStatment] = useState(false);

    const logout = async () => {
        var result = await dispatch(logOut());
        if (result) {
            navigate("/login");
        }
    };
    const ScoreBoost = () => {
        navigate("/dashboard");
    };

    const Integration = () => {
        navigate("/integration");
    };

    const Developer = () => {
        navigate("/developer");
    };

    const ProdDeveloper = () => {
        navigate('/prod-developer')
    }

    const B2BUsers = () => {
        navigate('/users')
    }
    const ProdUsers = () => {
        navigate('/prod-users')
    }
    const PricingPage = () => {
        navigate('/pricing')
    }
    const MyCustomers = (fromWhere) => {
        if (fromWhere === 'prod') {
            navigate('/my-customers?prod')
        } else {
            navigate('/my-customers?uat')
        }
    }

    const BankStatement = (fromWhere) => {
        if (fromWhere === 'prod') {
            navigate('/upload-bank-statements?prod')
        } else {
            navigate('/upload-bank-statements?uat')
        }
    }

    const DocumentList = (fromWhere) => {
        if (fromWhere === 'prod') {
            navigate('/bank-account-list?prod')
        } else {
            navigate('/bank-account-list?uat')
        }

    }

    useEffect(() => {
        setCurrentRoute(window.location.pathname);
        setShowUAT(false)
        setShowInt(false)
        setShowProd(false)
        setShowMyCustomer(false)
        setPricingPage(false);
        setOpenBankStatment(false);
        setConfigShowProd(JSON.parse(localStorage.getItem('allConfiguration'))?.productionEnable);
        setConfigShowScurbData(JSON.parse(localStorage.getItem('allConfiguration'))?.scrubDataEnable)
        setConfigShowScurbProdData(JSON.parse(localStorage.getItem('allConfigurationProd'))?.scrubDataEnable)

        const isShowInt = window.location.pathname.includes('integration');
        if (isShowInt) {
            setShowInt(true)
        }
        const isShowUAT = window.location.pathname === '/users' || window.location.pathname.startsWith('/users/')
            || window.location.pathname === '/developer' || window.location.pathname.startsWith('/developer/')
            || window.location.pathname === '/pricing' || window.location.pathname.startsWith('/pricing/')
            || (window.location.pathname === '/my-customers' && window.location.search === '?uat') ||
            (window.location.pathname.startsWith('/my-customers') && window.location.search.startsWith('?uat'))
            || (window.location.pathname === '/upload-bank-statements' && window.location.search === '?uat')
            || (window.location.pathname.startsWith('/upload-bank-statements') && window.location.search.startsWith('?uat'))
            || (window.location.pathname === '/bank-account-list' && window.location.search === '?uat')
            || (window.location.pathname.startsWith('/bank-account-list') && window.location.search.startsWith('?uat'));
        if (isShowUAT) {
            if (window.location.search === '?uat') {
                setCurrentRoute(window.location.pathname + window.location.search);
            }
            setShowUAT(true)
        }
        const isShowProd = window.location.pathname === '/prod-users' || window.location.pathname.startsWith('/prod-users/')
            || window.location.pathname === '/prod-developer' || window.location.pathname.startsWith('/prod-developer/')
            || (window.location.pathname === '/my-customers' && window.location.search === '?prod') ||
            (window.location.pathname.startsWith('/my-customers') && window.location.search.startsWith('?prod'))
            || (window.location.pathname === '/upload-bank-statements' && window.location.search === '?prod')
            || (window.location.pathname.startsWith('/upload-bank-statements') && window.location.search.startsWith('?prod'))
            || (window.location.pathname === '/bank-account-list' && window.location.search === '?prod')
            || (window.location.pathname.startsWith('/bank-account-list') && window.location.search.startsWith('?prod'));
        if (isShowProd) {
            if (window.location.search === '?prod') {
                setCurrentRoute(window.location.pathname + window.location.search);
            }
            setShowProd(true)
        }

        const isShowMyCustomer = window.location.pathname === '/my-customers' || window.location.pathname.startsWith('/my-customers/')
            || window.location.pathname === '/my-customers' || window.location.pathname.startsWith('/my-customers/');
        if (isShowMyCustomer) {
            setShowMyCustomer(true)
        }


        const isShowPricing = window.location.pathname.includes('pricing');
        if (isShowPricing) {
            setPricingPage(true)
        }

        // const isOpenBankMenu = window.location.pathname === '/upload-bank-statements' || window.location.pathname.startsWith('/upload-bank-statements/')
        //     || window.location.pathname === '/bank-account-list' || window.location.pathname.startsWith('/bank-account-list/')
        // if (isOpenBankMenu) {
        //     setOpenBankStatment(true)
        // }

    }, [])



    return (
        <div className={`l-navbar${showNav ? ' shows' : ''}`}>
            <nav className="nav navbox">
                <div>
                    <div className='icon-text-box'>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='left-arow-icon'>
                                {!showNav && <i className={`bi bi-list`}
                                    onClick={() => setShowNav(!showNav)} />}
                            </div>
                            <div className='menu-icon'>
                                {showNav && <i className={`bi bi-arrow-left`}
                                    onClick={() => setShowNav(!showNav)} />}
                            </div>
                        </div> */}
                    </div>
                    <div className="nav_list">
                        <ul>
                            <li>
                                <div className={currentRoute === "/users" || currentRoute === "/developer" || currentRoute === "/my-customers?uat"
                                    || currentRoute === '/upload-bank-statements?uat' || currentRoute === '/bank-account-list?uat'
                                    ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { B2BUsers() }} data-bs-toggle="collapse" href="#collapseExampleTest" role="button" aria-expanded="false" aria-controls="collapseExampleTest">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-send nav_icon"></i>
                                        <span className="nav_name ps-2">Trial Sandbox</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons"></i>
                                </div>
                                <div className={`collapse ${showUAT ? 'show' : ''}`} id="collapseExampleTest">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="users#helpvideos">Help Videos</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="users#exploreproducts">Explore Products</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="users#userslist">Customers</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { Developer() }}>Keys</a>
                                            </li>
                                            {/* <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { PricingPage() }}>Pricing</a>
                                            </li> */}

                                            {isConfigShowScurbData && <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { MyCustomers('uat') }}>Scurb Users</a>
                                            </li>}

                                            {/* <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { BankStatement('uat') }}>Upload Statement</a>
                                            </li> */}

                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { DocumentList('uat') }}>Bank Statement Analyzer</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <a className={currentRoute === "/dashboard" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { ScoreBoost() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-expanded="false" aria-controls="collapseExampleTwo">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-send nav_icon"></i>
                                        <span className="nav_name ps-2">Getting Started</span>
                                    </div>
                                </a>
                                <div className="collapse" id="collapseExampleTwo">
                                    <div className="card card-body">
                                    </div>
                                </div>
                            </li> */}
                            <li>
                                <a className={currentRoute === "/integration" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { Integration() }} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-code-slash nav_icon"></i>
                                        <span className="nav_name ps-2">Documentation</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons"></i>
                                </a>
                                <div className={`collapse ${showInt ? 'show' : ''}`} id="collapseExample">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#loginapi">Login API</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#websdk">Web SDK Redirection</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#callbackapi">Callback API</a>
                                            </li>
                                            {/* <li>
                                                <a className="nav_links" href="#javasciptpost">Javascript PostMessage</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>


                            {/* <li>
                                <a className={currentRoute === "/upload-bank-statements" || currentRoute === "/bank-account-list" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { BankStatement() }} data-bs-toggle="collapse" href="#collapseBank" role="button" aria-expanded="false" aria-controls="collapseBank">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-file-earmark-arrow-up" style={{ fontSize: '20px' }}></i>
                                        <span className="nav_name ps-2">Bank Statements</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons"></i>

                                </a>
                                <div className={`collapse ${isOpenBankStatement ? 'show' : ''}`} id="collapseBank">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { BankStatement() }}>Uplaod Statements</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { DocumentList() }}>Document List</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li> */}

                            {/* <li>
                                <a className={currentRoute === "/integration" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { Integration() }} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-link-45deg nav_icon"></i>
                                        <span className="nav_name ps-2">Integration</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons"></i>
                                </a>
                                <div className={`collapse ${showInt ? 'show' : ''}`} id="collapseExample">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#loginapi">Login API</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#websdk">Web SDK Redirection</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#callbackapi">Callback API</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#javasciptpost">Javascript PostMessage</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li> */}
                            {/* <li>
                                <a className={currentRoute === "/developer" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { Developer() }} data-bs-toggle="collapse" href="#collapseExampleDev" role="button" aria-expanded="false" aria-controls="collapseExampleDev">
                                    <i className="bi bi-code-slash nav_icon"></i><span className="nav_name">Developer <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                </a>
                                <div className="collapse" id="collapseExampleDev">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#apikeys">API Keys</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#apibaseurl">API Base URL</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#callback">Callback Configuration</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li> */}


                            {isConfingShowProd && <li>
                                <a className={currentRoute === "/prod-users" || currentRoute === "/my-customers?prod"
                                    || currentRoute === '/upload-bank-statements?prod' || currentRoute === '/bank-account-list?prod' ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { ProdUsers() }} data-bs-toggle="collapse" href="#collapseExampleProd" role="button" aria-expanded="false" aria-controls="collapseExampleProd">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-gear nav_icon"></i>
                                        <span className="nav_name ps-2">Production</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons "></i>
                                </a>
                                <div className={`collapse ${showProd ? 'show' : ''}`} id="collapseExampleProd">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="prod-users#sdk">SDK</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="prod-users#userslist">Customers</a>
                                            </li>
                                            <li>
                                                <a className="nav_links"
                                                    data-bs-toggle="collapse" href="" onClick={() => { ProdDeveloper() }}>Keys</a>
                                            </li>
                                            {isConfigShowScurbProdData && <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { MyCustomers('prod') }}>Scurb Users</a>
                                            </li>}

                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { DocumentList('prod') }}>Bank Statement Analyzer</a>
                                            </li>

                                            {/* <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { BankStatement('prod') }}>Upload Statement</a>
                                            </li>

                                            <li>
                                                <a className="nav_links" data-bs-toggle="collapse" href="" onClick={() => { DocumentList('prod') }}>Statement List</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>}

                            {/* <li>
                                <a className={currentRoute === "/pricing" ||
                                    currentRoute === "/pricing" ? "nav_link active " : "nav_link"}
                                    rel="noopener" onClick={() => {
                                        PricingPage();
                                    }} data-bs-toggle="collapse" href="#collapseExampleProd" role="button" aria-expanded="false" aria-controls="collapseExampleProd">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-cash-stack"></i>
                                        <span className="nav_name ps-2">Pricing</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons "></i>
                                </a>
                                <div className={`collapse ${showPricingPage ? 'show' : ''}`} id="collapseExampleProd">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#pricing">Pricing</a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </li> */}

                            {/* {isConfigShowScurbData && <li>
                                <a className={currentRoute === "/my-customers" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { MyCustomers() }} data-bs-toggle="collapse" href="#collapseExampleCustomer" role="button" aria-expanded="false" aria-controls="collapseExampleCustomer">
                                    <div className="flex-grow-1">
                                        <i className="bi bi-people nav_icon"></i>
                                        <span className="nav_name ps-2">Customers</span>
                                    </div>
                                    <i className="bi bi-chevron-down nav_icon nav-icons "></i>
                                </a>
                                <div className={`collapse ${showCustomer ? 'show' : ''}`} id="collapseExampleProd">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="my-customers#customerlist">Customer List</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </li>} */}



                            {/* <li>
                                <a className={currentRoute === "/login" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { logout() }}>
                                    <i className="bi bi-box-arrow-right nav_icon"></i><span className="nav_name" >Logout</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};
export default Sidebar;
