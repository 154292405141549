import React, { useState } from 'react';
import Tabs from './tabs';
import '../tab-component/tab-component.css'
import { useNavigate } from 'react-router-dom';


export default function TabComponent(props) {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    function changeTabIndex(i) {
        if (i == 0) {

        } else {

        }

        setTabIndex(i);
    }

    return (
        <Tabs activeIndex={tabIndex ?? 0} onTabChange={changeTabIndex} />
    );
}