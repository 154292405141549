import { ToastContainer } from 'react-toastify';
import '../../App.css';
import './dashboard.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DashBoardPage from './dashbord-page/dashboard-page';
import MenuBar from './Menubar/menubar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from './SideBar/sidebar';
import Footer from '../landing-page/Footer/Footer';
import getUserData from '../../Common/helper';

function DashBoard() {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        }
    }, []);

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    <div className='content'>
                        <DashBoardPage />
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
}

export default DashBoard;