import "./profile_header.css";

const Avatar_logo = require("../../../../images/avatar-profile.png");
const IC_CAMERA = require("../../../../images/assets/ic-camera.png");
const IC_EYE = require("../../../../images/assets/ic-eye.png");

const ProfileHeaderBanner = (props) => {
  
  let fullName = localStorage.getItem("fullName")

  return (
    <>
      <section className="col-lg-12 col-md-12 banner_card d-flex flex-wrap justify-content-md-start">
        <div className="circular--portrait--parent">
          <div className="circular--portrait">
            {/* <img
              src={Avatar_logo}
              className="profile_circle"
              alt="avatar"
            ></img> */}
            {/* <div className="camera-ic-background">
              <img src={IC_CAMERA} className="camera-ic" alt="camera"></img>
            </div> */}
            <div className="profile_circle">{fullName ? fullName.charAt(0).toUpperCase() : ''}</div>
          </div>
        </div>
        <div className="d-flex flex-column ps-2">
          <p className="profile-user-name">{props.name ?? ""}</p>
          <p className="profile-company-name">{props.businessName ?? ""}</p>
        </div>
      </section>
    </>
  );
};
export default ProfileHeaderBanner;
