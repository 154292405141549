import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import { getCommonUATClientTokenB2B } from '../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../Component/GenetateProdClientTokenB2B';
import config from './../../config.json'
import Loader from '../loader/loader';
import DownloadInvoiceBtn from './download-invoice/download-invoice-btn';
const GreenTick = require("../../images/green_tick.png")
const InfoIcon = require("../../images/info.png")

const buttonStyles = {
    width: '100px',
    height: '36px',
    flexShrink: 0,
    borderRadius: '8px',
    border: '1px solid #7624E7',
    backgroundColor: 'white',
    color: '#7624E7',
    fontWeight: '600',
    fontSize: '13px',
    padding: '0px',
    margin: '5px'

};

const tableCustomStyles = {
    headCells: {
        style: {
            fontSize: '15px',
            fontWeight: '600',
            justifyContent: 'center',
            backgroundColor: '#F5F5FE',
        },
    },
    rows: {
        style: {
            justifyContent: 'center',
            fontSize: '14px',
        },
    },
    cells: {
        style: {
            justifyContent: 'center',
            fontSize: '14px',
            height: '65px',
            // overflowY: 'auto'
        },
    },
    table: {
        style: {
            marginBottom: '0px',

        },
    },
}


const BillTableComponent = ({
    data,
    selectableRows,
    selectableRowsNoSelectAll,
    selectableRowsVisibleOnly,
    selectableRowsHighlight,
    selectableRowsSingle,
    expandableRows,
    expandOnRowClicked,
    expandOnRowDoubleClicked,
    expandableRowsHideExpander,
    pagination,
    highlightOnHover,
    striped,
    pointerOnHover,
    dense,
    persistTableHead,
    noHeader,
    fixedHeader,
    fixedHeaderScrollHeight,
    progressPending,
    selectableRowsRadio,
    noTableHead,
    noContextMenu,
    direction,
    subHeader,
    subHeaderAlign,
    subHeaderWrap,
    responsive,
    disabled,
}) => {

    useEffect(() => {
        /// HIDE ELEMENT Class which prevented download button hidden issue fixed
        setTimeout(() => {
            var element = document.querySelector('.fpBGKh');
            // console.log("element " + element)
            if (element) {
                element.classList.replace('fpBGKh', 'fpBGKh-new');
                // Disable attributes
                // for (var i = 0; i < element.attributes.length; i++) {
                //     element.removeAttribute(element.attributes[i].name);
                // }
                // // element.setAttribute('width', '100%');
                // element.style.width = '100%';

                // element.setAttribute('overflow-x', 'auto');
            }

        }, 1000);
    }, []);

    const [loader, setLoader] = useState(false);

    const downloadInvoiceExcel = (e, businessIbillingId) => {
        e.preventDefault();
        setLoader(true);
        const param = {
            billingId: businessIbillingId,
            reportType: 'EXCEL',
            clientId: localStorage.getItem('prodclientId')
        }
        getCommonProdClientTokenB2B(
            () => {
                // Create the request object
                var xhr = new XMLHttpRequest();
                xhr.open('POST', config.prodApiUrl + 'api/v1/b2b/invoice/download', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('b2bProdToken'))
                // Set up the request body
                var requestBody = JSON.stringify({
                    "clientId": localStorage.getItem('prodclientId'),
                    "billingId": businessIbillingId,
                    "reportType": "EXCEL"
                });
                // Set up the response type to be 'blob' (binary data)
                xhr.responseType = 'blob';
                // Set up the onload function
                xhr.onload = function () {
                    setLoader(false);
                    if (xhr.status === 200) {
                        // Create a Blob from the response
                        var blob = new Blob([xhr.response], { type: 'application/vnd.ms-excel' });
                        // Create a link element
                        var downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(blob);
                        downloadLink.download = 'billing_invoice_excel.xlsx';
                        // Append the link to the body and trigger the click event
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        // Clean up
                        document.body.removeChild(downloadLink);
                    } else {
                        console.error('Request failed with status:', xhr.status);
                    }
                };
                // Set up the onerror function
                xhr.onerror = function () {
                    console.error('Request failed');
                };
                // Send the request with the request body
                xhr.send(requestBody);
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const downloadInvoicePDF = (e, businessIbillingId) => {
        e.preventDefault();
        setLoader(true);
        const param = {
            billingId: businessIbillingId,
            reportType: 'PDF',
            clientId: localStorage.getItem('prodclientId')
        }
        getCommonProdClientTokenB2B(
            () => {
                // Create the request object
                var xhr = new XMLHttpRequest();
                xhr.open('POST', config.prodApiUrl + 'api/v1/b2b/invoice/download', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('b2bProdToken'))
                // Set up the request body
                var requestBody = JSON.stringify({
                    "clientId": localStorage.getItem('prodclientId'),
                    "billingId": businessIbillingId,
                    "reportType": "PDF"
                });

                // Set up the response type to be 'blob' (binary data)
                xhr.responseType = 'blob';
                // Set up the onload function
                xhr.onload = function () {
                    setLoader(false);
                    if (xhr.status === 200) {
                        // Create a Blob from the response
                        var blob = new Blob([xhr.response], { type: 'application/pdf' });
                        // Create a link element
                        var downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(blob);
                        downloadLink.download = 'billing_invoice_pdf.pdf';
                        // Append the link to the body and trigger the click event
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        // Clean up
                        document.body.removeChild(downloadLink);
                    } else {
                        console.error('Request failed with status:', xhr.status);
                    }
                };
                // Set up the onerror function
                xhr.onerror = function () {
                    console.error('Request failed');
                };
                // Send the request with the request body
                xhr.send(requestBody);
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const columns = [
        {
            name: 'Month',
            grow: 1.0,
            selector: row => row.month,
            sortable: true,
            reorder: true,
            conditionalCellStyles: [
                {
                    when: row => row.month,
                    style: {
                        fontWeight: 600,
                        subHeaderAlign: 'center',
                        justifyContent: 'center',
                    },
                },
            ]

        },
        {
            name: 'Amount',
            grow: 1.0,
            selector: row => row.amount,
            sortable: true,
            reorder: true,
            style: {
                subHeaderAlign: 'center',
                justifyContent: 'center',
            }
        },
        {
            name: 'Status',
            grow: 1.0,
            selector: row => row.status,
            sortable: true,
            reorder: true,
            cell: row => <div className='d-flex align-items-center'>
                <img src={row.status == "Paid" ? GreenTick : InfoIcon} className='icon-status' />
                <p className='m-0' style={{ color: row.status == "Paid" ? "#086F3D" : "#E28903", fontWeight: 600 }}>{row.status}</p>
            </div>,
        },
        {
            name: 'Invoice',

            selector: row => row.invoice,
            sortable: false,
            reorder: true, grow: 2.0,
            style: {

            },
            // cell: row => <div className=''>
            //     <DownloadInvoiceBtn
            //         btnLabel='Download Invoice'
            //         clickOnDownload={(e) => {
            //             downloadInvoicePDF(e, row.invoice);
            //         }}
            //         clickOnExcel={(e) => {
            //             downloadInvoiceExcel(e, row.invoice);

            //         }}
            //     ></DownloadInvoiceBtn>
            // </div>,
            cell: row => <div className='d-flex'><button className='mr-2' onClick={(e) => downloadInvoicePDF(e, row.invoice)} style={
                buttonStyles
            }> Invoice PDF</button>
                <button onClick={(e) => downloadInvoiceExcel(e, row.invoice)} style={
                    buttonStyles
                }> Invoice Excel</button>
            </div>,

        },
    ];

    return (
        <div>
            {loader && <Loader />}
            <DataTable
                responsive={true}
                customStyles={tableCustomStyles}
                columns={columns}
                data={data}
                defaultSortFieldId={1}
                selectableRows={selectableRows}
                selectableRowsNoSelectAll={selectableRowsNoSelectAll}
                selectableRowsHighlight={selectableRowsHighlight}
                selectableRowsSingle={selectableRowsSingle}
                selectableRowsVisibleOnly={selectableRowsVisibleOnly}
                expandableRows={expandableRows}
                expandOnRowClicked={expandOnRowClicked}
                expandOnRowDoubleClicked={expandOnRowDoubleClicked}
                expandableRowsHideExpander={expandableRowsHideExpander}
                pagination={pagination}
                highlightOnHover={highlightOnHover}
                striped={striped}
                pointerOnHover={pointerOnHover}
                dense={dense}
                noTableHead={noTableHead}
                persistTableHead={persistTableHead}
                progressPending={progressPending}
                noHeader={noHeader}
                subHeader={subHeader}
                subHeaderAlign={subHeaderAlign}
                subHeaderWrap={subHeaderWrap}
                noContextMenu={noContextMenu}
                fixedHeader={fixedHeader}
                fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                direction={direction}
                disabled={disabled}
            />
        </div>
    );
};

export default BillTableComponent;