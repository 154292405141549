// import AsyncStorage from "@react-native-async-storage/async-storage";
// import { applyMiddleware, compose, createStore } from "redux";
// import { createLogger } from "redux-logger";
// import persistReducer from "redux-persist/es/persistReducer";
// import persistStore from "redux-persist/es/persistStore";
// import thunk from "redux-thunk";
// import reducers from "./reducers";

// const enhancers = [
//     applyMiddleware(
//         thunk,
//         createLogger(),
//     )
// ];

// const persistConfig = {
//     key: 'root',
//     storage: AsyncStorage
// }

// const composeEnhancers = (
//     typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//     compose;

// const enhancer = composeEnhancers(...enhancers);

// const persistedReducer = persistReducer(persistConfig, reducers);

// export const store = createStore(persistedReducer, {},enhancer);

// export const persistor = persistStore(store)

import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)


