export const dispatchAction = (type, payload, isError, errorTimeout = 3000) => (
    dispatch,
  ) => {
    dispatch({
      type,
      payload,
    });
  
    if (isError) {
      setTimeout(() => {
        dispatch({
          type,
          payload: '',
        });
      }, errorTimeout);
    }
  };
  