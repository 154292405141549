import { b2bProdLogin } from '../../redux/actions/prod_b2b_login';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getProdConfiguration } from '../../redux/actions/prod_action';
import { setB2BAuthHeaderProd } from '../../config/prod-b2b';

export const getCommonProdClientTokenB2B = async (successCallback, errorCallback) => {
    try {
        const resp = await getProdConfiguration();

        if (resp) {
            localStorage.setItem('prodclientId', resp.clientId);
            const params = {
                clientId: resp.clientId,
                clientSecret: resp.clientSecret
            };

            // PROD B2B Login for get PROD B2B Access Token
            const b2bResp = await b2bProdLogin(params);

            if (b2bResp.access_token) {
                AsyncStorage.setItem('b2bProdToken', b2bResp.access_token);
                setB2BAuthHeaderProd(b2bResp.access_token)
                successCallback(); // Invoke success callback
            }
        }
    } catch (error) {
        if (
            error?.response.data &&
            (error.response.data.errorCode === 'CLIENT_DETAILS_NOT_FOUND' ||
                error.response.data.errorCode === 'B2B_GET_CLIENT_CREDENTIALS_404')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        } else if (
            error?.response.data &&
            (error.response.data.errorCode === 'B2B_LOGIN_400' ||
                error.response.data.errorCode === 'B2B_LOGIN_500')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        }
    }
};