import React from 'react';
import './excel-import.css'
const DL_IC = require('../../../images/assets/dl_ic.png');
const ExcelImg = require('../../../images/assets/excel-img.png');
const SampleFile = require('../../../images/assets/excel/sample.xlsx');

const ExcelUploadGuide = ({
    onDownloadSample,
    onContinue
}) => {

    const handleDownloadExcelSample = () => {
        fetch(SampleFile)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', 'sample.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error('Download error:', error));
    };

    return (
        <div>
            <p className='info-excel p-0 px-2'>
                Before uploading your Excel file into our platform, please download the sample Excel file to ensure the correct format and headers.
            </p>

            <div className='p-0 m-0' onClick={() => handleDownloadExcelSample()} style={{ cursor: 'pointer' }}>
                <p className='skip-link'>
                    <span>
                        <img src={DL_IC} className="dl-report-ic mt-1" alt="Download Icon" />
                    </span>
                    Download sample XLSX
                </p>
            </div>

            <div className='divider p-0 m-0'></div>

            <div className='mt-3 px-2'>
                <p className='excel-label text-center p-0 m-0'>Sample Excel file snippet</p>
                <div className='mt-3'>
                    <img src={ExcelImg} alt="Excel Format" />
                </div>

                <div className='d-flex flex-row mt-4 mb-4'>
                    <p className='note-lable p-0 m-0' style={{ fontSize: '12px' }}>Note:</p>
                    <p className='note-line p-0 m-0 ps-1'>
                        Use the same header names as in the sample file.
                    </p>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-center p-0 m-0 mb-2'>
                <button
                    className="upload-statement-button"
                    type="button"
                    onClick={onContinue}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default ExcelUploadGuide;
