import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import '../../../App.css';
import { setAuthHeader } from '../../../config/api';
import { dispatchAction } from '../../../redux/actions/common_action';
import { registerUser } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import './createanaccount.css';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
// import InputMask from 'react-input-mask';
import PasswordValidation from './password-validation';
const Logo = require("../../../images/assets/Logo.png");
const IC_EYE = require("../../../images/assets/show-password.png");
const IC_EYE_HIDE = require("../../../images/assets/hide-password.png");

function SetPasswordScreen() {
    const [bussinessname, setBussinessName] = useState('');
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [passwrd, setPasswrd] = useState('');
    const { state } = useLocation();
    const [isAcceptTerm, setIsAccepTerm] = useState(false)
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [mobileNumber, setMobileNumber] = useState('');
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false); // Track focus state
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false,
        noSpaces: false,
    });

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        ReactGA.event({
            category: "Component/Page_Render",
            action: "set_password_page_render",
            label: `set_password_page_render`,
        });

        if (user?.access_token) {
            navigate('/users');
        }
    }, [])

    const loginPage = () => {
        navigate('/login');
    }
    const validateMobileNo = (mobileNo) => {
        setMobileNumber(mobileNo = mobileNo.replace(/[^0-9-]/g, ''))
        if (mobileNo) {
            var usMobile = mobileNo.split('-').join('');
            setMobileNumber(usMobile);
        }
    }
    const handleFocus = () => {
        setIsFocused(true);  // Set focus to true when input is focused
        setUserExists('');
        setFillupdetails('');
        setShowError(false);
    };

    const handleBlur = () => {
        setShowError(true);
        // Set focus to false when input is blurred
    };
    const save = () => {

        ReactGA.event({
            category: "CTA",
            action: "btn_registration_api_call",
            label: "registration_api_call",
        });

        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        if (state === null) {
            setUserExists('Please fill in all details like Name, Mobile, and Email before setting the password.');
            return;
        }

        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (
            state.step1Data.name === '' || state.step1Data.email === '' || state.step1Data.businessName === '' ||
            newPassword === '' && confirmPassword === '' ) {
            setShowError(true)

        } else if (newPassword !== confirmPassword) {
            setUserExists('Passwords do not match. Please try again');
        } else {
            const param = {
                "businessName": state.step1Data.bussinessname,
                "fullName": state.step1Data.name,
                "email": state.step1Data.email,
                "password": newPassword,
                "confirmPassword": confirmPassword
            };
            setLoader(true)
            registerUser(param).then(resp => {
                setLoader(false);
                if (resp.statusCode === "SUCCESS") {
                    ReactGA.event({
                        category: "API_CALL",
                        action: "api_call_registration_user",
                        label: 'api_call_registration_user_success',
                        value: 1
                    });

                    navigate('/email-verification', { state: { email: state.step1Data.email } });
                }
            }).catch(error => {
                ReactGA.event({
                    category: "API_CALL",
                    action: "api_call_registration_user",
                    label: 'api_call_registration_user_fail',
                    value: 0
                });

                if (error && error?.response && error?.response.data) {
                    setUserExists(error?.response.data.errorMessage)
                } else {
                    setUserExists("Something went wrong!")
                }
                setLoader(false);
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);

        // Check each requirement
        setRequirements({
            length: newPassword.length >= 8 && newPassword.length <= 20,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
            number: /[0-9]/.test(newPassword),
            noSpaces: !/\s/.test(newPassword),
        });
    };
    const btnEnableDisable = {
        opacity: newPassword === '' || !Object.values(requirements).every(Boolean)
            || confirmPassword === '' || (confirmPassword !== newPassword) ? 0.5 : 1.0
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                {/* <h1 >
                                    Start your <span className='boost-text'>boost</span> journey
                                </h1> */}
                                <h1 className='color'>
                                    Set Up Your Password to Register
                                </h1>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Set Password
                                    </h1>
                                    <div className='form-input-box mt-md-4'>
                                        <form>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Password</div>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        onFocus={handleFocus}  // Focus event handler
                                                        onBlur={handleBlur}    // Blur event handler
                                                        placeholder='Create password'
                                                        type={isRevealNewPwd ? "text" : "password"}
                                                        id='new-password' className='input-box form-control' value={newPassword}
                                                        onChange={(e) => { handlePasswordChange(e) }}
                                                        onKeyDown={handleKeyDown} />

                                                    <span>
                                                        <img title={isRevealNewPwd ? "Hide password" : "Show password"}
                                                            src={isRevealNewPwd ? IC_EYE_HIDE : IC_EYE}
                                                            onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                                            className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1"
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>

                                                </div>
                                                {/* {(showError && newPassword === '') && <span className="demo-tetx">Please enter your password</span>} */}
                                            </div>

                                            {isFocused && <PasswordValidation requirements={requirements} />}
                                            <div className='mt-3'></div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Confirm Password</div>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input

                                                        placeholder='Confirm password'
                                                        type={isRevealConfirmPwd ? "text" : "password"}
                                                        id='confirm-password' className='input-box form-control' value={confirmPassword}
                                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                        onKeyDown={handleKeyDown} />

                                                    <span>
                                                        <img title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                                                            src={isRevealConfirmPwd ? IC_EYE_HIDE : IC_EYE}
                                                            onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                                                            className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1"
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>

                                                </div>
                                                {(showError && confirmPassword === '') && <span className="demo-tetx">Please enter confirm password</span>}
                                            </div>

                                            {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}


                                            {!isFocused &&
                                                <div className='p-0 m-0' style={{ height: '85px' }}></div>}
                                            {isFocused && Object.values(requirements).every(Boolean) &&
                                                <div className='p-0 m-0' style={{ height: '85px' }}></div>}

                                            <div className='form-inner-box'>

                                                <div className='align-items-center p-0 m-0 d-flex flex-row justify-content-between 
                                                         px-1 px-md-0 px-sm-0 px-lg-0'>
                                                    <div className='order-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span className='any-account-text'>Have an account? </span><span className='login-text' onClick={() => {
                                                                loginPage()
                                                            }}>Login</span>
                                                        </div>
                                                    </div>
                                                    <div className='order-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button'

                                                                style={btnEnableDisable} className='next-button mx-0' onClick={() => {
                                                                    save()
                                                                }} >
                                                                Register
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default SetPasswordScreen;