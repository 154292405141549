import React from 'react';
import './error-message.css'
import '../../../App.css'
import 'react-dropdown/style.css';

const ErrorMessage = (props) => {

    return (
        <>
            <div className='container-lg container-fluid my-3'>
                {props?.message?.success && <div className="alert show-success-main-box alert-success d-flex align-items-center w-100 justify-content-between">
                    <div className='d-flex align-items-center align-items-sm-center'>
                        <i className="bi bi-check-circle success-exclamations-icons"></i>
                        <p className='mb-0 ms-3 error-message-text-email'>{props?.message?.success}</p>
                    </div>
                    <button type="button" className="success-mess-close-box p-0 m-0" data-bs-dismiss="alert"> <i className="bi bi-x-circle circle-close-box"></i></button>
                </div>}
                {props?.message?.error && <div className="alert show-error-main-box alert-success d-flex align-items-center w-100 justify-content-between">
                    <div className='d-flex align-items-center align-items-sm-center'>
                        <i className="bi bi-check-circle exclamations-icons"></i>
                        <p className='mb-0 ms-3 error-message-text-email'>{props?.message?.error} </p>
                    </div>
                    <button type="button" className="error-mess-close-box p-0 m-0" data-bs-dismiss="alert"><i className="bi bi-x-circle circle-close-box"></i></button>
                </div>}
            </div>
        </>
    )
}

export default ErrorMessage;