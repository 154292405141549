import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import SelectableInfoBox from './selectable_gen_req_dialog';

const ShareIcon = require("../../../images/assets/share_url.png");
const UploadIcon = require("../../../images/assets/upload_ic_black.png");

const GenReqDialogContent = (props) => {
    const [selectedBox, setSelectedBox] = useState(null);

    useEffect(() => {
        ReactGA.event({
            category: "CTA",
            action: "btn_click_open_generate_request_box_call",
            label: `open_generate_request_box_api_call`
        });
    }, [])

    return (
        <div>
            {props.clientAllConfig.lendingCopilot && <SelectableInfoBox
                title="Generate AI Lending Copilot URL"
                img={ShareIcon}
                description="Generate & share a URL to collect Loan Documents"
                isSelected={selectedBox === 'copilot'}
                onClick={() => setSelectedBox('copilot')}
            />}
            <div className='mt-3'>
                <SelectableInfoBox
                    img={ShareIcon}
                    title="Generate SDK URL"
                    description="Generate & share a URL to get Credit Report & Bank Data"
                    isSelected={selectedBox === 'sdk'}
                    onClick={() => setSelectedBox('sdk')}
                />
            </div>
            {props.showUploadDoc && <div className='mt-3 d-inline-flex flex-row align-items-center'>
                <div className="dl-break-line " style={{ width: '165px' }}></div>
                <p className='p-0 m-0 px-3'>or</p>
                <div className="dl-break-line " style={{ width: '165px' }}></div>
            </div>}


            {props.showUploadDoc && <div className='mt-3'>
                <SelectableInfoBox
                    img={UploadIcon}
                    title="Upload Documents"
                    description="Upload Credit Report & Bank Statement files to get analysis."
                    isSelected={selectedBox === 'upload-doc'}
                    onClick={() => setSelectedBox('upload-doc')}
                />
            </div>}

            <div className=" px-1 mt-4 mb-2">
                <div
                    className="upload-statement-btn text-center cursor-pointer"
                    style={{ opacity: selectedBox == null ? '0.5' : '1.0' }}
                    onClick={() => selectedBox && props.handleGenerateURL(selectedBox)}
                >
                    Continue
                </div>
            </div>
        </div>
    );
};



export default GenReqDialogContent;
