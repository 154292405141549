import httpClientuser from "../../config/user";
import { LOGOUT, } from "../constants";
import AsyncStorage from '@react-native-async-storage/async-storage';


const resendEmail = data =>
  new Promise((resolve, reject) => {
    httpClientuser({
      method: 'POST',
      url: 'api/v1/resend-email',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
});


const fogotPassword = data =>
  new Promise((resolve, reject) => {
    httpClientuser({
      method: 'POST',
      url: 'api/v1/forgot-password',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
});

const confirmAccount = data =>
  new Promise((resolve, reject) => {
    httpClientuser({
      method: 'POST',
      url: 'api/v1/confirm-account',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
});

const validateForgotPassword = data =>
  new Promise((resolve, reject) => {
    httpClientuser({
      method: 'POST',
      url: 'api/v1/validate/forgot-password',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
});

const validateEmail = data =>
  new Promise((resolve, reject) => {
    httpClientuser({
      method: 'POST',
      url: 'api/v1/check/users/existent',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

export {
    confirmAccount,
    resendEmail,
    fogotPassword,
    validateForgotPassword,
    validateEmail
}