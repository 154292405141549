
import React, { useState, useEffect } from 'react';
import '../../../App.css'

import Sidebar from '../../Dashboard/SideBar/sidebar';
import Loader from '../../loader/loader';
import MenuBar from '../Menubar/menubar';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../usage-overview/usage-overview.css"
import Select, { components } from 'react-select'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../../../Common/helper";
import CardCounter from "./component/card-component";

import { customStyles } from "../../Component/custom-style-dropdown-clients";
import DateRangePicker from 'rsuite/DateRangePicker';
import './component/date-range.css';
import { Stack } from "rsuite";
import { getProdConfiguration } from '../../../redux/actions/prod_action';
import { parseISO, format } from 'date-fns';
import { dashboardCountReqCall } from '../../../redux/actions/prod-b2b_action';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';
const ICAdminCard = require("../../../images/assets/ic-admin-card.png");



const getParsedDate = (monthOffset = 0, formatStr = 'yyyy/MM/dd', useParseISO = false) => {
    const date = new Date();
    date.setMonth(date.getMonth() + monthOffset);
    const formattedDate = format(date, formatStr);
    // If useParseISO is true, parse the formatted date as ISO; otherwise, return the formatted date string.
    return useParseISO ? parseISO(formattedDate) : formattedDate;
};

function UsageOverview(props) {


    const [clientId, setClientId] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [clientOptions, setClientOptions] = useState([]);
    const [userData, setUserData] = useState();
    const [loader, setLoader] = useState(false);
    const [userListData, setUserListData] = useState()
    const [userListDataFilter, setUserListDataFilter] = useState()
    const [selectedOption, setSelectedOption] = useState(null);

    // Get the date of the last month in yyyy-mm-dd format

    const [reqParamDBApi, setReqParam] = useState({
        "clientId": null,
        "fromDate": null, //getParsedDate(-1, 'yyyy/MM/dd'),
        "toDate": null, //getParsedDate(0, 'yyyy/MM/dd')
    });
    const [dateRange, setDateRange] = useState(
        []);

    // const [dateRange, setDateRange] = useState(
    //     [getParsedDate(-1, 'yyyy-MM-dd', true), getParsedDate(0, 'yyyy-MM-dd', true)]);

    const [numSuccessFailData, setNumSuccessFailData] = useState(null);


    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        } else {
            const data = getUserData()
            //console.log(data)
            setUserData(data)

            getCommonProdClientTokenB2B(
                () => {
                    getClientList(0);
                },
                (errorMessage) => {
                }
            );


        }
    }, []);



    const { user } = useSelector((state) => {
        return state.authReducer;
    });




    const getClientList = (page, fromPagination = false) => {
        //  setLoader(true)
        // getAllClientList().then((resp) => {
        //     if (resp.clientDetails) {
        //         setUserListData(resp.clientDetails)
        //         setUserListDataFilter(resp.clientDetails)
        //         setClientOptions(mapClientDetailsToOptions(resp.clientDetails));
        //     }

        //     setLoader(false)
        // }).catch((error) => {
        //     setLoader(false)
        // });

        getProdConfiguration().then(resp => {
            if (resp) {

                setClientId(resp.clientId)
                setReqParam(prevState => ({
                    ...prevState,
                    clientId: resp === null ? null : resp.clientId // Update only the clientId field, for example
                }));
                dashboardTotalCountReqCall(resp.clientId,
                    reqParamDBApi.fromDate, reqParamDBApi.toDate);
            }
        }).catch(error => {
            setLoader(false)
        })

    }

    const dashboardTotalCountReqCall = (clientId, fromDate, toDate) => {
        let param = {
            "clientId": clientId,
            "fromDate": fromDate === null ? null : new Date(fromDate).toLocaleDateString("en-CA"),
            "toDate": toDate === null ? null : new Date(toDate).toLocaleDateString("en-CA"),
        }
        setLoader(true)
        dashboardCountReqCall(param).then((resp) => {
            if (resp.statusCode === 'SUCCESS') {
                if (resp.noOfSuccessAndErrorCount) {
                    setNumSuccessFailData(resp.noOfSuccessAndErrorCount)
                } else {
                    setNumSuccessFailData(null)
                }
            } else {
                setNumSuccessFailData(null)
            }

            setLoader(false)
        }).catch((error) => {
            setLoader(false)
        });

    }


    const Control = ({ children, ...props }) => {
        const [inputValue, setInputValue] = useState('');
        const { selectProps, hasValue } = props;

        return (
            <components.Control {...props}>
                <div style={{ position: 'relative', display: 'flex', }}>
                    {/* {hasValue || inputValue ? (
           
          ) : null} */}
                    <label
                        style={{
                            position: 'absolute',
                            left: '6px',
                            top: '-20px',
                            backgroundColor: 'white',
                            padding: '0 4px',
                            fontSize: '80%',
                            fontWeight: '400',
                            fontFamily: 'Poppins',
                            color: '#000000',
                        }}
                    >
                        Select Clients
                        {/* {selectProps.placeholder}
               */}
                    </label>
                    {children}
                </div>
            </components.Control>
        );
    };

    const mapClientDetailsToOptions = (clientDetails) => {
        return clientDetails.map((client) => ({
            value: client.clientId, // using clientId as the value
            label: `${client.fullName} (${client.businessName})` // display businessName and fullName as label
        }));

    };

    return (
        <>
            <MenuBar />

            <div className="sidebar-box sidnav-box">
                <Sidebar></Sidebar>

                <div className="sidebat-inner-box sidnav-inner-box">
                    {loader && <Loader />}
                    <div id="usage-overiview" className='mt-4 mt-sm-2'>
                        <section className='' id='uatenv'>
                            <div className='container-lg container-fluid mt-3 my-sm-3'>
                                <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                                    <div className='col-5'>
                                        <div className='ps-sm-2'>
                                            <h1 className='username-text mb-2 mb-sm-3'>Usage Overview</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div >

                    <section className="pt-2 mt-sm-2 ps-2 pe-2">



                        <div className="p-0 m-0 d-block d-lg-flex d-md-flex d-sm-flex justify-content-between">
                            {/* <div className="p-0 m-0 px-3 py-3">
                                <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    options={clientOptions}
                                    components={{ Control }}
                                    placeholder='All Clients'
                                    isSearchable={true}
                                    onChange={(data) => {
                                        //   console.log(data)
                                        setReqParam(prevState => ({
                                            ...prevState,
                                            clientId: data === null ? null : data.value // Update only the clientId field, for example
                                        }));
                                        dashboardTotalCountReqCall(data === null ? null : data.value,
                                            reqParamDBApi.fromDate, reqParamDBApi.toDate
                                        );
                                    }}

                                />
                            </div> */}
                            <div className="p-0 m-0 px-3 py-3">
                                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                                    <DateRangePicker
                                        cleanable
                                        onChange={(date) => {
                                            //  console.log(date)
                                            setReqParam(prevState => ({
                                                ...prevState,
                                                fromDate: date === null ? null : date[0],
                                                toDate: date === null ? null : date[1]
                                            }));
                                            setDateRange(date);
                                            dashboardTotalCountReqCall(reqParamDBApi.clientId,
                                                date === null ? null : date[0], date === null ? null : date[1]
                                            );
                                        }}
                                        showOneCalendar
                                        block
                                        format="yyyy/MM/dd" color="#4243F1"
                                        placeholder='Select Date Range' value={dateRange} />
                                    <label style={{
                                        position: 'absolute',
                                        left: '16px',
                                        top: '-7px',
                                        backgroundColor: 'white',
                                        padding: '0 4px',
                                        fontSize: '80%',
                                        fontWeight: '400',
                                        fontFamily: 'Poppins',
                                        color: '#000000',
                                    }}>Select Date</label>

                                </div>
                            </div>

                        </div>

                        {/* <div className="container-lg container-fluid my-md-4 p-0 m-0 pt-3 pt-lg-0 pt-md-0 pt-sm-0">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 bank-state-box d-flex flex-wrap align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-sm-12">
                <img src={AdminIllustrator} alt="admin" className="admin-logo" />
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-sm-12 p-0 m-0 pt-2">
                <h4 className="guide-title">
                  Welcome to Admin
                </h4>
                <h4 className="guide-title" style={{ color: "#4243F1", fontSize: "36px" }}>
                  {userData == null ? "" : userData.name}
                </h4>
              </div>
            </div>
          </div>
        </div> */}

                        {<div className="px-2 mt-2">
                            <div className=" p-0 m-0 pt-2 ps-2 pe-2">
                                <div className="row p-0 m-0 d-flex flex-wrap justify-content-between">

                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 pe-lg-2 pe-md-2 pe-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Total Credit Report Fetch'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfCreditPullRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.creditPullSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.creditPullFailed}>
                                        </CardCounter>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 px-lg-2 px-md-2 px-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Full Match Credit Report Fetch'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfFullMatchCreditPullRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.fullMatchCreditPullSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.fullMatchCreditPullFailed}>
                                        </CardCounter>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 px-lg-2 px-md-2 px-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Enhance Match Credit Report Fetch'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfEnhanceMatchCreditPullRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.enhanceMatchCreditPullSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.enhanceMatchCreditPullFailed}>
                                        </CardCounter>
                                    </div>

                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 pe-lg-2 pe-md-2 pe-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Total Account Aggregator Fetch'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfAccountAggregatorRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.accountAggregatorSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.accountAggregatorFailed}>
                                        </CardCounter>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 px-lg-2 px-md-2 px-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Total Credit Report Upload'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfcreditReportParsingRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.creditReportParsingSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.creditReportParsingFailed}>
                                        </CardCounter>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4 p-0 m-0 my-2 px-lg-2 px-md-2 px-sm-2">
                                        <CardCounter logo={ICAdminCard} label={'Total Bank Statement Upload'}
                                            count={numSuccessFailData === null ? '-' : numSuccessFailData.totalNumberOfStatementUploadDataExtractionRequest}
                                            successCount={numSuccessFailData === null ? '-' : numSuccessFailData.statementUploadDataExtractionSuccess}
                                            failCount={numSuccessFailData === null ? '-' : numSuccessFailData.statementUploadDataExtractionFailed}>
                                        </CardCounter>
                                    </div>

                                </div>
                            </div>
                        </div>}

                        {/* {numSuccessFailData === null &&
          <div className="info-details-box py-5 mt-5 mx-3">
            <h3 className="p-0 m-0 text-center  acc-info-text">Data is not found!</h3>
          </div>
        } */}


                    </section>
                </div>
            </div>




        </>
    );
}
export default UsageOverview;
