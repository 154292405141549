import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './billingpage.css';
import Sidebar from '../Dashboard/SideBar/sidebar';
import MenuBar from '../Dashboard/Menubar/menubar';
import BillTableComponent from './bill-table';
import { getCommonUATClientTokenB2B } from '../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../Component/GenetateProdClientTokenB2B';
// import { getInvoiceData } from '../../redux/actions/b2b_action';
import { getProdInvoiceData } from '../../redux/actions/prod-b2b_action';
import Loader from '../loader/loader';

import Dropdown from 'react-dropdown';
const DropdownIcon = require("../../images/drop_point.png")

const years = [
    'Select year',
];

const months = [
    'Select month', 'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
    'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

let selectedMonth = 'Select month'; //defualt
let selectedYear = 'Select year'; //defualt


let invoiceData = [];
const BillingPage = (props) => {
    const [isClearFilter, setClearFilter] = useState(false);
    const [invoiceBillingDt, setinvoiceBillingData] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        invoiceData = []
        setLoader(true)
        getCommonProdClientTokenB2B(
            () => {
                setLoader(false)
                const param = {
                    clientId: localStorage.getItem('prodclientId')
                }
                getProdInvoiceData(param).then(resp => {
                    setLoader(false);
                    if (resp && resp.data.statusCode === "SUCCESS") {
                        var invoiceDt = resp.data.invoiceDataDTOList
                        for (let index = 0; index < invoiceDt.length; index++) {
                            const year = invoiceDt[index].year;
                            if (!years.includes(year)) {
                                years.push(year);
                            }
                            invoiceData.push({
                                id: index + 1,
                                month: invoiceDt[index].month + ' ' + invoiceDt[index].year,
                                amount: 'INR ₹' + Number(invoiceDt[index].amount).toFixed(2),
                                status: invoiceDt[index].status,
                                invoice: invoiceDt[index].billingId,
                            })
                            setinvoiceBillingData(invoiceData)
                        }

                    }
                }).catch((error) => {
                    setLoader(false);
                });
            },
            (errorMessage) => {
                setLoader(false);
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }, [])

    const filterByMonth = (e) => {
        if (e.value === 'Select month') {
            clearFilter(true);
            return;
        }
        setClearFilter(false);
        selectedMonth = e.value;
        const filteredDatas = invoiceData.filter(data => data.month === e.value + " " + selectedYear);
        setinvoiceBillingData(filteredDatas);
    };

    const filterByYear = (e) => {
        if (e.value === 'Select year') {
            clearFilter(true);
            return;
        }
        setClearFilter(false);
        selectedYear = e.value;
        var filteredDatas;
        if (selectedMonth === 'Select month') {

            filteredDatas = invoiceData.filter(data => selectedYear === e.value);
        } else {
            filteredDatas = invoiceData.filter(data => data.month === selectedMonth + " " + e.value);
        }
        setinvoiceBillingData(filteredDatas);
    };

    function clearFilter() {
        selectedMonth = 'Select month'; //defualt
        selectedYear = 'Select year'; //defualt
        setClearFilter(true);
        setinvoiceBillingData(invoiceData);
    }

    return (<>

        <MenuBar />

        <div className='sidebar-box sidnav-box'>
            {loader && <Loader></Loader>}
            <Sidebar></Sidebar>
            <div className="sidebat-inner-box sidnav-inner-box">
                <div className='boost-score-heding-page'>
                    <div className='mt-4 mt-sm-2'>
                        <section className='' id='creditscore'>
                            <div className='container-lg container-fluid mt-3 my-sm-3'>
                                <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                                    <div className='col-5'>
                                        <div className='ps-sm-2'>
                                            <h1 className='username-text mb-2 mb-sm-3'>Billing</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div >

                    <section className='ps-2'>
                        <div className='container-lg container-fluid mt-4 my-sm-3'>
                            <div className='tbl-main-frame'>
                                <p className='tbl-head-text p-3 pb-0'>
                                    PAYMENT HISTORY AND INVOICING
                                </p>
                                <div className='tbl-full-hr-line'></div>

                                <div className='d-flex flex-column align-items-start pt-2 pb-1'>
                                    <p className='tbl-sub-text m-0 ps-3 pb-2'>
                                        Filter by month & year
                                    </p>
                                    <div className='d-flex flex-wrap flex-row'>
                                        <div className="btn-group ps-3">
                                            <div className='tbl-dropdown'>
                                                <Dropdown controlClassName='dropdown-control'
                                                    arrowClassName='dropdown-pointer-icon'
                                                    onChange={filterByMonth} value={selectedMonth}
                                                    options={months} placeholder="Jan" />
                                                {(selectedMonth != 'Select month') && !isClearFilter && <div className='active-dot'></div>}
                                            </div>
                                        </div>

                                        <div className="btn-group ps-1">
                                            <div className='tbl-dropdown'>
                                                <Dropdown
                                                    controlClassName='dropdown-control'
                                                    arrowClassName='dropdown-pointer-icon'
                                                    onChange={filterByYear} value={selectedYear}
                                                    options={years} placeholder="2023" />
                                                {(selectedYear != 'Select year') && !isClearFilter && <div className='active-dot'></div>}
                                            </div>
                                        </div>

                                        {(selectedMonth != 'Select month' || selectedYear != 'Select year') &&
                                            <p className='underline-txt-primary align-self-center pt-0 ps-3'
                                                onClick={clearFilter}>
                                                Clear Filter
                                            </p>}
                                    </div>

                                </div>


                                <div className='pt-3 pb-3 ps-4 pe-4'>
                                    <div className='tbl-border'>
                                        <BillTableComponent
                                            data={invoiceBillingDt}
                                            direction="auto"
                                            fixedHeaderScrollHeight="300px"
                                            pagination
                                            responsive={true}
                                            subHeaderAlign="center"
                                            subHeaderWrap
                                            striped
                                        />
                                    </div>

                                </div>

                                <div className='pt-3'></div>



                            </div>

                        </div>

                    </section>
                </div >
            </div>
        </div >
    </>);
};

export default BillingPage;