import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './gst-upload.css'
import '../../../App.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Loader from '../../loader/loader';
import { gstUploadApi } from '../../../redux/actions/b2b_action';
import { gstUploadApiProd } from '../../../redux/actions/prod-b2b_action';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';

const GSTUploadDocuments = (props) => {

    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedBureau, setSelectedBureau] = useState();
    const [bureauError, setBureauError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [showError, setShowError] = useState(false);
    const [uploadError, setuploadError] = useState()

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const skipBtn = (event) => {
        props.setIsOpenBankStmDialog(true)
        props.setIsOpenDialog(false)
        // props.closeDialog()
    }


    const sumbitCreditBureau = () => {
        setShowError(false)

        if (props.openFromProd) {
            fetchCreditReport(getCommonProdClientTokenB2B, selectedFiles, selectedBureau, props, pwd);
        } else {
            fetchCreditReport(getCommonUATClientTokenB2B, selectedFiles, selectedBureau, props, pwd);
        }
    }

    const fetchCreditReport = async (getToken, selectedFiles, selectedBureau, props, pwd) => {
        try {
            await getToken(async () => {
                const param = new FormData();
                param.append("file", selectedFiles[0]);
                param.append("password", pwd);
                param.append("businessUserId", props.businessId);
                const uuid = uuidv4();

                setLoader(true);
                const resp = props.openFromProd ? await gstUploadApiProd(param, uuid)
                    : await gstUploadApi(param, uuid);
                setLoader(false);

                if (resp.statusCode === "SUCCESS") {
                    if (props.businessId !== "") {
                        props.setIsOpenBankStmDialog(false);
                    }
                    props.closeDialog();
                    props.getTestUserList();
                    props.setMessage({ success: resp.statusMessage });
                } else if (resp.statusCode === "ERROR") {
                    setuploadError(resp.statusMessage);
                }



                // props.scrollToTop();
                // window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                // });
            },
                (errorMessage) => {
                    console.error('Error:', errorMessage); // Handle error
                });
        } catch (error) {
            setLoader(false);
            if (error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404") {
                setuploadError(error.errorMessage);
            }
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className='container'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>

                    <div className="col-12 my-2">
                        <div className="label-label mb-2">Upload File</div>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please upload file</span>}
                    </div>
                    <div className='col-12 '>
                        <div className='label-label mb-2'>Password</div>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter Password"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>
                    {/* <div>
                        <p className='demo-tetx'>{bureauError === undefined ? '' : bureauError}</p>
                    </div> */}
                    <div className='pt-2'>
                        <p className='demo-tetx'>{uploadError != "" ? uploadError : ''}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-items-center">

                        <button className="upload-statement-butoon me-0" type="button" onClick={() => {
                            sumbitCreditBureau()
                        }} >Submit</button>
                    </div>
                </form>}
            </div>
        </>
    )
}

export default GSTUploadDocuments;