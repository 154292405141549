import React from 'react';
import './pagination.css';

const PaginationComponent = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
    const totalPages = Math.ceil(rowCount / rowsPerPage);
    const maxPageNumbersToShow = 5;

    const handleClick = page => {
        if (page >= 1 && page <= totalPages) {
            onChangePage(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handleClick(i)}
                        className={`page-number ${currentPage === i ? 'active' : ''}`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handleClick(1)}
                    className={`page-number ${currentPage === 1 ? 'active' : ''}`}
                > 1
                </button>
            );

            if (startPage > 2) {
                pageNumbers.push(<span key="start-ellipsis" className="ellipsis">...</span>);
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handleClick(i)}
                        className={`page-number ${currentPage === i ? 'active' : ''}`}>
                        {i}
                    </button>
                );
            }

            if (endPage < totalPages - 1) {
                pageNumbers.push(<span key="end-ellipsis" className="ellipsis">...</span>);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handleClick(totalPages)}
                    className={`page-number ${currentPage === totalPages ? 'active' : ''}`}
                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };
    const startCount = (currentPage - 1) * rowsPerPage + 1;
    const endCount = Math.min(currentPage * rowsPerPage, rowCount);
    return (
        <div className="pagination-container">
            <div className="user-count"><span className='user-count dark'>{startCount}-{endCount}</span>{` of ${rowCount} Users`}</div>
            <div className="pagination-wrapper">
                <button
                    onClick={() => handleClick(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="page-button"
                >
                    Prev
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => handleClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="page-button">
                    Next
                </button>
            </div>
        </div>
    );
};

export default PaginationComponent;
