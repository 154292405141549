import httpClientDemoB2B from "../../config/demo";

const reportDownload = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientDemoB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/boost/report/get',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const downloadExcelReport = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClientDemoB2B({
      method: 'POST',
      responseType: 'blob',
      url: 'api/v1/b2b/boost/report/get/excel',
      data: data,
      headers: { "x-reference-id": uuid }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

export {
  reportDownload,
  downloadExcelReport
}