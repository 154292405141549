import React, { useEffect, useState, useRef } from 'react';
import './bank-statement.css';
import './accordition-multiple.css'
import '../../../App.css'
import AsyncSelect from 'react-select/async';
import { multipleUploadStatement, getBankList } from '../../../redux/actions/b2b_action';
import { multipleUploadStatementProd, getBankListProd } from '../../../redux/actions/prod-b2b_action';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import Loader from '../../loader/loader';
// import { uuid } from '../../Utils/uuid';
import { v4 as uuidv4 } from 'uuid';
import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import $ from 'jquery';
import DragDropFiles from '../../Component/dragdrop/DragDropFiles';
// const Back = require("../../../images/assets/back.png")
const Close = require("../../../images/assets/close.png");

const IC_EYE = require("../../../images/assets/ic_eye.png")
const IC_EYE_OFF = require("../../../images/assets/ic_eye_close.png")
const IC_ARROW = require("../../../images/assets/ic-arrow.png")


const BankStatmentMultiple = (props) => {

    const [selectedBank, setSelectedBank] = useState();
    const [selectedFiles, setSelectedFiles] = useState();
    const [loader, setLoader] = useState(false);
    const [scoreError, setScoreError] = useState();
    const [showError, setShowError] = useState(false);
    const [bankList, setBankList] = useState();
    const [uploadError, setUploadError] = useState(false);
    const [uploadErrMsg, setUploadErrMsg] = useState();
    // const [passwordDt, setPasswordDt] = useState('');
    // const [passType, setPasswordType] = useState('password');
    // const [eyeIcon, setPasswordIcon] = useState(IC_EYE_OFF);
    // const [selectedAccountType, setSelectedAccountType] = useState('');
    // const [openMultipleScreen, setOpenMultipleScreen] = useState('');
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false);
    /// accordionItems
    const [accordionItems, setAccordionItems] = useState([]);
    const [accordionErrors, setAccordionErrors] = useState({});


    let businessUserId
    let startFlow = props?.startFlow

    useEffect(() => {
        setLoader(true)
        if (accordionItems.length == 0) {
            addAccordionItem();
        }
        const param = {
            "businessUserId": props.businessId
        }

        if (props.openFromProd) {
            fetchBankList(getCommonProdClientTokenB2B, getBankListProd, param);
        } else {
            fetchBankList(getCommonUATClientTokenB2B, getBankList, param);
        }


    }, []);

    const fetchBankList = (getToken, getBankListFunction, param) => {
        getToken(() => {
            getBankListFunction(param)
                .then(resp => {
                    setLoader(false);
                    if (resp.data.statusCode === "SUCCESS") {
                        setBankList(resp.data.bankList);
                    }
                })
                .catch(error => {
                    setLoader(false);
                    console.error('Error:', error); // Handle error
                });
        },
            errorMessage => {
                console.error('Error:', errorMessage); // Handle error
            });
    };

    /// check validation for button enable/disable
    useEffect(() => {
        checkValidForButtonEnable();
        setAccordionErrors({});
    }, [accordionItems]); /// check validation for button enable/disable


    const addAccordionItem = () => {
        const newItem = {
            id: accordionItems.length + 1,
            selectedBank: '',
            selectedAccountType: '',
            selectedFiles: [],
            passwordDt: '',
            showError: false,
            passType: 'password',
            eyeIcon: IC_EYE_OFF
        };
        /// HIDE ALL PANELS
        if (accordionItems.length != 0) {
            $('.accordion__panel').attr('hidden', true);
        }

        setAccordionItems([...accordionItems, newItem]);
    };

    const deleteAccordionItem = (id) => {
        // Filter out the item with the specified ID
        const updatedAccordionItems = accordionItems.filter(item => item.id !== id);
        // Update the IDs of the remaining items
        const updatedItems = updatedAccordionItems.map((item, index) => ({
            ...item,
            id: index + 1 // Update the ID based on the new index
        }));
        setAccordionItems(updatedItems);
    };

    const loadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filterOptions = bankList.filter(option => option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            callback(filterOptions);
        }, 1000)
    };


    const onChangeAccountType = (value) => {
        setUploadError(false)

        let valData = value ? value.value : "";
        // setSelectedAccountType(valData)
    }

    const skipBtn = (event) => {
        props.closeMultiBankStmDialog()
    }

    const backFiuErrorScreen = () => {
        props.setFiuErrorScreen(true);
        props.setFiuErrorModel(true);
        props.setIsFiuOpenDialog(false);
        props.setFipTspModel(false);
    }

    const updateAccordionItemData = (id, newData) => {
        setAccordionItems(prevItems => {
            return prevItems.map(item => {
                if (item.id === id) {
                    return { ...item, ...newData };
                }
                return item;
            });
        });
    };

    const checkValidForButtonEnable = () => {
        let isValid = true;
        accordionItems.forEach(item => {
            if (item.selectedBank === undefined || item.selectedBank === ''
                || item.selectedAccountType === undefined || item.selectedAccountType === ''
                || item.selectedFiles.length === 0) {
                isValid = false;
            }
        });
        setSubmitBtnEnable(isValid);
    }

    const sumbitBankStatement = () => {
        setUploadErrMsg(false)
        let isValid = true;
        const errors = {};

        accordionItems.forEach(item => {
            if (!item.selectedBank || item.selectedBank === '') {
                errors[item.id] = {
                    selectedBank: 'Please select a bank'
                };
                isValid = false;
            }
            if (!item.selectedAccountType || item.selectedAccountType === '') {
                errors[item.id] = { ...errors[item.id], selectedAccountType: 'Please select account type' };
                isValid = false;
            }
            if (!item.selectedFiles || item.selectedFiles.length === 0) {
                errors[item.id] = { ...errors[item.id], selectedFiles: 'Please select file(s)' };
                isValid = false;
            }
            // Set the errors state
            setAccordionErrors(errors);
        });
        if (isValid) {

            var param = new FormData();
            param.append("businessUserId", props.businessId);
            param.append("isFromSDK", true);
            // console.log("Accordion items:", accordionItems);
            for (let i = 0; i < accordionItems.length; i++) {
                const item = accordionItems[i];

                param.append("statements[" + i + "].bank", item.selectedBank);
                param.append("statements[" + i + "].accountType", item.selectedAccountType);
                param.append("statements[" + i + "].password", item.passwordDt);

                let filesArray = Array.from(item.selectedFiles);
                filesArray.forEach((file, fileIndex) => {
                    param.append(`statements[${i}].bankStmts[${fileIndex}]`, file);
                });
            }
        }
        const uuid = uuidv4();
        setLoader(true)


        const sumbitBankStatement = props.openFromProd ? multipleUploadStatementProd(param, uuid) : multipleUploadStatement(param, uuid);
        sumbitBankStatement.then(resp => {
            setLoader(false);
            // props.closeBankStmDialog()
            // props.getTestUserList()

            if (resp.statusCode === "SUCCESS") {
                props.setFiuMultiBankSPF("SUCCESS");
            } else if (resp.statusCode === "PARTIAL_SUCCESS") {
                props.setFiuMultiBankSPF("PARTIAL_SUCCESS");
            } else if (resp.statusCode === "ERROR") {
                props.setFiuMultiBankSPF("ERROR");
            }
            props.setFiuPartialMsg({ msg: resp.statusMessage, uuid: uuid })
            props.openStatmentResSPFDialog();

        }).catch((error) => {
            setLoader(false)
            if (error?.errorCode === "B2B_STATEMENT_UPLOAD_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_STATEMENT_UPLOAD_500") {
                setUploadErrMsg(true)
                setUploadErrMsg(error.errorMessage);
            }
        })

    };

    const closeSDK = () => {
        var url = new URL(window.location.href)
        var searchParams = url.searchParams
        var businessUserId = searchParams.get('businessUserId')
        var redirectUrl = searchParams.get('redirectUrl')
        if (redirectUrl) {
            window.open(redirectUrl + '?status=CANCEL&businessUserId=' + businessUserId + '&referenceId=' + uuidv4, '_blank')
        }
    }
    const btnEnableDisable = {
        opacity: submitBtnEnable ? 1.0 : 0.5
    };

    return (
        <>
            {loader && <Loader />}
            <div className='container-box'>
                {/* <div className='header-action'>
                    <img src={Back} className="text-start" onClick={() => { backFiuErrorScreen() }} alt='back' />
                    {/* <img src={Close} className="float-end img-close-logo" onClick={() => { closeSDK() }} alt='close' />  </div>     */}

                <form className="row g-2 needs-validation" noValidate >
                    <div className='accordition-content'>
                        <Accordion className='' allowZeroExpanded>
                            {accordionItems.map(item => (
                                <AccordionItems
                                    key={item.id}
                                    item={item}
                                    deleteItem={() => deleteAccordionItem(item.id)}
                                    bankList={bankList}
                                    loadOptions={loadOptions}
                                    updateItemData={updateAccordionItemData}
                                    errors={accordionErrors[item.id]}
                                // onSubmit={(data) => onSubmit(data)}

                                />
                            ))}
                        </Accordion>
                    </div>
                    <div className="d-flex flex-wrap mt-1 justify-content-center ">
                        <p className="blue-light-text btn-upload-statements"
                            onClick={() => {
                                addAccordionItem();
                            }}>Add more accounts
                        </p>
                    </div>
                    {uploadError && <span className="demo-tetx mt-2">{uploadErrMsg}</span>}
                    {uuidv4 && (uploadError || scoreError) && <p className='reference-info'>Reference ID : {uuidv4}</p>}

                    <div className="col-12 text-center mt-3">
                        <button className="upload-statement-butoon" style={btnEnableDisable} type="button" onClick={() => {
                            sumbitBankStatement()
                        }}>Upload Bank Statement</button>
                    </div>

                    {/* <div className="col-12 mt-2 d-flex justify-content-around p-0 mt-2">
                        <button className='skip-link border-0 p-0' onClick={() => {
                            skipBtn();
                        }}>Skip</button>
                    </div> */}
                </form>
            </div>
        </>
    )
};
const AccordionItems = ({ item, deleteItem, bankList, loadOptions, updateItemData, errors }) => {
    const { id, selectedBank, selectedAccountType, selectedFiles, passwordDt, showError, } = item;
    const [localSelectedBank, setLocalSelectedBank] = useState(selectedBank);
    const [localSelectedAccountType, setLocalSelectedAccountType] = useState(selectedAccountType);
    const [localSelectedFiles, setLocalSelectedFiles] = useState(selectedFiles);
    const [localPasswordDt, setLocalPasswordDt] = useState(passwordDt);
    const [passType, setPasswordType] = useState('password');
    const [eyeIcon, setPasswordIcon] = useState(IC_EYE_OFF);
    const accountTypes = [
        { value: 'SAVING', label: 'Saving' },
        { value: 'CURRENT', label: 'Current' }
    ];
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        handleSubmit();
    }, [localSelectedBank, localSelectedAccountType, localSelectedFiles, localPasswordDt]);

    const handleSubmit = () => {
        // Collect data from local state
        const newData = {
            selectedBank: localSelectedBank == null ? '' : localSelectedBank.value,
            selectedAccountType: localSelectedAccountType == null ||
                localSelectedAccountType === undefined ? '' : localSelectedAccountType.value,
            selectedFiles: localSelectedFiles ?? [],
            passwordDt: localPasswordDt ?? '',
            showError: false,
            passType: 'password',
            eyeIcon: IC_EYE_OFF
        };
        // Update data of the item in parent state
        updateItemData(item.id, newData);
    };

    const onBankChange = (selectedOption) => {
        setLocalSelectedBank(selectedOption);
    };

    const onChangeAccountType = (selectedOption) => {
        setLocalSelectedAccountType(selectedOption);
    };

    const onFileSelect = (files) => {
        // if (event.target.files.length > 0) {
        // }
        setLocalSelectedFiles(files);
    };

    const clearSelectedFiles = (ids) => {
        var fileInput = document.getElementById(`file-${ids}`);
        fileInput.value = ''
        setLocalSelectedFiles([]);

    };

    const handleTogglePassword = () => {
        const newPassType = passType === 'password' ? 'text' : 'password';
        setPasswordIcon(passType === 'password' ? IC_EYE : IC_EYE_OFF);
        setPasswordType(newPassType);

    };

    const handleAccordionClick = (id, expanded) => {
        var $icArrow = $(`#arrow-${id}`);
        if (expanded) {
            if ($icArrow.length > 0) {
                $icArrow.addClass('rotate-ic-min90')
            }
            const $panel = $(`#accordionItem-${id}`);
            if ($panel.length > 0) {
                const offset = $panel.offset();
                if (offset) {
                    const scrollTopValue = offset.top - $('.accordition-content').offset().top + $('.accordition-content').scrollTop();
                    $('.accordition-content').animate({
                        scrollTop: scrollTopValue
                    }, 500);
                } else {
                    console.log("Offset is undefined.");
                }
            } else {
                console.log("Panel element not found.");
            }

        } else {
            if ($icArrow.length > 0) {
                $icArrow.removeClass('rotate-ic-min90')
            }
        }
    };

    const fileInputRef = useRef(null);

    const handleChooseFilesClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            onFileSelect(files);
        }
    };

    const handleRemoveFile = (id) => {
        const filesArray = Array.from(localSelectedFiles);
        if (id >= 0 && id < filesArray.length) {
            filesArray.splice(id, 1); // Remove the file at the specified index
        }
        setLocalSelectedFiles(filesArray);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        // console.log(event.dataTransfer.files)
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            onFileSelect(files);
        }
    };

    return (
        <AccordionItem className='pb-2' id={`accordionItem-${id}`}>
            <div className='accordion__button__items'>
                <AccordionItemButton className={errors ? 'accordion__button error-border' : 'accordion__button'}>
                    Account {id}
                    <AccordionItemState >
                        {({ expanded }) => {
                            handleAccordionClick(id, expanded)
                            // Ensure you return null or a JSX element here
                        }}
                    </AccordionItemState>
                    <img id={`arrow-${id}`} src={IC_ARROW} alt='ic_arrow' className='accordion__dropdown_ic' />
                </AccordionItemButton>
            </div>
            <AccordionItemPanel>

                <div className='col-12 d-flex flex-wrap'>
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 pe-lg-1">
                        <div htmlFor="validationCustom04" className="label-label mb-2">Bank Name</div>
                        <AsyncSelect loadOptions={loadOptions}
                            onChange={onBankChange}
                            defaultOptions={bankList}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select your bank" />
                        {errors && errors.selectedBank && <span className="demo-tetx">{errors.selectedBank}</span>}
                    </div>
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 ps-1">
                        <div htmlFor="validationCustom04" className="label-label mb-2">Account Type</div>
                        <AsyncSelect options={accountTypes}
                            onChange={onChangeAccountType}
                            isSearchable={false}
                            defaultOptions={accountTypes}
                            isClearable={true}
                            placeholder="Select account type" />
                        {errors && <span className="demo-tetx">{errors.selectedAccountType}</span>}
                    </div>
                </div>
                <div className="col-12 my-2">
                    <div className="label-label mb-2">Select File</div>
                    <div className={`drag-root-area ${dragging ? 'drag-over' : ''}`}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>

                        <div className='file-area'>
                            {localSelectedFiles.length > 0 &&
                                <div className="file-list">
                                    {Array.from(localSelectedFiles).map((localSelectedFiles, idx) =>
                                        <div className='d-flex flex-row justify-content-between file-item' key={idx}>
                                            <p className='m-0 p-0'>{localSelectedFiles.name}</p>
                                            <img src={Close} className="close-file-ic ms-2"
                                                onClick={() => handleRemoveFile(idx)}>
                                            </img>
                                        </div>
                                    )}
                                </div>}
                            <div className='custom-file-input'>
                                <input
                                    id={`file-${id}`}
                                    type="file"
                                    multiple
                                    className="m-0 p-0"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    style={{ display: 'none' }}
                                />
                                <div className='d-flex flex-row justify-content-center position-sticky'>
                                    <p className="blue-light-text btn-upload-statements"
                                        onClick={handleChooseFilesClick}
                                    > Choose files</p>
                                    <p className='btn-upload-statements ps-2'>or drag it here</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* <div className="input-container">
                        <input id={`file-${id}`} type='file' multiple="multiple" className='form-control input-box'
                            onChange={(event) => { onFileSelect(event) }} />

                    </div> */}
                    {errors && errors.selectedFiles && <span className="demo-tetx">{errors.selectedFiles}</span>}
                </div>

                {/* {uploadError && <span className="demo-tetx mt-1">{uploadErrMsg}</span>} */}
                <div className="col-12 my-2">
                    <div className="label-label mb-2">File Password</div>
                    <div className="form-control">
                        <div className='d-flex'>
                            <input id={`passType-${id}`} type={passType} className='input-box col-11 border-0'
                                placeholder='Enter file password (Optional)' value={localPasswordDt || ''} onChange={(e) => {
                                    setLocalPasswordDt(e.target.value)
                                }} />
                            <img src={eyeIcon} className="eye-icon col-1 pb-1 ms-lg-4 ms-0" onClick={handleTogglePassword}></img>
                        </div>
                    </div>
                </div>

                {id != 1 && <div className="d-flex flex-wrap pt-2 pb-2 justify-content-center ">
                    <p className="blue-light-text btn-upload-statements"
                        onClick={() => deleteItem(id)}>Delete account
                    </p>
                </div>}
            </AccordionItemPanel>
        </AccordionItem>
    );


}
export default BankStatmentMultiple;