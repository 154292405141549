import httpClientBSAProdB2B from "../../config/bsa_b2b_prod_action";


const getBankListProdBSA = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAProdB2B({
            method: 'GET',
            url: 'api/v1/bank/list',
            headers: { "x-reference-id": uuid }

        },).then(resp => {
            resolve(resp.data);
        })
            .catch(error => {
                reject(error);
            });
    });

const statementProdList = (uuid) =>
    new Promise((resolve, reject) => {

        httpClientBSAProdB2B({
            method: 'GET',
            url: 'api/v1/statement/list',
            headers: { "x-reference-id": uuid }

        },)
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error);
            });
    });

const multipleUploadStatementProdBSA = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAProdB2B({
            method: 'POST',
            url: 'api/v1/uploadMultipleStatements',
            data: data, headers: {
                "x-business-user-id": uuid,
                "Content-Type": "multipart/form-data",
                "x-reference-id": uuid
            }
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const downloadExcelProd = (id, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAProdB2B({
            method: 'GET',
            url: 'api/v1/excel/summary/' + id,
            headers: {
                "x-reference-id": uuid,
                "Content-Type": "application/octet-stream",
            },
            responseType: 'blob',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
const downloadAnalticsProd = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAProdB2B({
            method: 'POST',
            url: 'api/v1/download/fraud/analytics/pdf/report',
            data: data,
            headers: { "x-reference-id": uuid },
            responseType: 'blob',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

export {
    getBankListProdBSA,
    statementProdList,
    multipleUploadStatementProdBSA,
    downloadExcelProd,
    downloadAnalticsProd
}