import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';
import './bank-statement.css';
import '../../../App.css'
import AsyncSelect from 'react-select/async';
import 'react-dropdown/style.css';
import { multipleUploadStatement, getBankList } from '../../../redux/actions/b2b_action';
import { multipleUploadStatementProd, getBankListProd } from '../../../redux/actions/prod-b2b_action';
import Loader from '../../loader/loader';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';
import BankStatmentMultiple from './bank-statement-multiple.js'
const PoweredBy = require("../../../images/assets/Experian_logo.png");

const BankStatmentForm = (props) => {

    const [selectedBank, setSelectedBank] = useState();
    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedAccount, setSelectedAccount] = useState('SAVING')
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [scoreError, setScoreError] = useState();
    const [showError, setShowError] = useState(false);
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [uploadError, setuploadError] = useState()
    const [bankList, setBankList] = useState();
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [openMultipleScreen, setOpenMultipleScreen] = useState(false);

    const accountTypes = [
        { value: 'SAVING', label: 'Saving' },
        { value: 'CURRENT', label: 'Current' }
    ];

    useEffect(() => {
        ReactGA.event({
            category: "Component/Page_Render",
            action: "bank_upload_component_render",
            label: `bank_upload_component_render`,
        });
        const param = {
            businessUserId: props.businessId
        }
        setLoader(true)
        if (props.openFromProd) {
            fetchBankList(getCommonProdClientTokenB2B, getBankListProd, param);
        } else {
            fetchBankList(getCommonUATClientTokenB2B, getBankList, param);
        }

    }, [])

    const fetchBankList = (getToken, getBankListFunction, param) => {
        getToken(() => {
            getBankListFunction(param)
                .then(resp => {

                    setLoader(false);
                    if (resp.data.statusCode === "SUCCESS") {
                        ReactGA.event({
                            category: "API_CALL",
                            action: props.openFromProd ? 'api_call_get_bank_list_prod' : "api_call_get_bank_list_sandbox",
                            label: props.openFromProd ? 'api_call_get_bank_list_prod_success' : "api_call_get_bank_list_sandbox_success",
                        });
                        setBankList(resp.data.bankList);
                    }
                })
                .catch(error => {
                    ReactGA.event({
                        category: "API_CALL",
                        action: props.openFromProd ? 'api_call_get_bank_list_prod' : "api_call_get_bank_list_sandbox",
                        label: props.openFromProd ? 'api_call_get_bank_list_prod_fail' : "api_call_get_bank_list_sandbox_fail",
                    });
                    setLoader(false);
                    console.error('Error:', error); // Handle error
                });
        },
            errorMessage => {
                console.error('Error:', errorMessage); // Handle error
            });
    };

    const loadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filterOptions = bankList.filter(option => option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            callback(filterOptions);
        }, 1000)

    };

    const onBanckChange = (value) => {
        let valData = value ? value.value : "";
        setSelectedBank(valData)
    }
    const onChangeAccountType = (value) => {
        let valData = value ? value.value : "";
        setSelectedAccountType(valData)
    }
    // const onBanckChange = (value) => {
    //     setSelectedBank(value.value)
    // }

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const skipBtn = (event) => {
        props.closeBankStmDialog()
    }
    const openMultipleAccountScreen = () => {
        // setOpenMultipleScreen(true)
        props.openMultipleBankStatement();
    };

    const sumbitBankStatement = () => {
        setShowError(false)
        if (!selectedBank || selectedBank === '' || !selectedFiles || selectedFiles === '') {
            setShowError(true)
        } else {
            var param = new FormData();
            param.append("businessUserId", props.businessId);
            param.append("isFromSDK", false);
            param.append("statements[" + 0 + "].bank", selectedBank);
            param.append("statements[" + 0 + "].accountType", selectedAccountType);
            param.append("statements[" + 0 + "].password", pwd);
            let filesArray = Array.from(selectedFiles);

            filesArray.forEach((file, fileIndex) => {
                param.append(`statements[${0}].bankStmts[${fileIndex}]`, file);
            });
            const uuid = uuidv4();
            setLoader(true)
            const sumbitBankStatement = props.openFromProd ? multipleUploadStatementProd(param, uuid) : multipleUploadStatement(param, uuid);
            sumbitBankStatement.then(resp => {
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_submit_bank_prod' : "api_call_submit_bank_sandbox",
                    label: props.openFromProd ? 'api_call_submit_bank_prod_success' : "api_call_submit_bank_sandbox_success",
                });
                setLoader(false);
                // props.closeBankStmDialog()
                // props.getTestUserList()
                if (resp.statusCode === "SUCCESS") {
                    props.setFiuMultiBankSPF("SUCCESS");
                } else if (resp.statusCode === "PARTIAL_SUCCESS") {
                    props.setFiuMultiBankSPF("PARTIAL_SUCCESS");
                } else if (resp.statusCode === "ERROR") {
                    props.setFiuMultiBankSPF("ERROR");
                }
                props.setFiuPartialMsg({ msg: resp.statusMessage, uuid: uuid })
                props.openStatmentResSPFDialog();

            }).catch((error) => {
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_submit_bank_prod' : "api_call_submit_bank_sandbox",
                    label: props.openFromProd ? 'api_call_submit_bank_prod_fail' : "api_call_submit_bank_sandbox_fail",
                });
                setLoader(false)
                if (error?.errorCode === "B2B_STATEMENT_UPLOAD_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_STATEMENT_UPLOAD_500") {
                    setuploadError(error.errorMessage);
                }
            })
        }
    }

    return (
        <>
            {loader && <Loader />}
            {<div className='container-box'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>
                    <div className="col-12">
                        <div htmlFor="validationCustom04" className="label-label mb-2">Bank</div>

                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    minHeight: "32px", // Reduce height
                                    height: "48px", // Ensure it applies
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px 5px",
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px",
                                }),
                            }}
                            loadOptions={loadOptions}
                            onChange={onBanckChange}
                            defaultOptions={bankList}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select your bank" />
                        {(showError && (!selectedBank || selectedBank === '')) && <span className="demo-tetx">Please select your bank</span>}
                    </div>
                    <div className="col-12 my-2">
                        <div htmlFor="validationCustom04" className="label-label mb-2">Account Type</div>
                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    minHeight: "32px", // Reduce height
                                    height: "48px", // Ensure it applies
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px 5px",
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px",
                                }),
                            }}
                            options={accountTypes}
                            onChange={onChangeAccountType}
                            isSearchable={false}
                            defaultOptions={accountTypes}
                            isClearable={true}
                            placeholder="Select account type" />
                        {(showError && (!selectedAccountType || selectedAccountType === '')) && <span className="demo-tetx">Please select account type</span>}
                    </div>
                    <div className="col-12 my-2">
                        <div className="label-label mb-2">Upload File</div>
                        <input type='file' multiple className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please select file</span>}
                    </div>
                    <div className='col-12 '>
                        <div className='label-label mb-2'>Password</div>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter Password"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap mt-4 justify-content-center ">
                        <p className="grey-normal-text btn-upload-statements">Multiple bank accounts?</p>
                        <p
                            className="blue-light-text btn-upload-statements ps-1"
                            onClick={() => {
                                openMultipleAccountScreen();
                            }}>Upload Statements
                        </p>
                    </div>

                    {scoreError && <span className="demo-tetx">{scoreError}</span>}
                    {/* <div>
                        <p className='demo-tetx'>{uploadError != "" ? uploadError : ''}</p>
                    </div> */}
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className='skip-link ps-1' onClick={() => {
                            skipBtn()
                        }}>Skip</p>
                        <button className="upload-statement-butoon me-0" type="button" onClick={() => {
                            sumbitBankStatement()
                        }}>Submit</button>
                    </div>
                </form>}
            </div>}
        </>
    )
}

export default BankStatmentForm;