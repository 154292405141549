import React from 'react'
import '../../../App.css';
import { useEffect, useState } from 'react';
import Footer from '../../landing-page/Footer/Footer';
import Loader from '../../loader/loader';
import { Link, useNavigate } from 'react-router-dom';
import { fogotPassword } from '../../../redux/actions/user_action';

const Logo = require("../../../images/assets/Logo.png");

const EmailPassword = () => {
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [showError, setShowError] = useState(false);
    const [emailVerify, setEmailVerify] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const resetPassword = () => {
        setEmailVerify('')
        setShowError(false)
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setEmailVerify('Please enter valid email address');
        } else {
            setLoader(true);
            const param = {
                email: email
            }
            fogotPassword(param).then(resp => {
                setLoader(false);
                if (resp.statusCode === "SUCCESS") {
                    navigate('/reset-password', { state: { email: email } });
                }
            }).catch(error => {
                if (error && error?.response && error?.response?.data) {
                    setErrorMessage(error?.response.data.errorMessage)
                } else {
                    setErrorMessage('Something went wrong!');
                }
                setLoader(false);
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            resetPassword()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Reset your password using email
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Reset Password
                                    </h1>
                                    <p className='fw-semibold mt-md-3'>
                                        Enter the email linked to your account and we’ll send you a link to reset your password
                                    </p>
                                    <div className='form-input-box login-box'>
                                        <form>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Email</div>
                                                </div>
                                                <div>
                                                    <input type="email" id='eamil' className='input-box form-control' value={email} onChange={(e) => { setEmail(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && email === '') && <span className="demo-tetx">Please enter your email</span>}
                                                    {emailVerify !== '' && <span className="demo-tetx mt-2">{emailVerify}</span>}
                                                </div>
                                            </div>
                                            {errorMessage !== '' && <p className="demo-tetx mt-2">{errorMessage}</p>}

                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>

                                                    <div className='col-12 order-1 order-md-2 text-end'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button type='button' className='next-button mx-0'
                                                                onClick={() => {
                                                                    resetPassword();
                                                                }}>Reset Password</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default EmailPassword