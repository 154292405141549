import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const UploadCloud = require("../../../images/assets/upload_cloud.png");
const Close = require("../../../images/assets/close.png");
const ExcelFileIC = require("../../../images/assets/excel-ic.png");
const CSV = require("../../../images/assets/csv-file.png");
const ExcelUploadArea = ({ id, onFilesSelected, onFileRemoved, onFileUpload, errors }) => {
    const [localFiles, setLocalFiles] = useState([]);
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef(null);


    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const files = Array.from(event.dataTransfer.files);
        handleFileSelection(files);
    };

    const handleFileInputChange = (event) => {
        const files = Array.from(event.target.files);
        handleFileSelection(files);
    };

    const handleFileSelection = (files) => {
        setLocalFiles(files);
        if (onFilesSelected) {
            onFilesSelected(files);
        }
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = [...localFiles];
        updatedFiles.splice(index, 1);
        setLocalFiles(updatedFiles);
        if (onFileRemoved) {
            onFileRemoved(updatedFiles);
        }
    };

    const handleChooseFilesClick = () => {
        fileInputRef.current.click();
    };

    const checkFileType = (fileName) => {
        const allowedExtensions = ["xlsx", "csv"];

        // Get the file extension
        const fileExtension = fileName.split(".").pop().toLowerCase();

        // Check if the extension is valid
        return fileExtension;
    };

    return (
        <div className="col-12 mt-2">
            <div className="label-label-upload ps-1">
                {localFiles.length === 0 ? `Select File` : `Selected File`}</div>

            {localFiles.length === 0 && <div
                className={`drag-root-area ${dragging ? "drag-over" : ""}`}
                style={{ height: '180px' }}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div className="file-area" >
                    {localFiles.length > 0 && (
                        <div className="file-list ">
                            {localFiles.map((file, idx) => (
                                <div className="d-flex flex-row file-item-upload" key={`${idx}-${uuidv4()}`}>
                                    <p className="m-0 p-0">{file.name}</p>
                                    <img
                                        src={Close} // Replace with your close icon
                                        className="close-file-ic ms-2"
                                        onClick={() => handleRemoveFile(idx)}
                                        alt="Remove"
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="custom-file-input" onClick={handleChooseFilesClick}>
                        <input

                            accept=".xlsx, .xls, .csv"
                            id={`file-${uuidv4()}`}
                            type="file"
                            className="m-0 p-0"
                            ref={fileInputRef}
                            onChange={handleFileInputChange}
                            style={{ display: "none", }}
                        />
                        {localFiles.length == 0 &&
                            <div className="pb-3">
                                <img src={UploadCloud} alt="upload-cloud" className="upload-cloud-ic" />
                            </div>}

                        <div className="d-flex flex-row justify-content-center position-sticky pt-2">
                            <p
                                className="blue-light-text btn-upload-statements p-0 m-0"

                            >
                                Choose files
                            </p>
                            <p className="btn-upload-statements ps-2 p-0 m-0" style={{

                            }}>or drag it here</p>

                        </div>
                        <p className="p-0 m-0 fileformat-line pt-1">(Supported formats .xlsx and .xls)</p>
                    </div>
                </div>
            </div>}


            {localFiles.length !== 0 &&
                (<div className="file-row-parent">
                    {localFiles.map((file, idx) => (
                        <div className="file-row mt-2" key={`${idx}-${uuidv4()}`}>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img alt="xlsx"
                                        className="excel-icon"
                                        src={checkFileType(localFiles[idx].name) === '.csv' ? CSV : ExcelFileIC} />
                                    <p className="p-0 m-0 excel-file-name ps-2">{localFiles[idx].name}</p>

                                </div>
                                <div>
                                    <img
                                        src={Close} // Replace with your close icon
                                        className="close-file-ic ms-2"
                                        onClick={() => handleRemoveFile(idx)}
                                        alt="Remove"
                                    />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                )
            }



            {localFiles.length !== 0 && errors && errors.selectedFiles && (
                <div className="pt-2">
                    <p className="demo-tetx m-0 p-0 " style={{
                        fontSize: '12px'
                    }}>{errors.selectedFiles}</p>
                </div>
            )}

            <div className='d-flex flex-row justify-content-center p-0 m-0 mt-4 mb-2'>
                <button
                    className="upload-statement-button"
                    type="button"
                    onClick={() => localFiles.length == 0 ? null : onFileUpload(localFiles)}
                    style={{ opacity: localFiles.length == 0 ? 0.5 : 1.0 }}

                >Upload Document
                </button>
            </div>

        </div>
    );
};

export default ExcelUploadArea;
