import React from 'react'
import '../../../App.css';
import Header from '../../landing-page/header/header';
import Footer from '../../landing-page/Footer/Footer';
const Logo = require("../../../images/assets/Logo.png");

const Privacypolicy = () => {
    return (
        <>
            <Header></Header>
            <div className="container">

                <h2 className="mt-5 mb-4">Privacy Policy</h2>

                <p>FinBoost Software Private Limited (hereinafter “Boost.Money”) provides cloud software and applications along with relevant API and SDK as a product for businesses and individuals including associated offline and mobile applications ("Service" or "Services"). We work with various businesses and Individuals (“Customer or Customers”) to provide Services. Our Customers trust in our platform and capabilities is of utmost importance to us and we go above and beyond to ensure that the rights of our customers are protected at all times.</p>
                 
                <p>We are committed to maintaining the confidentiality, integrity and security of any personal information about our Customers. We take our Customers privacy seriously, our privacy guidelines underlines our commitment to withholding our Customers privacy at all times.</p>

                <p>Customer Information : Any data or information shared by our customers is solely for the reason for providing product services to them. We enter specific agreements with our customers to detail out the services provided as well as handling and management of Customer Information. Any Customer information is always managed with industry level encryption best practices and data residency requirements are always managed based on our Customer’s preferences.</p>

                <p>Information Use: The usage of information and data to provide relevant services is always outlined during onboarding solely as a means to provide Services. The utilization and disclosure of information is solely based on our Customer’s preferences as and when they consume our analytics reports and product services output in a secure manner from our platform. Platform level configurations and APIs are available for our Customers to consume services based on their preferences.</p>
           
                <p className="mb-5">Disclosure: We do not disclose any information to any third party except if required to comply with the local law and/or regulator of a particular country.</p>
           </div>
            <Footer></Footer>
        </>
    )
}

export default Privacypolicy