import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuBar from '../Menubar/menubar';
import Sidebar from '../SideBar/sidebar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import SvgCopyIcon from '../../Component/svg-copy-icon';
import Footer from '../../landing-page/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './integration.css'

const copyIcon = require("../../../images/assets/copy-1.png");
const apiLogo = require("../../../images/assets/apiIcon.png");
const sdkLogo = require("../../../images/assets/sdkIcon.png");
const backArrow = require("../../../images/assets/backArrow.png");

function Integration() {
    var urlRedirectContent = 'https://<base_url>/sdk/onboard?token=<access_token>&firstName=<firstName>\n&lastName=<lastName>&mobileNumber=<mobileNumber>\n&businessUserId=<businessUserId>';
    var callbackApiContent = '{\n "businessUserId": "<businessUserId>\n "status": "<status>\n "statusCode": "<statusCode>\n "statusMessage": "<statusMessage>\n "creditDataPull": "<creditDataPull>\n "accountAggregatorStatus": "<accountAggregatorStatus>\n "uploadStatementStatus": "<uploadStatementStatus>\n "referenceId": "<referenceId>\n}';

    var callbackAPIContentNew = "{\n" +
        "  \"businessUserId\": \"<businessUserId>\",\n" +
        "  \"referenceId\": \"<referenceId>\",\n" +
        "  \"statuses\": [\n" +
        "    {\n" +
        "      \"serviceType\": \"CREDIT_DATA_PULL\",\n" +
        "      \"status\": \"ERROR\"\n" +
        "    },\n" +
        "    {\n" +
        "      \"serviceType\": \"ACCOUNT_AGGREGATOR\",\n" +
        "      \"status\": \"SUCCESS\"\n" +
        "    },\n" +
        "    {\n" +
        "      \"serviceType\": \"UPLOAD_STATEMENT\",\n" +
        "      \"status\": \"SUCCESS\"\n" +
        "    }\n" +
        "  ]\n" +
        "}" +
        "";

    var jsPromptMsg = 'const win = window;\n' +
        '\\\\Register event listener to receive postMessage event data.\n' +
        'if (win.addEventListener) {\n' +
        '   win.addEventListener("message", onMessage, true);\n' +
        '} else if (win.attachEvent) {\n' +
        '   win.attachEvent("onmessage", onMessage, true);\n' +
        '}\n' +
        '\\\\You can write your own callback method to receive event data.\n' +
        'function onMessage(event) {\n' +
        '   event.stopImmediatePropagation();\n' +
        ' \\\\Accept event only from the Boost.Money base url\n' +
        ' if (event.origin !== "<base_url>") return;\n' +
        ' \\\\Write custom function to handle event.\n' +
        '  processEvent(event.data);\n' +
        '}';

    const navigate = useNavigate();
    const { state } = useLocation();

    const { user } = useSelector(state => {
        return state.authReducer;
    });


    const scrollToWebSdk = () => {
        const websdkSection = document.getElementById('websdk');
        if (websdkSection) {
            websdkSection.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'center'
            });

        }
    };
    useEffect(() => {
        const prefix = '#websdk';

        if (window.location.hash === prefix) {
            scrollToWebSdk();
        }
        if (!user?.access_token) {
            navigate('/');
        }
    }, []);

    const copied = () => {
        toast("Copied!", {
            toastId: 100, progressStyle: {
                background: '#4243F1', // Change this to the desired color
            }, icon: SvgCopyIcon,
            position: toast.POSITION.BOTTOM_RIGHT, className: 'toast-message'
        });
    }

    return (
        <>
            <MenuBar ></MenuBar>
            <div className='sidebar-box sidnav-box' >
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box ">
                    <div className='content'>
                        <div className='mt-4 mt-sm-2'>
                            <section className='' id='creditscore'>
                                <div className='container-lg container-fluid mt-3 my-sm-3'>
                                    <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                                        <div className='col-5'>
                                            <div className='ps-sm-2'>
                                                <h1 className='username-text mb-2 mb-sm-3'>Documentation</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div >

                        <section>
                            <div className="container-lg container-fluid">
                                <div className="row d-flex p-0 m-0">
                                    <div className='col-lg-6 col-md-6 col-sm-12 py-2 p-0 m-0'>
                                        <div className="bank-state-box">
                                            <h4 className="guide-title mb-4">
                                                <img src={apiLogo} /> <span className="ps-2">API{" "}</span>
                                            </h4>
                                            <p className="guidline-txt pb-5">
                                                Automated onboarding with easy documentation helps quick API
                                                integration. Integrated Credit Bureau and AA integration
                                                facilitates collective API simplifying integration that directly
                                                plugs into FI’s BRE.
                                            </p>

                                            <div className="guide-link text-end">
                                                <h4>
                                                    <a href="/integration#loginapi">API Guide</a>{" "}
                                                    <img src={backArrow} />
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 py-2 m-0 ps-lg-3 ps-md-3 ps-sm-3 ps-0'>
                                        <div className="bank-state-box ">
                                            <h4 className="guide-title mb-4">
                                                <img src={sdkLogo} /> <span className="ps-2">SDK</span>
                                            </h4>
                                            <p className="guidline-txt pb-5">
                                                Mobile and responsive Web SDK available for direct plug and play
                                                for quick time to market. Optimized UX to help easy onboarding
                                                of end consumers with automated consent management as prescribed
                                                by the regulator.
                                            </p>
                                            <div className="guide-link text-end">
                                                <h4>
                                                    <a href="/integration#websdk">SDK Guide</a>{" "}
                                                    <img src={backArrow} />
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <section id='loginapi'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text" >LOGIN API</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Integrate Login API
                                                                </h1>
                                                                <p className='doc-desc mt-md-2'>
                                                                    To integrate with the Web SDK, it requires generating a JWT Access Token which will authenticate the client. This makes sure that only legit clients are accessing the platform.
                                                                </p>

                                                                <p className='doc-desc mt-md-2'>
                                                                    You can access APIs Postman collection <a href="https://documenter.getpostman.com/view/30588478/2s9YR9XCCz#4f3588f0-320e-4b6e-926c-9cbbea9b76560" target='_blank'>here</a>
                                                                </p>

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Required Query String Parameters in URL:
                                                                </h1>

                                                                {/* <div className="accordion-body"> */}
                                                                <div className='row g-3'>
                                                                    <div className='col-lg-12 mt-2 p-0'>
                                                                        <div className='account-details-box p-0 m-0 py-2 ps-2 pe-3'>
                                                                            <div className='row d-flex justify-content-between pt-1 pb-2'>
                                                                                <div className='col-6'>
                                                                                    <div className='d-flex justify-content-start py-1'>
                                                                                        <p className='account-details-main-text'>Base URL</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6 m-0 p-0'>
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <p className='account-details-sub-text text-end py-1'>Provided separately</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row d-flex justify-content-between pt-1 pb-1'>
                                                                                <div className='col-6'>
                                                                                    <div className='d-flex justify-content-start'>
                                                                                        <p className='account-details-main-text py-1'>API Endpoint</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6 m-0 p-0'>
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <p className='account-details-sub-text text-end py-1'>https://Base_URL/api/v1/b2b/login</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row d-flex justify-content-between pt-2 pb-1'>
                                                                                <div className='col-6'>
                                                                                    <div className='d-flex justify-content-start'>
                                                                                        <p className='account-details-main-text text-end py-1'>Method</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6 m-0 p-0'>
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <p className='account-details-sub-text text-end py-1'>POST</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {/* </div> */}

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Request Body:
                                                                </h1>

                                                                <div className="mockup-code mt-4">
                                                                    <pre className='my-0 py-0'><code className="account-details-sub-text">{'{\n "clientId": "<provided separately>",\n "clientSecret":"<provided separately>"\n}'}</code></pre>
                                                                </div>

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Response Body:
                                                                </h1>

                                                                <div className='mockup-code mt-4'>
                                                                    <pre className='my-0 py-0'><code className="account-details-sub-text">{'{\n "access_token": "",\n "expires_in": 300,\n "refresh_expires_in": 0,\n "refresh_token": null,\n "token_type": "Bearer" \n}'} </code></pre>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='websdk'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text" >WEB SDK REDIRECTION</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Integrate Web SDK Redirection Flow
                                                                </h1>
                                                                <p className='doc-desc mt-md-2'>
                                                                    Web SDK Redirection flow requires users to redirect on a URL with necessary query string parameters. We would recommend to open a child window as a redirection flow or a iframe integration if required to show the sdk interface within the integration website.
                                                                </p>

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Following is the valid redirection URL client has to prepare before redirecting the user to the redirect url page.
                                                                </h1>

                                                                <div className='mockup-code mt-4 d-flex'>
                                                                    <pre className='col-11 my-0 py-0'><code>{urlRedirectContent}
                                                                    </code>
                                                                    </pre>
                                                                    <div className="col-1 d-flex justify-content-end">
                                                                        <CopyToClipboard
                                                                            onCopy={copied}
                                                                            text={urlRedirectContent}>
                                                                            <span className="copy-box">
                                                                                <img
                                                                                    src={copyIcon}
                                                                                    alt="copy"
                                                                                    className="copy-ic"
                                                                                />
                                                                            </span>
                                                                        </CopyToClipboard>
                                                                    </div>
                                                                </div>


                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Required Query String Parameters in URL:
                                                                </h1>

                                                                {/* <div className="accordion-body"> */}
                                                                <div className='row g-3'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='integration-details-box'>
                                                                            <div className='row d-flex header-tbl'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-3'>Parameter Name</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-3'>Description</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>token<label className="red_mand">*</label></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Generate Access token using Login API</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>redirectUrl</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        {/* <p className='account-details-sub-text text-end'>Send callback postMessage to redirectUrl to get request status as SUCCESS or ERROR</p> */}
                                                                                        <p className='account-details-sub-text text-end py-2'>Redirect Url to get request status as SUCCESS or ERROR or CANCEL or UNAUTHORIZED with bussinessUserId</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>businessUserId<label className="red_mand">*</label></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Unique end user id availabel in the business system</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>mobileNumber<label className="red_mand">*</label></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Mobile number of the end user</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>firstName<label className="red_mand">*</label></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>First Name of the end user</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>lastName<label className="red_mand">*</label></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Last Name of the end user</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>

                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>email</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Email address of the end user</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className='dividers'></div> */}

                                                                            {/* <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>startFlow</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2 '>Flow will start from account aggregator(AA) OR credit pull (CREDIT)</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                        </div>
                                                                    </div>
                                                                    <h6>Notes : <label className="red_mand">*</label> is mandatory query string parameters in the url</h6>
                                                                </div>
                                                                {/* </div> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='callbackapi'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text" >CALLBACK API</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Set Callback URL
                                                                </h1>
                                                                <p className='doc-desc mt-md-2'>
                                                                    We send final data to the callback url provided during user redirection flow. Here status will be SUCCESS or ERROR or PARTIAL_SUCCESS or DATA_NOT_FOUND
                                                                </p>

                                                                <h1 className='text-start doc-title'>
                                                                    Following Callback API status :
                                                                </h1>

                                                                <div className='row g-3'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='integration-details-box'>
                                                                            <div className='row d-flex header-tbl'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-3'>Status Name</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-3'>Description</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>SUCCESS</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Operation completed successfully.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>PARTIAL_SUCCESS</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>The statement upload failed or the transaction fetch (via the account aggregator).</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>ERROR</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Operation failed due to an error.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dividers'></div>
                                                                            <div className='row'>
                                                                                <div className='col-6'>
                                                                                    <div className='text-start'>
                                                                                        <p className='account-details-main-text py-2'>DATA_NOT_FOUND</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className='text-end'>
                                                                                        <p className='account-details-sub-text text-end py-2'>Credit history not available.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Following data will be sent to callback API:
                                                                </h1>

                                                                <div className='mockup-code d-flex mt-4'>
                                                                    <pre className='col-11 my-0 py-0'><code>{callbackAPIContentNew} </code></pre>
                                                                    <div className="col-1 d-flex justify-content-end">
                                                                        <CopyToClipboard
                                                                            onCopy={copied}
                                                                            text={callbackAPIContentNew}>
                                                                            <span className="copy-box">
                                                                                <img
                                                                                    src={copyIcon}
                                                                                    alt="copy"
                                                                                    className="copy-ic"
                                                                                />
                                                                            </span>
                                                                        </CopyToClipboard>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <section id='javasciptpost'>
                            <div className='container-lg container-fluid my-3'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text" >JAVASCRIPT POSTMESSAGE</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <h1 className='text-start doc-title pb-3'>
                                                                    Integrate JavaScript postMessage
                                                                </h1>
                                                                <p className='doc-desc mt-md-2'>
                                                                    JavaScript PostMessage integration required to receive request status SUCCESS or ERROR. Based on this status integration partner can decide to show the proper screen to the user at their end or take relevant action.
                                                                </p>

                                                                <h1 className='text-start doc-title pt-3 dpb-3'>
                                                                    Below is the sample JavaScript code to register the event listener.
                                                                </h1>

                                                                <div className='mockup-code d-flex mt-4'>
                                                                    <pre className='col-11 my-0 py-0'><code>{jsPromptMsg} </code></pre>
                                                                    <div className="col-1 d-flex justify-content-end">
                                                                        <CopyToClipboard
                                                                            onCopy={copied}
                                                                            text={jsPromptMsg}>
                                                                            <span className="copy-box">
                                                                                <img
                                                                                    src={copyIcon}
                                                                                    alt="copy"
                                                                                    className="copy-ic"
                                                                                />
                                                                            </span>
                                                                        </CopyToClipboard>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                    </div>
                    <Footer />
                </div>

            </div >


        </>
    );
}

export default Integration;
