import '../../App.css';
import './creddharma.css'
import { useEffect, useState } from 'react';
import { credSDKApiCall } from '../../redux/actions/auth_action';
import Footer from '../landing-page/Footer/Footer';
import MiddleContentCD from '../landing-page/Component/middle-cd';
import Loader from '../loader/loader';
import ErrorMessage from '../Dashboard/error-message/error-message';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css'
import { useLocation } from 'react-router-dom';

const Logo = require("../../images/assets/cred-assets/cd_logo.webp");
const BMLOGO = require("../../images/assets/cred-assets/cred_logo.webp");

function CredDharma(props) {
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState();
    const [openSDKIframe, setOpenSDKIframe] = useState(false);
    const [b2bLink, setB2BLink] = useState('')
    const [callSDKB2B, setCallSDKB2B] = useState(false);
    const dialogHeight = "auto";

    let sdkMessage = '';
    let setLinkCode = '7089';  // Default linkcode for UAT
    // Function to parse query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const onlyCredit = queryParams.get('onlyCredit');
    const onlyAA = queryParams.get('onlyAA');

    useEffect(() => {
        window.addEventListener('message', fetchIframeData, false);
        function fetchIframeData(e) {
            try {
                e.preventDefault()
                if (typeof e.data !== "object") return
                if (!e.data.status) return
                if (!e.data.responseFrom) return
                if (!e.data.referenceId) return
                let iframeData = e.data && e.data.status ? e.data : null;
                if (iframeData) {
                    const status = iframeData.status;
                    const responseFrom = iframeData.responseFrom;
                    const referenceId = iframeData.referenceId;

                    if (responseFrom === "FIUTSP") {
                        var iframe = document.getElementById('tspiframe');
                        iframe.contentWindow.postMessage(e.data, '*');
                        return;
                    }

                    if (responseFrom === "B2BSDK") {
                        setTimeout(function () {
                            window.location.reload(1);
                        }, 5000);
                        if (status === "SUCCESS") {
                            sdkMessage = { success: 'Your request processed successfully.' }
                        } else if (status === "ERROR") {
                            sdkMessage = { error: 'We have encountered an error. Please try again. Reference Id :' + ' ' + referenceId }
                        } else if (status === "CANCEL") {
                            sdkMessage = { error: 'User cancelled. Reference Id :' + ' ' + referenceId }
                        } else if (status === "UNAUTHORIZED") {
                            sdkMessage = { error: 'Your token is expired. Please re initiate the request again. Reference Id :' + ' ' + referenceId }
                        } else {
                            sdkMessage = { error: 'Something went wrong!' }
                        }
                        setMessage(sdkMessage)
                        setOpenSDKIframe(false)
                        return;
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }


    }, [])

    const clickOnMiddleBtn = async () => {
        setOpenSDKIframe(true)
        await cdSdkCallApi();
        setCallSDKB2B(true)
    };

    const cdSdkCallApi = async () => {
        setLoader(true);
        setShowError(false)

        const url = new URL(window.location.href)
        const pathname = url.pathname;

        if (pathname === "/creditdharma") {
            setLinkCode = 'e3519811'
        }

        var jsonResponse = {
            statusCode: '',
            statusMessage: '',
            sdkUrl: ''
        };
        var param = {
            linkCode: setLinkCode
        }

        credSDKApiCall(param, pathname, false).then(resp => {
            setLoader(false);
            setShowError(false)
            if (resp.statusCode === "SUCCESS") {
                jsonResponse = {
                    statusCode: resp['statusCode'],
                    statusMessage: resp['statusMessage'],
                    sdkUrl: resp['sdkUrl']
                };
                const isIframe = '&isIframe=true';
                var sdkUrl = jsonResponse.sdkUrl + isIframe;
                if (onlyCredit != null && (onlyCredit === true || onlyCredit === 'true')
                    && onlyAA != null && (onlyAA === true || onlyAA === 'true')) {
                    setB2BLink(sdkUrl)
                    return;
                }
                if (onlyCredit != null && (onlyCredit === true || onlyCredit === 'true')) {
                    sdkUrl = sdkUrl + '&onlyCredit=true'
                }
                if (onlyAA != null && (onlyAA === true || onlyAA === 'true')) {
                    sdkUrl = sdkUrl + '&onlyAA=true'
                }
                //console.log(sdkUrl)
                 setB2BLink(sdkUrl)
            }
        }).catch(error => {
            setShowError(true)
            setLoader(false);
        })
    }

    const FipTspModel = (props) => {
        return <div className='tsp-iframe-popup text-center'>
            <iframe
                title="bmsdk"
                allowFullScreen
                height="650px"
                src={props.src}
                width="100%"
                id="tspiframe"
                allow='popup'
                sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
            />
        </div>
    }

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container-fluid mt-2'>
                    <div className='row g-3 mb-0 align-items-center'>
                        <div className='d-flex flex-inline justify-content-center justify-content-lg-start justify-content-md-start'>
                            <div className='d-flex flex-row align-items-center'>
                                    <img src={BMLOGO} className='img-fluid cred-mini-logo-small-hd' />
                                    <img src={Logo} className='header-logo ms-2 pb-1' />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="middle-content">
                <div className="profile-main pt-5">

                    <img src={BMLOGO} className='img-fluid cred-mini-logo' />

                    <p className="pro-sub-line pt-3">Credit Score Checker</p>
                </div>
                <ErrorMessage message={message} />
                <MiddleContentCD onChildClick={clickOnMiddleBtn} BtnLabel={"Start Now"} />

                {openSDKIframe &&
                    <div className='open-b2bsdk'>
                        <ReactDialogBox
                            headerBackgroundColor='white'
                            headerTextColor='white'
                            closeButtonColor='white'
                            bodyBackgroundColor='white'
                            bodyTextColor='white'
                            bodyHeight={dialogHeight}
                            borderRadius='0px'
                            headerHeight='0'
                        >
                            {callSDKB2B ?
                                <FipTspModel src={b2bLink} />
                                : null
                            }
                        </ReactDialogBox>
                    </div>}

                {showError && <div id='result' className="mt-4 mb-3">
                    <div className="alerts danger" role="alert">
                        Something went wrong, please try again!
                        <span className="close" onClick={() => setShowError(false)}>×</span>
                    </div>
                </div>}

            </section >

            {/* <div className='footer-box bottom-0 position-fixed'>
                <Footer />
            </div> */}
        </>
    );
}

export default CredDharma; 