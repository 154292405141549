import './landing-page.css';
import '../../App.css'
// import Slider from "./Slider/Slider";
import Header from "./header/header";
// import Testimonial from "./Testimonial1/Testimonial";
// import Invitation from "./Invitation/Invitation";
import Footer from './Footer/Footer';

function LandingPage() {
    return (
        <>
            <Header />
            {/* <Slider /> */}
            {/* <Testimonial /> */}
            {/* <Invitation /> */}
            <Footer />
        </>
    );
}

export default LandingPage;