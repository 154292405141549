
const getSDKError = () => {
    var url = new URL(window.location.href);
    var redirectUrlData = url.searchParams;
    var message = ''

    if(redirectUrlData){
        var status = redirectUrlData.get('status');
        var referenceId = redirectUrlData.get('referenceId');

        if(status){
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        if(status === "SUCCESS"){
            message = {success: 'Your request processed successfully.'}
        }else if(status === "ERROR"){
            message = {error: 'We have encountered an error. Please try again. Reference Id :'+' '+ referenceId}  
        }else if(status === "CANCEL"){
            message = {error: 'User cancelled. Reference Id :'+' '+ referenceId}   
        }else if(status === "UNAUTHORIZED"){
            message = {error: 'Your token is expired. Please re initiate the request again. Reference Id :'+' '+ referenceId}   
        }
    }

    return message;
}

export default getSDKError;