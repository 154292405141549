import { store } from "../redux/store";
import jwt_decode from "jwt-decode";

const getUserData = () => {
    const state = store.getState();
    var token = state.authReducer.user?.access_token;
    var decoded = jwt_decode(token);
    return decoded;
}

export default getUserData;