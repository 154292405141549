import React, { useState, useEffect } from 'react';
import "./pricing.css";
import ReactGA from 'react-ga4';
import MenuBar from "../Menubar/menubar";
import Sidebar from "../SideBar/sidebar";
import Loader from '../../loader/loader';
import PricingCards from './pricing-card';
import Footer from '../../landing-page/Footer/Footer';

const smLogo = require("../../../images/assets/smlogo.png");
const Logo = require("../../../images/assets/MobileViewLogo.png");


function DsaPricing(props) {
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: "Component/Page_Render",
            action: "dsa_pricing_page_render_prod",
            label: `dsa_pricing_page_render_prod`,
        });

    }, [])


    const MenuBar = () => {

        return (
            <nav className="navbar fixed-top navbar-expand-lg navbar-light" aria-label="Light offcanvas navbar">
                <div className="container-fluid">
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='d-flex align-items-center'>
                            {/* <button className="navbar-toggler mx-2 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                <span className="navbar-toggler-icon"></span>
                            </button> */}
                            <div className='ms-0 '>
                                <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
                                    <img src={Logo} className='ms-2 head-img-logo' />
                                    <img src={smLogo} className='img-fluid sm-logo' width="23" />
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </nav>
        )
    }

    return (
        <>
            <MenuBar/>
            <div className="sidebar-box sidnav-box">
                {/* <Sidebar></Sidebar> */}
                <div className="sidebat-inner-box sidnav-inner-box">
                    {loader && <Loader />}

                    <div id="pricing" className='mt-4 mt-sm-2'>
                        <section className='' id='uatenv'>
                            <div className='container-lg container-fluid mt-3 my-sm-3'>
                                <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                                    <div className='col-5'>
                                        <div className='ps-sm-2'>
                                            <h1 className='username-text mb-2 mb-sm-3'>DSA Boost Pricing</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div >

                    <section id='pricingdata'>
                        <div className='mb-4 px-1'>
                            <div className='container-lg container-fluid my-md-4'>
                                <div className='bank-state-box m-0 py-3'>
                                    <div className='row p-0 m-0'>
                                        <div className='co-12 text-center p-0 m-0'>
                                            <h1 className='price-head-text text-uppercase p-0 m-0
                                            pt-3'>BUY CREDITS to seamlessly use our products</h1>

                                        </div>
                                    </div>
                                    <div className=' p-0 m-0 pt-3'>
                                        <PricingCards></PricingCards>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
}
export default DsaPricing;
