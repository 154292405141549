import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './credit-tab-component.css';
import { flushSync } from "react-dom";
import ReactGA from "react-ga4";
import Loader from '../../loader/loader';
import { creditReport, } from '../../../redux/actions/dashboard_action';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getUserData from '../../../Common/helper';
import Footer from '../../landing-page/Footer/Footer';
import BankStatment from '../Bank/bankstatement';
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactDialogBox } from 'react-js-dialog-box';
import { authCode, emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import Insights from './components/insihght/insights-dilog';
import ScoreDetails from './components/score-details/score-range-dailog';
import ErrorMessage from '../error-message/error-message';
import { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { dispatchAction } from '../../../redux/actions/common_action';
import { SELECTED_BUREAU } from '../../../redux/constants';
import Select, { components } from 'react-select';
import { customStyles } from './components/custom-style-component-bureau'

import { initOnboarUserArrayApi, loginAction, otpVerification, submitUserArrayTokenApi } from '../../../redux/actions/auth_action';

import { v4 as uuidv4 } from 'uuid';
import { creditReportDataBID } from '../../../redux/actions/b2b_action';
import { creditReportDataProdBID } from '../../../redux/actions/prod-b2b_action';

const AxisBank = require("../../../images/Bank/Bank.png");
const Score = require("../../../images/assets/Chart.png");
const PaymentsIcon = require("../../../images/assets/Time Machine.png");
const LimitIcon = require("../../../images/assets/Money Box.png");
const AgeIcon = require("../../../images/assets/Schedule.png");
const AccountIcon = require("../../../images/assets/Merchant Account.png");
const EnquiriesIcon = require("../../../images/assets/Query.png");
const ICArrowUp = require("../../../images/assets/ic-arrow-up.png");
const ExperianLogo = require("../../../images/Bank/experian.png");
const CibillLogo = require("../../../images/Bank/cibil.png");
const CrifLogo = require("../../../images/Bank/crif.png");
const EquifaxLogo = require("../../../images/Bank/equifax.png");
const InfoIC = require("../../../images/assets/info-ic.png");
const NoFoundDocs = require("../../../images/assets/no_saving_thumb.png");
const CreditTabComponent = (props) => {

    const [loader, setLoader] = useState(false);
    const [creditAnalysisResponse, setCreditAnalysisResponse] = useState(null)
    const [creditReportData, setCreditReportData] = useState();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDialogS, setIsOpenDialogS] = useState(false);
    const [dialogHeight, setDialogHeight] = useState('auto')
    const [selectedBureau, setSelectedBureau] = useState('');
    const [bureauList, setBureauList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [analysisReportData, setAnalysisReportData] = useState();
    const [insightsDialog, setInsightsDialog] = useState(false);
    const [selectedAnalysisName, setSelectedAnalysisName] = useState();
    const [emailErrorMessage, setEmailErrorMessage] = useState();
    const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
    const [message, setMessage] = useState();
    const [isViewAll, setIsViewAll] = useState(true);
    const [insightsItem, setInsightsItem] = useState([])
    const { state } = useLocation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [getRefreshTokenStatus, setRefreshTokenData] = useState();
    const [isCollapsedCreditInfo, setIsCreditInfoCollapse] = useState(true);
    const [isCollapsedPersonalInfo, setIsPersonalCollapse] = useState(false);
    const [collapsedBankItems, setCollapsedBankItems] = useState(Array(0).fill(true));
    const [checkInitStageObj, setCheckInitStageObj] = useState({
        "statusCode": "",
        "statusMessage": "",
        "stage1": false,
        "stage2": false
    })

    const [creditBureauData, setCreditBureauData] = useState({ index: 0, currentBureau: '' })

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => setModalOpen(false);


    const { user, bureau } = useSelector(state => {
        return state.authReducer;
    });

    // console.log("CdData ", CDDATA.data);
    useEffect(() => {

        let param = {
            "businessUserId": props.businessUserId
        }
        getCreditScoreApi(param)
        // getScrubDataDetail(param).then((resp) => {
        //     console.log(resp);
        //     setScrubDetail(resp);
        //     setLoader(false)
        // }).catch((error) => {
        //     setLoader(false)
        //     // console.log("error ", error)
        // })
    }, []);

    const getCreditScoreApi = (param) => {

        setLoader(true)
        const data = getUserData()
        if (bureau) {
            setSelectedBureau(bureau);
            getCreditReport(bureau, param);
            //getRefreshTokenData();
        } else {
            getCreditReport('', param);
            //getRefreshTokenData();
        }
        setUserData(data)
        if (state?.success) {
            setMessage({ success: state?.success.statusMessage });
        } else if (state?.error) {
            setMessage({ error: state?.error.statusMessage });
        }
    }

    const getCreditReport = (bureau = '', param) => {
        setLoader(true);
        setCreditReportData(null);
        insightsAnalysisReports(null);
        const { openFromProd, demoClientToken } = props; // Destructure props for clarity
        const token = demoClientToken || null; // Simplify null assignment

        const fetchFunction = openFromProd
            ? creditReportDataProdBID(bureau)
            : creditReportDataBID(bureau);

        fetchCreditReportData(fetchFunction, param, token, bureau);
    };

    const fetchCreditReportData = async (fetchFunction, param, demoClientToken, bureau) => {
        const { openFromProd } = props;
        try {
            const resp = await fetchFunction(param, demoClientToken);
            flushSync(() => {

                ReactGA.event({
                    category: "API_CALL",
                    action: openFromProd ? "api_call_credit_prod" : "api_call_credit_sandbox",
                    label: openFromProd ? "api_call_credit_prod_report_success" : "api_call_credit_sandbox_report_success",
                });
                setCreditAnalysisResponse(resp)
                if (resp.data !== null && resp.data.length > 0) {
                    setCreditBureauData({ index: 0, currentBureau: resp.data[0].bureauName });
                    setCreditReportData(resp.data[creditBureauData.index].creditReport);
                    insightsAnalysisReports(resp.data[creditBureauData.index].analysis);
                    bureauDataGenerate(resp.data ?? []);
                }
            });


            //bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? []);
        } catch (error) {
            ReactGA.event({
                category: "API_CALL",
                action: openFromProd ? "api_call_credit_prod" : "api_call_credit_sandbox",
                label: openFromProd ? "api_call_credit_prod_report_fail" : "api_call_credit_sandbox_report_fail",
            });
            console.error('Error fetching credit report data:', error);
        } finally {
            setLoader(false); // Ensure loader is reset in both success and failure cases
        }


    };

    // const getCreditReport = (bureau = '', param) => {
    //     setLoader(true)

    //     if (props.openFromProd) {
    //         fetchCreditReportData(creditReportDataProdBID(bureau), param, null);
    //     } else {
    //         fetchCreditReportData(creditReportDataBID(bureau), param,
    //             props.demoClientToken !== null ?
    //                 props.demoClientToken : null);
    //     }

    // }
    // const fetchCreditReportData = (fetchFunction, param, demoClientToken) => {
    //     fetchFunction(param, demoClientToken)
    //         .then((resp) => {
    //             setCreditReportData(resp.data);
    //             insightsAnalysisReports(resp.analysis);
    //             bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? []);
    //             setLoader(false);
    //         })
    //         .catch((error) => {
    //             setLoader(false);
    //         });
    // };

    const openInsights = (name) => {
        setSelectedAnalysisName(name);
        setInsightsDialog(true)
        setDialogHeight('auto')
    }

    const openScoreDetails = (name) => {
        setScoreDitailsDialog(true)
        setDialogHeight('auto')
    }

    const openDialog = () => {
        setIsOpenDialogS(true)
    }

    const openDialogs = () => {
        setIsOpenDialogS(true)
    }

    const closeinsght = () => {
        setInsightsDialog(false)
        setDialogHeight('auto')
    }

    const closeScoreDetails = () => {
        setScoreDitailsDialog(false)
        setDialogHeight('auto')
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogHeight('auto')
    }

    const closeDialogS = () => {
        setIsOpenDialogS(false)
        setDialogHeight('auto')
    }
    const toggleBankCollapse = (index) => {
        const newCollapsedItems = [...collapsedBankItems];
        newCollapsedItems[index] = !newCollapsedItems[index];
        setCollapsedBankItems(newCollapsedItems);
    };
    const toggleCollapseCreditInfo = () => {
        setIsCreditInfoCollapse(!isCollapsedCreditInfo);
    };
    const toggleCollapsedPersonalInfo = () => {
        setIsPersonalCollapse(!isCollapsedPersonalInfo);
    };
    const bureauDataGenerate = (bureauData) => {

        setSelectedBureau(bureauData[creditBureauData.index]);
        // var bureauListData = bureauList;

        // if (!bureauListData.includes(bureauName)) {
        //     bureauListData.push(bureauName)
        // }
        let bureauListData = [];
        bureauData.forEach(element => {
            bureauListData.push(element.bureauName);
        });

        // console.log("bureauListData ", bureauListData);
        setBureauList(bureauListData);
    }

    const insightsAnalysisReports = (analysis) => {
        setAnalysisReportData(analysis)
        var i = [];
        if (analysis && analysis.paymentHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={PaymentsIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.label && analysis?.paymentHistory?.label != null) ? analysis.paymentHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.impactOnScore && analysis?.paymentHistory?.impactOnScore !== null) ? analysis.paymentHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('paymentHistory') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.information?.title && analysis?.paymentHistory?.information?.title !== null) ? analysis.paymentHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(analysis && analysis !== null) ? (analysis?.paymentHistory?.ratio && analysis?.paymentHistory?.ratio !== null) ? (analysis.paymentHistory.ratio + '%') : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.accounts) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AccountIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.accounts?.label && analysis?.accounts?.label !== null) ? analysis.accounts.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.accounts?.impactOnScore && analysis?.accounts?.impactOnScore !== null) ? analysis.accounts.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('accounts') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.accounts?.information?.title && analysis?.accounts?.information?.title !== null) ? analysis.accounts.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        {analysis?.accounts?.noOfActiveAccounts && <h5 className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.accounts?.noOfActiveAccounts && analysis?.accounts?.noOfActiveAccounts !== null) ? analysis.accounts.noOfActiveAccounts : '' : ''} Active</h5>}
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AgeIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.creditHistory?.label && analysis?.creditHistory?.label !== null) ? analysis.creditHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.creditHistory?.impactOnScore && analysis?.creditHistory?.impactOnScore !== null) ? analysis.creditHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditHistory') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.creditHistory?.information?.title && analysis?.creditHistory?.information?.title !== null) ? analysis.creditHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditHistory?.ageOfAccounts && analysis?.creditHistory?.ageOfAccounts !== null) ? analysis.creditHistory.ageOfAccounts : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditUtilization) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={LimitIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.label && analysis?.creditUtilization?.label !== null) ? analysis.creditUtilization.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.impactOnScore && analysis?.creditUtilization?.impactOnScore !== null) ? analysis.creditUtilization.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditUtilization') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.information?.title && analysis?.creditUtilization?.information?.title !== null) ? analysis.creditUtilization.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditUtilization?.ratio && analysis?.creditUtilization?.ratio !== null) ? (analysis.creditUtilization.ratio + '%') : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.enquiries) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={EnquiriesIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.enquiries?.label && analysis?.enquiries?.label !== null) ? analysis.enquiries.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.enquiries?.impactOnScore && analysis?.enquiries?.impactOnScore !== null) ? analysis.enquiries.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('enquiries') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.enquiries?.information?.title && analysis?.enquiries?.information?.title !== null) ? analysis.enquiries.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.enquiries?.totalNoOfEnquiry && analysis?.enquiries?.totalNoOfEnquiry !== null) ? analysis.enquiries.totalNoOfEnquiry : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        setInsightsItem(i)
    }
    const responsives = {
        // 0: {
        //     items: 2
        // },
        // 767: {
        //     items: 2
        // },
        // 1024: {
        //     items: 5,
        //     itemsFit: 'contain',
        //     disableButtonsControls: 'true',
        //     infinite: 'true'
        // },
        0: {
            items: 1.7
        },
        344: {
            items: 1.3
        },
        360: {
            items: 1.4
        },
        375: {
            items: 1.5
        },
        390: {
            items: 1.5
        },
        412: {
            items: 1.6
        },
        414: {
            items: 1.6
        },
        430: {
            items: 1.7
        },
        540: {
            items: 2.2,
        },
        576: {
            items: 3
        },
        768: {
            items: 3,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        820: {
            items: 2.0
        },
        1024: {
            items: 3,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        1200: {
            items: 5,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
    };

    const Control = ({ children, ...props }) => {
        const [inputValue, setInputValue] = useState('');
        const { selectProps, hasValue } = props;

        return (
            <components.Control {...props}>
                <div style={{ position: 'relative', display: 'flex', }}>
                    {hasValue || inputValue ? (
                        <label
                            style={{
                                position: 'absolute',
                                left: '6px',
                                top: '-24px',
                                backgroundColor: 'white',
                                padding: '0 4px',
                                fontSize: '80%',
                                fontWeight: '400',
                                fontFamily: 'Poppins',
                                color: '#000000',
                            }}
                        >
                            {selectProps.placeholder}
                        </label>
                    ) : null}
                    {children}
                </div>
            </components.Control>
        );
    };


    const accountDetails = (accountess, personalInformation) => {
        var accounts = [];
        for (let index = 0; index < accountess.length; index++) {
            accounts.push(
                <div className="accordion-item my-3" key={"accordionItem" + index}>
                    <div className="accordion-header accordion-button align-items-center collapsed" type="button"
                        onClick={() => toggleBankCollapse(index)}
                        // data-bs-toggle="collapse"
                        data-bs-target={"#collapseOne" + index}
                        aria-expanded={!collapsedBankItems[index]}
                        aria-controls={"collapseOne" + index}>
                        <div className='row w-100 align-items-center g-2 g-sm-0'>
                            <div className='col-12 p-0 m-0'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='bank-image-box'>
                                            <img src={AxisBank} width={100}></img>
                                        </div>
                                        <div className='text-start ms-3 p-0 m-0 pt-2'>
                                            <div className='row d-flex align-items-center p-0 m-0'>
                                                <div className='col-12 p-0 m-0'>
                                                    <h1 className='bank-name-text p-0 m-0'>{accountess[index].creditAccountInformation.lender ?? "N/A"}</h1>
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className='col-12 p-0 m-0'>
                                                    <p className='acc-type-text pt-2 m-0'>{accountess[index].creditAccountInformation.accountType + " | " +
                                                        `${accountess[index].creditAccountTerms.accountNumber ?? 'N/A'}` ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='pe-2'>
                                        <div className='d-flex flex-row justify-content-center align-items-center'>
                                            {(accountess[index].creditAccountDescription.accountStatus && accountess[index].creditAccountDescription.accountStatus != null && accountess[index].creditAccountDescription.accountStatus !== '') &&
                                                (<div className='pe-2'>
                                                    <div className={accountess[index].creditAccountDescription.accountStatus === 'CLOSED'
                                                        || accountess[index].creditAccountDescription.accountStatus == 'CLOSED' ? 'box-active-bank box-close-bank m-0' :
                                                        'box-active-bank m-0'}>
                                                        {accountess[index].creditAccountDescription.accountStatus ?? ''}</div>
                                                </div>)}
                                            <img src={ICArrowUp} className={`arrow_icon ${collapsedBankItems[index] ? 'rotate-180' : ''}`}></img>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className='col-12 col-sm-5'>
                                <span className='mb-1 progress-text'>50% Payment done</span>
                                <div className='progresssbars'>
                                    <ProgressBar completed={50} labelClassName="labels" barContainerClassName="barcompleted" />
                                </div>
                            </div> */}
                            {/* <div className='col-12 col-sm-3'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    {accountess[index].creditAccountDetails.creditLimitAmount !== '' && accountess[index].creditAccountDetails.creditLimitAmount !== '-' && <h1 className='text-start text-sm-end dolars-rs-text mb-0'>$  {accountess[index].creditAccountDetails.creditLimitAmount ?? ""}</h1>}
                                    <div className='credit-report-amount-box float-sm-end float-start'>
                                        <div className='good-stand-box'>Good Standing</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div id={"collapseOne" + index}
                        className={"accordion-collapse collapse" + (collapsedBankItems[index] ? "" : " show")}
                        aria-labelledby={"headingOne" + index}>
                        <div className="accordion-body">
                            <div className='row g-2'>
                                <div className='col-lg-4'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Account Term</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account number</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Opened</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Closed</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateClosed ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Ownership</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.ownership ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Rate Of Interest</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.rateOfInterest ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Value Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.valueOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Type Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.typeOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Description</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Reported</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.dateReported ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Loan Type</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.loanType ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account Status</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.accountStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Highest Credit</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.highestCredit ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Current balance</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.currentBalance ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Amount overdue</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.amountOverdue ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Last Payment Date</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.lastPaymentDate ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Details</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Credit Limit Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.creditLimitAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Emi</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.emiAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Repayment Tenure</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.repaymentTenure ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Total Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.totalWriteOffAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Principal Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.principalWriteOff ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Settlement Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.settlementAmount ?? "N/A"}</p>
                                                </div>
                                            </div>

                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Write Off Settle</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.writtenOffSettleStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row g-2 mt-3'>
                                <div className='col-lg-6 pe-lg-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.name ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.dateOfBirth ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.gender ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.mobileNumber ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.eamilAddress ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.pan ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 pt-sm-0 ps-lg-2 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0 '>
                                        <p className='account-details-text m-0 py-3'>Address</p>
                                        <div className='dividers'></div>
                                        <div className={`address-box${!accountess[index].creditAccountInformation?.addresses ||
                                            accountess[index].creditAccountInformation?.addresses.length === 0 ? ' no-scroll-address-box' : ''}`}>
                                            {(accountess[index].creditAccountInformation?.addresses && accountess[index].creditAccountInformation?.addresses.length > 0) ?
                                                accountess[index].creditAccountInformation?.addresses.map((address, idx) => (
                                                    <div key={idx}>
                                                        {idx !== 0 && <div className='dividers'></div>}
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-5 p-0 m-0'>
                                                                <p className='account-details-main-text account-details-main-text-new text-start'>Address {idx + 1}</p>
                                                            </div>
                                                            <div className='col-7 p-0 m-0'>
                                                                <p className='account-details-sub-text account-details-sub-text-new text-end'>{address ?? "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                <div className='d-flex h-100 align-items-center justify-content-center '>
                                                    <p className='p-0 m-0 px-1 text-center no-address-line'>There's no address associated with this account.</p>
                                                </div>
                                            }
                                        </div>
                                        {/* <div className='dividers'></div>
                                        <div className='row p-0 m-0'>
                                            <div className='col-6 p-0 m-0'>
                                                <p className='account-details-main-text account-details-main-text-new text-start'>Address 1</p>
                                            </div>
                                            <div className='col-6 p-0 m-0'>
                                                <p className='account-details-sub-text account-details-sub-text-new text-end'>{accountess[index].creditAccountInformation?.addresses[0] ?? "N/A"}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div >
            )
        }
        return accounts;
    }



    return (
        <div>
            {loader && <Loader />}
            {creditAnalysisResponse !== null && creditAnalysisResponse.data !== null && <div className='mt-3 mt-sm-2'>

                <section className='' id='creditscore'>
                    {bureauList.length !== 0 && creditReportData != null && <div className='container-lg container-fluid my-sm-3 pt-2 ps-lg-3'>
                        <Select
                            styles={customStyles}
                            components={{ Control }}
                            defaultValue={{ value: bureauList[creditBureauData.index], label: bureauList[creditBureauData.index] }}
                            placeholder='Select Credit Bureau'
                            isSearchable={false}
                            options={bureauList.map((bureau) => ({
                                value: bureau,
                                label: bureau,
                            }))}
                            onChange={(bureau) => {
                                setSelectedBureau(bureau.value)
                                dispatch(dispatchAction(SELECTED_BUREAU, bureau.value, false));
                                let param = {
                                    "businessUserId": props.businessUserId
                                }
                                //console.log(bureauList.indexOf(bureau.value))

                                setCreditBureauData({ index: bureauList.indexOf(bureau.value), currentBureau: bureau.value });
                                setCreditReportData(creditAnalysisResponse.data[bureauList.indexOf(bureau.value)].creditReport);
                                insightsAnalysisReports(creditAnalysisResponse.data[bureauList.indexOf(bureau.value)].analysis);
                                setIsViewAll(true)
                            }}
                            formatOptionLabel={bureau => (
                                <div className="country-option">
                                    <img src={
                                        bureau.value.toLowerCase() === "experian" ? ExperianLogo :
                                            (bureau.value.toLowerCase() === "transunion" || bureau.value.toLowerCase() === "cibil") ? CibillLogo :
                                                bureau.value.toLowerCase() === "equifax" ? EquifaxLogo : CrifLogo} alt="dropdown-logo" className='dropdown-logo' />
                                </div>
                            )}
                        />
                    </div>}
                    <div className='container-lg container-fluid  mt-0 my-sm-3'>
                        <div className='row align-items-start align-items-lg-center align-items-md-center g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0'>
                            <div className='col-5'>
                                <div className='ps-sm-2'>

                                    <p className='credit-bureau-text mb-2'>Customer Name: <span className='bold-txt'>{props.panelData !== undefined &&
                                        props.panelData !== null ? props.panelData.fullName : '-'}</span></p>
                                    <p className='credit-bureau-text mb-1'>Next Report In :  <span className='bold-txt'> 27 Days</span></p>

                                </div>


                            </div>
                            <div className='col-7'>
                                <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                                    <div className='text-end text-sm-end me-sm-2'>
                                        <p className='mb-0 userscore-textes'>Your Credit score</p>
                                        <h1 className={creditReportData && creditReportData !== null && creditReportData?.score?.score > 0 ? 'credit-score' : 'credit-score0'} >
                                            {(creditReportData && creditReportData !== null) ? (creditReportData?.score?.score && creditReportData?.score?.score !== null && Number(creditReportData?.score?.score) >= 0) ? creditReportData.score.score : '0' : '---'}
                                        </h1>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                                        <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                                            {(creditReportData?.score?.score >= 780 && creditReportData?.score?.score <= 900) &&
                                                <div className='excellect-box score-box'>
                                                    <p className='mb-0 score-box-text'>Excellent</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 706 && creditReportData?.score?.score <= 779) &&
                                                <div className='goods-box score-box'>
                                                    <p className='mb-0 score-box-text'>Good</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 631 && creditReportData?.score?.score <= 705) &&
                                                <div className='average-box score-box'>
                                                    <p className='mb-0 score-box-text'>Average</p>
                                                </div>}
                                            {(creditReportData?.score?.score <= 630) &&
                                                <div className='poor-box score-box'>
                                                    <p className='mb-0 score-box-text'>Poor</p>
                                                </div>}
                                            {(creditReportData?.score?.score === undefined || creditReportData?.score?.score === null || creditReportData?.score?.score === 0) &&
                                                <div className='score-not-show-box score-box mx-2'>
                                                    <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                                                </div>}
                                        </div>
                                        <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1" onClick={() => { openScoreDetails() }}></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className='crdit-score-dailog'>
                                {scoreDitailsDialog && (
                                    <ReactDialogBox
                                        closeBox={closeScoreDetails}
                                        headerBackgroundColor='white'
                                        headerTextColor='black'
                                        closeButtonColor='black'
                                        bodyBackgroundColor='white'
                                        bodyTextColor='black'
                                        headerText="Credit Score Range"
                                        bodyHeight={dialogHeight}
                                        borderRadius='8px'
                                        headerHeight='65px'
                                    >
                                        <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                                    </ReactDialogBox>)}
                            </div>
                        </div>
                    </div>
                </section>


                <section id='insights'>
                    <div className='container-lg container-fluid my-3'>
                        {analysisReportData && <div className='insights-main-box  p-0 m-0'>
                            <div className='row g-2'>
                                <div className='col-12 p-0'>
                                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>INSIGHTS</p>

                                </div>
                            </div>
                            <div className='dividers w-100'></div>
                            <div className='row g-2 '>
                                <div className='col-12 p-0 m-0'>
                                    <p className='ps-2 ps-lg-4 ps-md-3 m-0 pt-3 insights-sub-text'>Check out the factors affecting your credit score!</p>
                                </div>
                                <div className='col-12 parent-insight'>
                                    <AliceCarousel
                                        items={insightsItem}
                                        responsive={responsives}>
                                    </AliceCarousel>
                                    <div id='insightdialog'>
                                        {insightsDialog && (
                                            <ReactDialogBox
                                                closeBox={closeinsght}
                                                headerBackgroundColor='white'
                                                headerTextColor='black'
                                                closeButtonColor='black'
                                                bodyBackgroundColor='white'
                                                bodyTextColor='black'
                                                bodyHeight={dialogHeight}
                                                borderRadius='8px'
                                            >
                                                <Insights closeDialog={closeinsght} setDialogHeight={setDialogHeight} setAnalysisReportData={setAnalysisReportData} selectedAnalysisName={selectedAnalysisName} analysisReportData={analysisReportData[selectedAnalysisName]} />
                                            </ReactDialogBox>)}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section >

                {creditReportData?.creditAccountInformationDetails && <section id='summatyaccount'>
                    <div className='container-lg container-fluid my-3'>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 id='accountInformationButtons'
                                    className={`accordion-header accordion-button align-items-start pb-0 p-0 ${isCollapsedCreditInfo ? 'collapsed' : ''}`}
                                    type="button" data-bs-target="#panelsStayOpen-collapseOne"
                                    // className="accordion-header accordion-header accordion-button align-items-start pb-0 collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-controls="panelsStayOpen-collapseOne" onClick={toggleCollapseCreditInfo}>
                                    <div className={creditReportData && creditReportData?.reportSummary ? 'w-100 m-0 pb-3' : 'w-100 m-0 pb-0'}>
                                        <div className='d-flex justify-content-between py-3 align-items-center ps-2 ps-lg-4 ps-md-3'>
                                            <p className="acc-info-text m-0">Credit Account Information</p>
                                            <div className='pe-3'>
                                                <img src={ICArrowUp} className={`arrow_icon ${isCollapsedCreditInfo ? 'rotate-180' : ''}`}></img>
                                            </div>
                                            {/* <span className='text-end me-2 view-text-all'>View All</span> */}
                                        </div>
                                        {creditReportData && creditReportData?.reportSummary && <div className='dividers w-100'></div>}
                                        {creditReportData && creditReportData?.reportSummary && <section>
                                            <div className='my-2 ps-2 ps-lg-4 ps-md-3'>
                                                <div className='row align-items-center g-3'>
                                                    <div className='col-lg-2'>
                                                        <p className='account-summary-text'>Account Summary</p>
                                                    </div>
                                                    <div className='col-lg-10 row g-2'>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='balance-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Balance</p>
                                                                    {creditReportData?.reportSummary?.currentBalanceAccountSummary && <h3 className='summary-numbers'>₹ {(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount && creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount != null) ? creditReportData.reportSummary.currentBalanceAccountSummary.totalCurrentBalanceAmount : '-' : '-'}</h3>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='payments-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Payments</p>
                                                                    <h3 className='summary-numbers'> -</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='open-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Open Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts && creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.activeAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='closed-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Closed Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts && creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.closedAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>}
                                    </div>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${isCollapsedCreditInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayOpenExample">
                                    <div className="accordion-body">
                                        <div className='row m-0 p-0'>
                                            <div className='col-12 m-0 p-0'>
                                                <div className='account-accordion-box'>
                                                    <div className="accordion" id="accordionFlushExample">
                                                        {creditReportData?.creditAccountInformationDetails
                                                            && creditReportData?.creditAccountInformationDetails.length > 0
                                                            && accountDetails(creditReportData.creditAccountInformationDetails, creditReportData.personalInformation)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

                {creditReportData?.personalInformation && <div className='container-lg container-fluid my-3' id='accountinfo'>
                    <div className="accordion" id="accordionPanelsStayTwoExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header mb-0">
                                <div className='d-flex flex-row justify-content-between py-1'>
                                    <div id='personalInformationCollapseButton'
                                        className={`accordion-button acc-info-text m-0 ${isCollapsedPersonalInfo ? 'collapsed' : ''}`} type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo"
                                        onClick={() => {
                                            toggleCollapsedPersonalInfo()
                                            // onPersonalInformationExpand()
                                        }}>
                                        Personal Information
                                    </div>
                                    <div className='pe-3 pt-1'>
                                        <img src={ICArrowUp} className={`arrow_icon ${isCollapsedPersonalInfo ? 'rotate-180' : ''}`}></img>
                                    </div>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse ${isCollapsedPersonalInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayTwoExample">
                                <div className="accordion-body">
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg-6 pe-lg-2'>
                                            <div className='account-details-box p-0 m-0'>
                                                <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                                <div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.name ?? "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.dateOfBirth ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.gender ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.mobileNumber ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.eamilAddress ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.pan ?? "N/A"}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 ps-lg-2 pt-lg-0 pt-sm-0 pt-md-2 pt-2'>
                                            <div className='account-details-box p-0 m-0 '>
                                                <p className='account-details-text m-0 py-3'>Address</p>
                                                <div className='address-box'>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.address ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}


            </div >}

            {((creditAnalysisResponse === null || creditAnalysisResponse.data === null) && loader === false) && <div className="col-12 text-center">
                <img src={NoFoundDocs} className="no-found-doc-img" />
                <p className="p-0 m-0 doc-desc fw-bold pt-4">
                    Looks like there are not available credit information for this user.
                </p>
            </div>}



        </div>
    );
};



export default CreditTabComponent;
