import { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import MenuBar from "../Menubar/menubar";
import Sidebar from "../SideBar/sidebar";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { Modal } from 'react-responsive-modal';
import Footer from "../../landing-page/Footer/Footer";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from 'moment/moment';
import '../usage-overview/component/date-range.css';
import { getGenetateBSAClientToken } from "../../Component/GenetateBSAClientToken";
import {
    statementList,
    downloadExcel,
    downloadAnaltics,
} from "../../../redux/actions/bsa_b2b_action";
import DownloadOptions from "./download-options";
import Tooltip from "../../Component/tool-tip";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../loader/loader";
import "./bank-account-statements.css";
import { columns, data } from "./load-bank-data";
import TabComponent from "../../Component/tab-component/tab-component.js";
import {
    downloadAnalticsProd,
    downloadExcelProd,
    statementProdList,
} from "../../../redux/actions/bsa_b2b_prod_action";
import { cache } from "react";
import PaginationComponent from "../../Component/pagination/pagination-component.js";
import FilterComponent from "../my-customers/searchbar/filter-component.js";
import SuccessFailDialog from "../../Component/dialogs/success-fail-dialog.js";
const UploadDocIC = require("../../../images/assets/upload_ic.png");
const UserListIC = require("../../../images/assets/user-list-ic.png");
const DownArrowIC = require("../../../images/assets/down_arrow.png");

const BankAccountList = (props) => {
    const navigate = useNavigate();
    // const [data, setData]= useState([]);
    const [isFromProd, setFromProd] = useState(false);
    const [search, setSearch] = useState("");
    const [mainAccountList, setMainAccountList] = useState([]);
    const [tempDataList, setDataList] = useState();
    const [loader, setLoader] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [currentRow, setCurrentRow] = useState(null);
    const [sizePerPage, setSizePerPage] = useState(5); /// initial default sizePerPage === 5
    const [totalRecordFromApi, setTotalRecordFromApi] = useState(0);
    const [fiuPartialMsg, setFiuPartialMsg] = useState({ msg: '', uuid: '' });
    const [openResSPFDialog, setOpenStatmentResSPFDialog] = useState(false);
    const [fiuMultiBankSPF, setFiuMultiBankSPF] = useState('');
    const [reqParamDBApi, setReqParam] = useState({
        "clientId": null,
        "fromDate": null, //getParsedDate(-1, 'yyyy/MM/dd'),
        "toDate": null, //getParsedDate(0, 'yyyy/MM/dd')
    });
    const [dateRange, setDateRange] = useState(
        []);

    useEffect(() => {
        /// HIDE ELEMENT Class which prevented download button hidden issue fixed
        // setTimeout(() => {
        //     var element = document.querySelector('.fpBGKh');
        //     if (element) {
        //         element.classList.replace('fpBGKh', 'fpBGKh-new');
        //     }
        // }, 1000);
        let isProd = window.location.search === "?prod";
        setFromProd(isProd);
        const fetchData = async () => {
            ReactGA.event({
                category: "Component/Page_Render",
                action: "multi_bank_statement_list_component_render",
                label: "multi_bank_statement_list_component_render",
            });

            setLoader(true); // Uncomment if needed

            const uuid = uuidv4();
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay

            if (isProd) {
                fetchStatementList(getGenetateBSAClientToken, statementProdList, uuid);
            } else {
                fetchStatementList(getGenetateBSAClientToken, statementList, uuid);
            }
        };

        fetchData(); // Call the async function
    }, []);

    const closeStatmentResSPFDialog = () => {
        setOpenStatmentResSPFDialog(false)
    }

    const openStatmentResSPFDialog = () => {
        setOpenStatmentResSPFDialog(true)
    }

    const DownloadUploadButton = ({ row }) => (
        <div className="mt-2 mb-2">
            <div className="d-flex align-items-center">
                {/* <Tooltip content="More options" > */}
                <DownloadOptions
                    commonOnClick={(clickFrom) => {
                        if (clickFrom === "DOWNLOAD_EXCEL") {
                            downloadExcelReport(row.accountUid);
                        }
                        if (clickFrom === "DOWNLOAD_ANALYTICS_PDF") {
                            downloadAnalyticsPDF(row.accountUid);
                        }
                    }}
                />
                {/* </Tooltip> */}
            </div>
        </div>
    );

    const buttonStyles = {
        width: "100px",
        height: "36px",
        flexShrink: 0,
        borderRadius: "8px",
        border: "1px solid #7624E7",
        backgroundColor: "white",
        color: "#7624E7",
        fontWeight: "600",
        fontSize: "13px",
        padding: "0px",
        margin: "5px",
    };

    const downloadAnalyticsPDF = (accountId) => {
        setLoader(true);

        ReactGA.event({
            category: "CTA",
            action: "btn_click_analytics_report_sandbox_api_call",
            label: "analytics_report_sandbox_api_call",
        });

        const param = {
            accountUid: accountId,
        };

        const uuid = uuidv4();
        const handleDownload = async () => {
            try {
                let isFromProd = window.location.search === "?prod"
                const response = isFromProd ? await downloadAnalticsProd(param, uuid)
                    : await downloadAnaltics(param, uuid);
                const url = window.URL.createObjectURL(
                    new Blob([response], { type: "application/pdf" })
                );
                const a = document.createElement("a");
                a.href = url;
                a.download = accountId + ".pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoader(false);

                ReactGA.event({
                    category: "API_CALL",
                    action: "api_call_analytics_report_sandbox",
                    label: "api_call_analytics_report_sandbox_success",
                    value: 1,
                });
            } catch (error) {

                ReactGA.event({
                    category: "API_CALL",
                    action: "api_call_analytics_report_sandbox",
                    label: "api_call_analytics_report_sandbox_fail",
                    value: 0,
                });
                setLoader(false);
                // Check if the response is an error (e.g., JSON instead of a file)
                const contentType = error.type;
                if (contentType === "application/json") {
                    const errorText = await error.text();
                    const errorJson = JSON.parse(errorText);
                    setFiuMultiBankSPF("ERROR");
                    setFiuPartialMsg({ msg: errorJson.message, uuid: uuid })
                    openStatmentResSPFDialog();
                    // console.log(`Error ${errorJson} ${errorJson.code}: ${errorJson.message}`);
                    //throw new Error(`Error ${errorJson.code}: ${errorJson.message}`);
                } else {
                    setFiuMultiBankSPF("ERROR");
                    setFiuPartialMsg({ msg: "Something went wrong!", uuid: uuid })
                    openStatmentResSPFDialog();
                }
                console.error("Error downloading the Analytics file:", error);
            }


        };
        getGenetateBSAClientToken(
            isFromProd,
            () => handleDownload(),
            (errorMessage) => {
                setLoader(false);
                console.error("Error:", errorMessage);
            }
        );
    };

    const downloadExcelReport = (accountId) => {
        setLoader(true);

        ReactGA.event({
            category: "CTA",
            action: "btn_click_excel_report_sandbox_api_call",
            label: "excel_report_sandbox_api_call",
        });

        const uuid = uuidv4();

        const handleDownload = async () => {
            setLoader(true); // Set loader before starting download

            try {
                const response = isFromProd
                    ? await downloadExcelProd(accountId, uuid)
                    : await downloadExcel(accountId, uuid);


                const url = window.URL.createObjectURL(new Blob([response]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `${accountId}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoader(false);

                ReactGA.event({
                    category: "API_CALL",
                    action: "api_call_excel_report_sandbox",
                    label: "api_call_excel_report_sandbox_success",
                    value: 1,
                });
            } catch (error) {

                setLoader(false);
                ReactGA.event({
                    category: "API_CALL",
                    action: "api_call_excel_report_sandbox",
                    label: "api_call_excel_report_sandbox_fail",
                    value: 0,
                });
                // Check if the response is an error (e.g., JSON instead of a file)
                const contentType = error.type;
                if (contentType === "application/json") {
                    const errorText = await error.text();
                    const errorJson = JSON.parse(errorText);
                    setFiuMultiBankSPF("ERROR");
                    setFiuPartialMsg({ msg: errorJson.message, uuid: uuid })
                    openStatmentResSPFDialog();
                    // console.log(`Error ${errorJson} ${errorJson.code}: ${errorJson.message}`);
                    //throw new Error(`Error ${errorJson.code}: ${errorJson.message}`);
                } else {
                    setFiuMultiBankSPF("ERROR");
                    setFiuPartialMsg({ msg: "Something went wrong!", uuid: uuid })
                    openStatmentResSPFDialog();
                }
                console.error("Error downloading the Excel file:", error);
            }
        };

        getGenetateBSAClientToken(
            isFromProd,
            () => handleDownload(),
            (errorMessage) => {
                setLoader(false);
                console.error("Error:", errorMessage);
            }
        );
    };



    useMemo(() => {
        let filteredItems = tempDataList;

        // Date filtering
        if (reqParamDBApi.fromDate || reqParamDBApi.toDate) {
            const fromDateObj = reqParamDBApi.fromDate ? new Date(reqParamDBApi.fromDate).setHours(0, 0, 0, 0) : null;
            const toDateObj = reqParamDBApi.toDate ? new Date(reqParamDBApi.toDate).setHours(23, 59, 59, 999) : null;

            filteredItems = filteredItems.filter(item => {
                if (item.uploadedDate) {
                    const uploadedDate = new Date(item.uploadedDate).setHours(0, 0, 0, 0);
                    return (fromDateObj ? uploadedDate >= fromDateObj : true) &&
                        (toDateObj ? uploadedDate <= toDateObj : true);
                }
                return false;
            });
        }
        // Text search filtering
        if (filterText === '') {
            setMainAccountList(tempDataList);
        } else {
            filteredItems = filteredItems.filter(item =>
                (item.accountHolderName && item.accountHolderName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.accountNumber && item.accountNumber.toLowerCase().includes(filterText.toLowerCase()))
            );
        }
        setMainAccountList(filteredItems);
        ;
    }, [tempDataList, filterText, reqParamDBApi]);

    // useMemo(() => {
    //     if (filterText === '') {
    //         setMainAccountList(tempDataList);
    //     } else {
    //         const filteredItems = tempDataList.filter(item =>
    //         (item.accountHolderName && item.accountHolderName.toLowerCase().includes(filterText.toLowerCase())
    //             || item.accountNumber && item.accountNumber.toLowerCase().includes(filterText.toLowerCase())
    //         )
    //         );
    //         setMainAccountList(filteredItems);
    //     }

    // }, [filterText,]);

    const handleClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    const columns = (fromDemoUser = false) => [
        {
            style: {
                display: "flex",
                justifyContent: "start",
            },
            sortable: false,
            cell: (row) => <UserName row={row} />,
        },

        {
            sortable: false,
            style: {
                display: "flex",
                justifyContent: "end",
            },
            cell: (row) => (
                <DownloadUploadButton row={row} fromDemoUser={fromDemoUser} />
            ),
        },
        {
            sortable: false,
            width: "3rem",
            style: {
                display: "flex",
                justifyContent: "center",
            },
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <img
                        src={DownArrowIC}
                        alt="UserListIC"
                        className={
                            row === currentRow
                                ? "down-arrow-ic custom-rotate-180"
                                : "down-arrow-ic"
                        }
                        data-tag="allowRowEvents"
                    />
                </div>
            ),
        },
    ];
    const customStyles = {
        rows: {
            style: {
                borderRadius: "8px",
                minHeight: "90px",
                margin: "10px 0px",
                border: "1px solid #CECECE",
                color: "red",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
            },
        },
        cells: {
            style: {
                paddingTop: "12px",
                paddingBottom: "12px",
                display: "flex",
                flexWrap: "wrap",
                cursor: "pointer",
                userSelection: "none",
                alignItems: "center",
            },
        },
        table: {
            style: {
                marginBottom: "10px",
            },
        },
    };

    const InfoRow = ({ date, accountNumber, name }) => (
        <div className="d-flex align-items-start justify-content-center">
            <img src={UserListIC} alt="UserListIC" className="user-ic" />
            <div className="ps-2">
                <div className="align-items-center justify-content-center">
                    <p className="bank-name-text p-0 m-0">{name}</p>
                </div>
                <div className="align-items-center justify-content-center">
                    <h1 className="reported-date mb-0 p-0  pt-1">{accountNumber}</h1>
                </div>
                <div className="align-items-center justify-content-center">
                    <h1 className="reported-date mb-0 p-0  pt-1">{date}</h1>
                </div>
            </div>
        </div>
    );

    const handlePerRowsChange = async (pageNo, totalRecord) => {
        if (pageNo > 0) {
            pageNo = pageNo - 1;
        } else {
            pageNo = 0;
        }
        let isProd = window.location.search === "?prod";
        setFromProd(isProd);
        const fetchData = async () => {
            ReactGA.event({
                category: "Component/Page_Render",
                action: "multi_bank_statement_list_component_render",
                label: "multi_bank_statement_list_component_render",
            });

            setLoader(true); // Uncomment if needed

            const uuid = uuidv4();
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay

            if (isProd) {
                fetchStatementList(getGenetateBSAClientToken, statementProdList, uuid);
            } else {
                fetchStatementList(getGenetateBSAClientToken, statementList, uuid);
            }
        };

        fetchData(); // Call the async function
    };

    const UserName = ({ row }) => (

        < InfoRow date={moment(row?.uploadedDate).format('DD-MM-YYYY')} accountNumber={row.accountNumber} name={row.accountHolderName} />
    );

    const fetchStatementList = (getToken, statementListData, uuid) => {
        let isProd = window.location.search === "?prod";
        setFromProd(isProd);
        try {
            getToken(
                isProd,
                () => {
                    statementListData(uuid)
                        .then((resp) => {
                            setLoader(false);

                            if (resp.status === "SUCCESS") {
                                ReactGA.event({
                                    category: "API_CALL",
                                    action: props.openFromProd
                                        ? "api_call_get_bank_statement_list_prod"
                                        : "api_call_get_bank_statement_list_sandbox",
                                    label: props.openFromProd
                                        ? "api_call_get_bank_statement_list_prod_success"
                                        : "api_call_get_bank_statement_list_sandbox_success",
                                    value: 1,
                                });
                                setMainAccountList(resp.accountList);
                                setTotalRecordFromApi(resp.accountList.length);
                                setDataList(resp.accountList);
                            }
                        })
                        .catch((error) => {
                            ReactGA.event({
                                category: "API_CALL",
                                action: props.openFromProd
                                    ? "api_call_get_bank_statement_list_prod"
                                    : "api_call_get_bank_statement_list_sandbox",
                                label: props.openFromProd
                                    ? "api_call_get_bank_statement_list_prod_fail"
                                    : "api_call_get_bank_statement_list_sandbox_fail",
                                value: 0,
                            });
                            setLoader(false);
                            console.error("Error:", error); // Handle error
                        });
                },
                (errorMessage) => {
                    setLoader(false);
                    console.error("Error:", errorMessage); // Handle error
                }
            );
        } catch (e) {
            setLoader(false);
        }
    };



    const ExpandedRowWrapper = (row) => (
        <ExpandedComponent props={row} isFromDemoUser={false} />
    );

    const ExpandedComponent = ({ props, isFromDemoUser }) => {
        const [currentTabName, setCurrentTabName] = useState("bank");
        const [isCreditShow, setCreditShow] = useState(true);

        return (
            <div className="m-0 p-0">
                <div className="col-12 d-flex m-0 p-0 flex-wrap info-root-box p-3">
                    <div className="col-12 p-0 m-0">
                        <TabComponent
                            showBank={true}
                            showScurb={false}
                            showGST={false}
                            showCredit={false}
                            tabIndex={2}
                            onTabChange={(i) => {
                                if (i === 1) {
                                    setCurrentTabName('credit')
                                } else if (i === 2) {
                                    setCurrentTabName('bank')
                                } else if (i === 3) {
                                    setCurrentTabName('gst')
                                }
                            }}
                        ></TabComponent>
                    </div>

                    {currentTabName === "bank" && (
                        <div className="col-12 m-0 p-0">
                            <div className="row m-0 p-0 ">
                                {props && props?.data !== null && (
                                    <div
                                        className="col-lg-6 col-md-6 ps-1 m-0 pt-3 pt-lg-4 pt-md-3 pt-sm-3"
                                        key={props.accountNumber}
                                    >
                                        <div className="info-details-box">
                                            <div className="row pt-2 pb-0">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <p className="info-details-label-text m-0">
                                                        Bank Account{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dividers"></div>
                                            <div className="row col-12">
                                                <div className="d-flex col-lg-12 flex-column">
                                                    <div className="d-flex justify-content-between pt-2 pb-2">
                                                        <p className="info-details-main-text m-0">Name</p>
                                                        <p className="info-details-sub-text m-0">
                                                            {props?.data.accountHolderName ?? "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="dividers p-0 m-0"></div>
                                                    <div className="d-flex justify-content-between pt-2 pb-2">
                                                        <p className="info-details-main-text m-0">
                                                            Account Number
                                                        </p>
                                                        <p className="info-details-sub-text m-0">
                                                            {props?.data.accountNumber ?? "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="dividers p-0 m-0"></div>
                                                    {/* <div className='d-flex justify-content-between pt-2 pb-1'>
                                                        <p className='info-details-main-text m-0'>Status</p>
                                                        <p className={bankAccountDetail && bankAccountDetail.status == "SUCCESS" ? 'info-details-sub-text m-0 response-status' : 'info-details-sub-text m-0'}>{bankAccountDetail?.status ?? 'N/A'}</p>
                                                    </div> */}
                                                    <div className="dividers p-0 m-0"></div>
                                                    <div className="d-flex justify-content-between pt-2 pb-2">
                                                        <p className="info-details-main-text m-0">Bank</p>
                                                        <p className="info-details-sub-text m-0">
                                                            {props?.data.bankCode ?? "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="dividers p-0 m-0"></div>
                                                    <div className="d-flex justify-content-between pt-2 pb-2">
                                                        <p className="info-details-main-text m-0">
                                                            Account UID
                                                        </p>
                                                        <p className="info-details-sub-text m-0">
                                                            {props?.data.accountUid ?? "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="dividers p-0 m-0"></div>
                                                    {/* <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Last Updated</p>
                                                        <p className='info-details-sub-text m-0'>{moment(bankAccountDetail?.updatedDate).format('DD-MM-YYYY') ?? 'N/A'}</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}



                </div>
            </div>
        );
    };

    return (
        <>
            <MenuBar />
            <div className="sidebar-box sidnav-box">
                <Sidebar></Sidebar>
                <div id="scoreDetails" className="sidebat-inner-box sidnav-inner-box">
                    {loader && <Loader />}
                    <div className="content">
                        <div id="sdk" className="mt-4 mt-sm-2">
                            <section className="" id="uatenv">
                                <div className="container-lg container-fluid mt-3 my-sm-3">
                                    <div
                                        className="row g-1 g-sm-2 my-2 mb-0 px-sm-2 px-2 px-md-0 d-flex
                                     flex-column flex-md-row justify-content-md-between align-items-center"
                                    >
                                        <div className="col-12 col-md-auto">
                                            <div className="text-center text-md-start">
                                                <h1 className="p-0 m-0 username-text">
                                                    Bank Statements
                                                </h1>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-auto p-0 m-0">
                                            <div className="ps-sm-2 text-center text-md-end">
                                                <button
                                                    className="add-user-btn mx-2"
                                                    type="button"
                                                    onClick={() => {
                                                        if (isFromProd) {
                                                            navigate("/upload-bank-statements?prod");
                                                        } else {
                                                            navigate("/upload-bank-statements?uat");
                                                        }
                                                    }}
                                                >
                                                    <span className="pe-1">
                                                        {" "}
                                                        <img
                                                            className="dl-report-ic"
                                                            src={UploadDocIC}
                                                            alt="upload doc"
                                                        />
                                                    </span>
                                                    Upload Statements
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="userslist">
                                <div className="container-lg container-fluid my-3">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header accordion-header accordion-button align-items-start pb-0"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="true"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <div className="w-100">
                                                    <div>
                                                        <div className="d-flex justify-content-between">
                                                            <p className="acc-info-text">
                                                                Bank Statement List
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                            >
                                                <div className="accordion-body p-0 m-0">
                                                    <div className="row  m-0 p-0">

                                                        <div className="m-0 p-0 pb-2 px-2 d-flex flex-wrap align-items-center
                       justify-content-between flex-column flex-md-row">
                                                            {/* Search Bar - Left */}
                                                            <div className="flex-grow-1 ps-2 m-0 p-0 pt-3">
                                                                <FilterComponent
                                                                    onFilter={(e) => setFilterText(e.target.value)}
                                                                    onClear={handleClear}
                                                                    placeHolder="Search on name, account no"
                                                                    filterText={filterText}
                                                                />
                                                            </div>

                                                            {/* Buttons - Right */}

                                                            <div className="p-0 m-0 px-3 pt-3">
                                                                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                                                                    <DateRangePicker
                                                                        cleanable
                                                                        onChange={(date) => {
                                                                            setReqParam(prevState => ({
                                                                                ...prevState,
                                                                                fromDate: date === null ? null : new Date(date[0]).toLocaleDateString("en-CA"),
                                                                                toDate: date === null ? null : new Date(date[1]).toLocaleDateString("en-CA")
                                                                            }));
                                                                            setDateRange(date);

                                                                        }}
                                                                        showOneCalendar
                                                                        block
                                                                        format="dd/MM/yyyy" color="#4243F1"
                                                                        placeholder='Select Date Range' value={dateRange} />


                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-12 m-0 p-0">
                                                            <div className="account-accordion-box">
                                                                <div
                                                                    className="accordion ps-3 pe-3"
                                                                    id="accordionFlushExample"
                                                                >
                                                                    {mainAccountList && (
                                                                        <DataTable
                                                                            title="Users"
                                                                            columns={columns(false)}
                                                                            data={mainAccountList}
                                                                            noHeader={true}
                                                                            customStyles={customStyles}
                                                                            noTableHead={true}
                                                                            expandableRows
                                                                            expandOnRowClicked={true}
                                                                            expandableRowsComponent={
                                                                                ExpandedRowWrapper
                                                                            }
                                                                            pagination
                                                                            paginationServer={false}
                                                                            paginationPerPage={sizePerPage}
                                                                            paginationTotalRows={totalRecordFromApi}
                                                                            onChangePage={handlePerRowsChange}
                                                                            paginationComponent={PaginationComponent}
                                                                            onRowExpandToggled={(bool, row) => {
                                                                                if (row === currentRow) {
                                                                                    setCurrentRow(null); // Collapse the row if it's already expanded
                                                                                } else {
                                                                                    setCurrentRow(row); // Expand the row if it's collapsed
                                                                                }
                                                                            }}
                                                                            expandableRowExpanded={(row) =>
                                                                                row === currentRow
                                                                            }
                                                                            onRowClicked={(row) => setCurrentRow(row)}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {openResSPFDialog && <Modal open={true} center classNames={{
                                modal: 'spf-modal',
                            }}>
                                <SuccessFailDialog
                                    fiuMultiBankSPF={fiuMultiBankSPF}
                                    fiuPartialMsg={fiuPartialMsg}
                                    closeStatmentResSPFDialog={closeStatmentResSPFDialog}>
                                </SuccessFailDialog>
                            </Modal>}

                        </div>
                    </div>


                    {/* <Footer /> */}
                </div>
            </div>
        </>
    );
};

export default BankAccountList;
