import React, { useState, useEffect } from 'react'
import '../../boost-score/bank-statement/bank-statement.css';
import '../../Dashboard/users/create-users/create-users.css'
import '../../../App.css'
const ERROR_IC = require("../../../images/assets/error-main.png");
const FiuSuccess = require("../../../images/assets/success-main.png");
const FiuPartialSuccess = require("../../../images/assets/success-partial.png");

const SuccessFailDialog = (props) => {

    return (<>
        <div className='container-box'>
            {/* <div className='header-action'>
                <img src={Close} className="float-end img-close-logo" onClick={() => {  }} alt='fiu_error' />
            </div> */}

            <div className=''>
                <h5 className={props.fiuMultiBankSPF == "PARTIAL_SUCCESS" ?
                    'text-center partial-txt' : (props.fiuMultiBankSPF == "ERROR" ? 'text-center error-txt' : 'text-center success-txt')}>
                    {props.fiuMultiBankSPF == "PARTIAL_SUCCESS" ? 'Partial Success' :
                        (props.fiuMultiBankSPF == "ERROR" ? "Upload Failed" : 'Congratulations!')}</h5>
                <p className='spf-msg text-center'>{props.fiuPartialMsg.msg}</p>
            </div>

            <div className='mt-top'></div>

            <div className='row justify-content-center'>
                <div className='col-12 text-center mt-2'>
                    <img src={props.fiuMultiBankSPF == "PARTIAL_SUCCESS" ? FiuPartialSuccess :
                        (props.fiuMultiBankSPF == "ERROR" ? ERROR_IC : FiuSuccess)}
                        alt='fiu_success' className='spf-center-img' />
                </div>
            </div>

            <div className='mt-ext-space-success'></div>
            {props.fiuPartialMsg.uuid != '' && props.fiuMultiBankSPF != "SUCCESS" && <p className='spf-ref-id m-0 p-0 text-center'>Reference ID : {props.fiuPartialMsg.uuid}</p>}

            <div className='mt-ext-space-success'></div>
            <div className='col-12 text-center mt-2 pt-0'>
                <button className="spf-button" type="button"
                    onClick={() => {
                        props.closeStatmentResSPFDialog();
                    }}>
                    {'Done'}</button>
            </div>


        </div >
    </>);

}
export default SuccessFailDialog;