import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sub-tab-component.css';
import { getScrubDataDetail } from '../../../../redux/actions/dashboard_action';
import Loader from '../../../loader/loader';

const SubTabComponent = (props) => {
    const [activeTab, setActiveTab] = useState('Summary');
    const data = [
        { id: 1, title: 'Scrub Summary', content: 'This is the content for Scrub Summary' },
    ];
    const tabs = ['Summary', 'Inquiry', 'Demographics', 'Accounts', 'Cross Sell Score', 'IOI'];
    const [scrubDetail, setScrubDetail] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true)
        let param = {
            "businessUserId": props.businessUserId
        }
        getScrubDataDetail(param).then((resp) => {
            console.log(resp);
            setScrubDetail(resp);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            // console.log("error ", error)
        })
    }, []);



    return (
        <div className='custom-tabs-parents'>
            <ul className="nav nav-tabs custom-tabs d-flex flex-row justify-content-between p-0 m-0">
                {tabs.map(tab => (
                    <li className="nav-item p-0 m-0" key={tab}>
                        <div
                            className={`nav-link  ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </div>
                    </li>
                ))}
            </ul>

            <div className="tab-content pt-2 ">
                {loader && <Loader />}
                {scrubDetail && <TabContent activeTab={activeTab} data={scrubDetail}></TabContent>}

            </div>


        </div>
    );
};


const SummaryContent = ({ data, }) => {
    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };
    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.summary && (
                        <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                            key={Math.random()}>
                            <div className="accordion-item">
                                <h2
                                    className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === 0 ? '' : 'collapsed'}`}
                                    onClick={() => togglePanel(0)}
                                    aria-expanded={openPanel === 0}>
                                    <div className='w-100'>
                                        <div className='d-flex justify-content-between'>
                                            <p className="acc-info-text text-capitalize">Scrub Summary</p>

                                        </div>
                                    </div>
                                </h2>
                                <div id={`panel${0}`} className={`accordion-collapse collapse ${openPanel === 0 ? 'show' : ''}`}>
                                    <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                        <div className='col-lg-12 col-md-12'
                                        >
                                            <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>

                                                {/* <div className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                    <div className='d-flex col-lg-12 flex-column m-0'>
                                                        <div className='d-flex justify-content-between pt-3 pb-2'>
                                                            <p className='info-details-main-text m-0 '>LOS Application ID</p>
                                                            <p className='info-details-sub-text m-0 bold-sub-text'>N/A</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-1'>
                                                            <p className='info-details-main-text m-0'>Phone Number</p>
                                                            <p className='info-details-sub-text m-0 bold-sub-text'>N/A</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='vertical-divider d-none d-lg-inline d-md-inline'></div>
                                                <div className='dividers p-0 m-0 d-inline d-lg-none d-md-none'></div>
                                                <div className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12 '>

                                                    <div className='d-flex col-lg-12 flex-column m-0'>

                                                        <div className='d-flex justify-content-between pt-3 pb-2'>
                                                            <p className='info-details-main-text m-0'>Credit Report ID</p>
                                                            <p className='info-details-sub-text m-0 bold-sub-text'>N/A</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-2'>
                                                            <p className='info-details-main-text m-0'>Overdue Credit Accounts</p>
                                                            <p className='info-details-sub-text m-0 bold-sub-text'>N/A</p>
                                                        </div>

                                                    </div>
                                                </div> */}

                                                {Object.entries(data.data.summary).map(([key, value]) => (
                                                    <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                        <div className='d-flex col-lg-12 flex-column m-0'>
                                                            <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.summary[`${key}_LABEL`]}</p>
                                                                <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                            </div>
                                                            <div className='dividers p-0 m-0'></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='p-0 m-0 text-center pt-3'>
                        {data.data.summary == null || data.data.summary == undefined ||
                            data.data.summary.length == 0 && (<p className='p-0 m-0'>No Summary found!</p>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const InquiryContent = ({ data }) => {

    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };

    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.inquiry && (
                        data.data.inquiry.map((inquiryDetail, index) => (
                            <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                                key={Math.random()}>
                                <div className="accordion-item">
                                    <h2
                                        className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === index ? '' : 'collapsed'}`}
                                        onClick={() => togglePanel(index)}
                                        aria-expanded={openPanel === index}>
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text text-capitalize">Inquiry {index + 1}</p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id={`panel${index}`} className={`accordion-collapse collapse ${openPanel === index ? 'show' : ''}`}>
                                        <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>
                                                    {Object.entries(inquiryDetail).map(([key, value]) => (
                                                        <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                            <div className='d-flex col-lg-12 flex-column m-0'>
                                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                    {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                    <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.inquiry[`${key}_LABEL`]}</p>
                                                                    <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                                </div>
                                                                <div className='dividers p-0 m-0'></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    <div className='p-0 m-0 text-center pt-3'>
                        {data.data.inquiry == null || data.data.inquiry == undefined ||
                            data.data.inquiry.length == 0 && (<p className='p-0 m-0'>No Inquiries avaialble!</p>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DemographicsContent = ({ data }) => {

    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };

    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.demog && (
                        data.data.demog.map((demogDetail, index) => (
                            <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                                key={Math.random()}>
                                <div className="accordion-item">
                                    <h2
                                        className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === index ? '' : 'collapsed'}`}
                                        onClick={() => togglePanel(index)}
                                        aria-expanded={openPanel === index}>
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text text-capitalize">Demographics {index + 1}</p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id={`panel${index}`} className={`accordion-collapse collapse ${openPanel === index ? 'show' : ''}`}>
                                        <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>
                                                    {Object.entries(demogDetail).map(([key, value]) => (
                                                        <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                            <div className='d-flex col-lg-12 flex-column m-0'>
                                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                    {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                    <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.demog[`${key}_LABEL`]}</p>
                                                                    <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                                </div>
                                                                <div className='dividers p-0 m-0'></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

const AccountContent = ({ data }) => {

    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };

    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.accounts && (
                        data.data.accounts.map((accountsDetail, index) => (
                            <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                                key={Math.random()}>
                                <div className="accordion-item">
                                    <h2
                                        className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === index ? '' : 'collapsed'}`}
                                        onClick={() => togglePanel(index)}
                                        aria-expanded={openPanel === index}>
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text text-capitalize">Account {index + 1}</p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id={`panel${index}`} className={`accordion-collapse collapse ${openPanel === index ? 'show' : ''}`}>
                                        <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>
                                                    {Object.entries(accountsDetail).map(([key, value]) => (
                                                        <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                            <div className='d-flex col-lg-12 flex-column m-0'>
                                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                    {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                    <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.account[`${key}_LABEL`]}</p>
                                                                    <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                                </div>
                                                                <div className='dividers p-0 m-0'></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    <div className='p-0 m-0 text-center pt-3'>
                        {data.data.accounts == null || data.data.accounts == undefined ||
                            data.data.accounts.length == 0 && (<p className='p-0 m-0'>No accounts found!</p>)}
                    </div>

                </div>
            </div>
        </div>
    );
};

const CrossSellContent = ({ data }) => {
    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };

    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.crossSellScores && (
                        data.data.crossSellScores.map((crossSellScoresDetail, index) => (
                            <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                                key={Math.random()}>
                                <div className="accordion-item">
                                    <h2
                                        className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === index ? '' : 'collapsed'}`}
                                        onClick={() => togglePanel(index)}
                                        aria-expanded={openPanel === index}>
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text text-capitalize">{index == 0 ? 'Perform Consumer 2.0' : 'X Sell Score'}</p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id={`panel${index}`} className={`accordion-collapse collapse ${openPanel === index ? 'show' : ''}`}>
                                        <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>
                                                    {Object.entries(crossSellScoresDetail).map(([key, value]) => (
                                                        <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                            <div className='d-flex col-lg-12 flex-column m-0'>
                                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                    {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                    <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.crossSellScore[`${key}_LABEL`]}</p>
                                                                    <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                                </div>
                                                                <div className='dividers p-0 m-0'></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    <div className='p-0 m-0 text-center pt-3'>
                        {data.data.crossSellScores == null || data.data.crossSellScores == undefined ||
                            data.data.crossSellScores.length == 0 && (<p className='p-0 m-0'>No Cross scroll found!</p>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const IOIContent = ({ data }) => {

    // State to manage which panel is expanded
    const [openPanel, setOpenPanel] = useState(null);

    // Toggle function for each accordion item
    const togglePanel = (id) => {
        setOpenPanel(openPanel === id ? null : id);
    };

    return (
        <div className={`tab-pane fade bg-white show active`}>
            <div className='pt-3'>
                <div className='container-lg container-fluid p-0 m-0'>
                    {data.data.ioi && (
                        data.data.ioi.map((ioiDetail, index) => (
                            <div className="accordion mt-3" id="accordionPanelsStayOpenExample"
                                key={Math.random()}>
                                <div className="accordion-item">
                                    <h2
                                        className={`accordion-header accordion-button align-items-start pb-0 accordion-button ${openPanel === index ? '' : 'collapsed'}`}
                                        onClick={() => togglePanel(index)}
                                        aria-expanded={openPanel === index}>
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text text-capitalize">IOI {index + 1} </p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id={`panel${index}`} className={`accordion-collapse collapse ${openPanel === index ? 'show' : ''}`}>
                                        <div className="accordion-body" style={{ backgroundColor: '#f8f8f8' }}>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='info-details-box  row p-0 m-0 px-lg-2 px-md-2 '>
                                                    {Object.entries(ioiDetail).map(([key, value]) => (
                                                        <div key={key} className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                                            <div className='d-flex col-lg-12 flex-column m-0'>
                                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                                    {/* <p className='info-details-main-text m-0'>{key.replace(/_/g, ' ')}</p> */}
                                                                    <p className='info-details-main-text m-0'>{data.scrubJsonDataLabel.ioi[`${key}_LABEL`]}</p>
                                                                    <p className='info-details-sub-text m-0 bold-sub-text'>{value !== null ? value : 'N/A'}</p>
                                                                </div>
                                                                <div className='dividers p-0 m-0'></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    <div className='p-0 m-0 text-center pt-3'>
                        {data.data.ioi == null || data.data.ioi == undefined ||
                            data.data.ioi.length == 0 && (<p className='p-0 m-0'>No IOI data found!</p>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TabContent = ({ activeTab, data, togglePanel, openPanel }) => {
    const renderContent = () => {
        console.log(activeTab)
        switch (activeTab) {
            case 'Summary':
                return <SummaryContent data={data} openPanel={openPanel} togglePanel={togglePanel} />;
            case 'Inquiry':
                return <InquiryContent data={data} openPanel={openPanel} togglePanel={togglePanel} />;
            case 'Demographics':
                return <DemographicsContent data={data} openPanel={openPanel} togglePanel={togglePanel} />
            case 'Accounts':
                return <AccountContent data={data} openPanel={openPanel} togglePanel={togglePanel} />
            case 'Cross Sell Score':
                return <CrossSellContent data={data} openPanel={openPanel} togglePanel={togglePanel} />
            case 'IOI':
                return <IOIContent data={data} openPanel={openPanel} togglePanel={togglePanel} />
            default:
                return null; // Handle default case if needed
        }
    };

    return renderContent();
};

export default SubTabComponent;
