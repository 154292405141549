import httpClientBSAB2B from "../../config/bsa_b2b_action";

const getBankListBSA = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAB2B({
            method: 'GET',
            url: 'api/v1/bank/list',
            headers: { "x-reference-id": uuid }

        },).then(resp => {
            resolve(resp.data);
        })
            .catch(error => {
                reject(error);
            });
    });

const statementList = (uuid) =>
    new Promise((resolve, reject) => {
        //  console.log("statementUATList",);
        httpClientBSAB2B({
            method: 'GET',
            url: 'api/v1/statement/list',
            headers: { "x-reference-id": uuid }

        },)
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error);
            });
    });

const multipleUploadStatementBSA = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAB2B({
            method: 'POST',
            url: 'api/v1/uploadMultipleStatements',
            data: data, headers: {
                "x-business-user-id": uuid,
                "Content-Type": "multipart/form-data",
                "x-reference-id": uuid
            }
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const downloadExcel = (id, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAB2B({
            method: 'GET',
            url: 'api/v1/excel/summary/' + id,
            headers: {
                "x-reference-id": uuid,
                "Content-Type": "application/octet-stream",
            },
            responseType: 'blob',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });


const downloadAnaltics = (data, uuid) =>
    new Promise((resolve, reject) => {
        httpClientBSAB2B({
            method: 'POST',
            url: 'api/v1/download/fraud/analytics/pdf/report',
            data: data,
            headers: {
                "x-reference-id": uuid,
            },
            responseType: 'blob',
        })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

export {
    getBankListBSA,
    statementList,
    multipleUploadStatementBSA,
    downloadExcel,
    downloadAnaltics
}