import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../../../Common/helper";
const apiLogo = require("../../../images/assets/apiIcon.png");
const sdkLogo = require("../../../images/assets/sdkIcon.png");
const backArrow = require("../../../images/assets/backArrow.png");

function DashBoardPage() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {
      const data = getUserData()
      setUserData(data)
    }
  }, []);

  const { user } = useSelector((state) => {
    return state.authReducer;
  });

  return (
    <>
      <div className="pt-2 mt-sm-2 ps-4">
        <section className="" id="creditscore">
          <div className="container-lg container-fluid mt-3 my-sm-3">
            <div className="row align-items-start g-1 g-sm-2 mb-1 mb-3 px-sm-2 px-2 px-md-0 ">
              <div className="col-5 p-0 m-0 ">
                <div className="ps-sm-2">
                  <h1 className="username-text  mb-2 mb-sm-3">
                    Hi {userData?.name}!
                  </h1>
                  <p className="welcome-back-text mb-1">
                    Welcome to Boost.Money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container-lg container-fluid my-md-4">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-5 col-md-5 col-sm-12 bank-state-box mt-3">
              <h4 className="guide-title mb-4">
                <img src={apiLogo} /> <span className="ps-2">API{" "}</span>
              </h4>
              <p className="guidline-txt pb-5">
                Automated onboarding with easy documentation helps quick API
                integration. Integrated Credit Bureau and AA integration
                facilitates collective API simplifying integration that directly
                plugs into FI’s BRE.
              </p>

              <div className="guide-link text-end">
                <h4>
                  <a href="/integration#loginapi">API Guide</a>{" "}
                  <img src={backArrow} />
                </h4>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12 bank-state-box ms-md-3 ms-lg-3 mt-3">
              <h4 className="guide-title mb-3">
                <img src={sdkLogo} /> <span className="ps-2">SDK</span>
              </h4>
              <p className="guidline-txt pb-5">
                Mobile and responsive Web SDK available for direct plug and play
                for quick time to market. Optimized UX to help easy onboarding
                of end consumers with automated consent management as prescribed
                by the regulator.
              </p>
              <div className="guide-link text-end">
                <h4>
                  <a href="/integration#websdk">SDK Guide</a>{" "}
                  <img src={backArrow} />
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3"></div>

      </div>
    </>
  );
}

export default DashBoardPage;
