import '../../../App.css'
import '../Component/middle-cd.css'

const SECURE_IC = require("../../../images/assets/cred-assets/secure.png");
const MONITOR_IC = require("../../../images/assets/cred-assets/monitor.png");
const CRED_SCORE_IC = require("../../../images/assets/cred-assets/credit_score.png");

const MiddleContentCD = (props) => {

    const handleClick = () => {
        props.onChildClick();
    };

    return (<>
        <div className="container bg-content-center mt-0 ms-lg-2 me-lg-2 ms-3 me-3 mb-3">
            <p className="tips-txt pt-3">Check your Credit Score and Get Best Home Loan Offers</p>
            <div className='hr-white-line'></div>
            <div className="col-12 d-flex flex-wrap pt-4 ps-4 pe-4 pb-3 justify-content-between ">
                <div className='col-lg-4 col-md-3 col-sm-12 pt-2 sub-item justify-content-center'>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={MONITOR_IC} alt="sub-icon" className="sub-icon " />
                        <p className="sub-title text-center">
                            <span id="content1">Instant Credit Score</span>
                            <span id="content2"> Verification</span>
                        </p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-3 col-sm-12 pt-2 justify-content-center sub-item'>
                    <div className="d-flex flex-column justify-content-center align-items-center ">
                        <img src={SECURE_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title text-center">100% Safe & Secure</p>
                    </div>
                </div>

                <div className='col-lg-4 col-md-3 col-sm-12 pt-3 justify-content-center sub-item'>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={CRED_SCORE_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title text-center">
                            <span id="content1">No Impact on Credit</span>
                            <span id="content2">Score</span>

                        </p>
                    </div>
                </div>

            </div>
            <div className="py-4 mt-3">
                <div onClick={handleClick} id="callApiOpenSdk" className="button-header">{props.BtnLabel}</div>
            </div>
        </div>
    </>);
}

export default MiddleContentCD;