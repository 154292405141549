
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getConfiguration } from '../../redux/actions/dashboard_action';
import { setB2BAuthHeader } from '../../config/b2b';
import { b2bLogin } from '../../redux/actions/b2b_login';


export const getCommonUATClientTokenB2B = async (successCallback, errorCallback) => {
    try {
        const resp = await getConfiguration();

        if (resp) {
            localStorage.setItem('clientId', resp.clientId);
            const params = {
                clientId: resp.clientId,
                clientSecret: resp.clientSecret
            };

            // B2B Login for get B2B Access Token
            const b2bResp = await b2bLogin(params);

            if (b2bResp.access_token) {
                AsyncStorage.setItem('b2btoken', b2bResp.access_token);
                setB2BAuthHeader(b2bResp.access_token)
                successCallback(); // Invoke success callback
            }
        }
    } catch (error) {
        if (
            error?.response.data &&
            (error.response.data.errorCode === 'CLIENT_DETAILS_NOT_FOUND' ||
                error.response.data.errorCode === 'B2B_UPLOAD_CREDIT_REPORT_404' ||
                error.response.data.errorCode === 'B2B_GET_CLIENT_CREDENTIALS_404')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        } else if (
            error?.response.data &&
            (error.response.data.errorCode === 'B2B_LOGIN_400' ||
                error.response.data.errorCode === 'B2B_UPLOAD_CREDIT_REPORT_404' ||
                error.response.data.errorCode === 'B2B_LOGIN_500')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        }
    }
};