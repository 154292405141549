import '../../App.css';
import './demo.css'
import { useEffect, useState } from 'react';
import { credSDKApiCall } from '../../redux/actions/auth_action';
import Footer from '../landing-page/Footer/Footer';
import MiddleContentCD from '../landing-page/Component/middle-cd';
import Loader from '../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorMessage from '../Dashboard/error-message/error-message';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css'

const Logo = require("../../images/assets/Logo.png");
const BMLOGO = require("../../images/assets/bm.png");


function Demo(props) {

    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState();
    const [openSDKIframe, setOpenSDKIframe] = useState(false);
    const [b2bLink, setB2BLink] = useState('')
    const [callSDKB2B, setCallSDKB2B] = useState(false);
    const [dialogHeight, setDialogHeight] = useState("auto");
    const [getDownloadLink,setDownloadLink]= useState('');

    let sdkMessage ='';

    const navigate = useNavigate();

        useEffect(() => {
            window.addEventListener('message', fetchIframeData, false);
            function fetchIframeData(e) {
            try {
                e.preventDefault()
                if (typeof e.data !== "object") return
                if (!e.data.status) return
                if (!e.data.responseFrom) return
                if (!e.data.referenceId) return
                let iframeData = e.data && e.data.status ? e.data : null;
                if (iframeData) {
                    const status = iframeData.status;
                    const responseFrom = iframeData.responseFrom;
                    const referenceId = iframeData.referenceId;
                   // const businessUserId = iframeData.businessUserId;

                    if(responseFrom === "FIUTSP"){
                        var iframe = document.getElementById('tspiframe');
                        // Ensure the iframe is loaded before sending the message
                        iframe.contentWindow.postMessage(e.data, '*'); 
                        return;
                    }      

                    if(responseFrom === "B2BSDK"){
                        setTimeout(function () {
                            window.location.reload(1);
                         }, 5000);
                        if(status === "SUCCESS"){
                            sdkMessage = {success: 'Your request processed successfully.'}
                        }else if(status === "ERROR"){
                            sdkMessage = {error: 'We have encountered an error. Please try again. Reference Id :'+' '+ referenceId}  
                        }else if(status === "CANCEL"){
                            sdkMessage = {error: 'User cancelled. Reference Id :'+' '+ referenceId}   
                        }else if(status === "UNAUTHORIZED"){
                            sdkMessage = {error: 'Your token is expired. Please re initiate the request again. Reference Id :'+' '+ referenceId}   
                        }else{
                            sdkMessage = {error: 'Something went wrong!'}    
                        }  
                        setMessage(sdkMessage)
                        setOpenSDKIframe(false)
                       
                        return;
                    }
                }
            } catch (e) {
                console.log(e)
            }
            }
        }, [])

    const clickOnMiddleBtn = async() => {
        setOpenSDKIframe(true)
        await cdSdkCallApi();
        setCallSDKB2B(true)
    };

    const cdSdkCallApi = async () => {
        setLoader(true);
        setShowError(false)

        const url = new URL(window.location.href)
        const pathname = url.pathname;
    
        var jsonResponse = {
            statusCode: '',
            statusMessage: '',
            sdkUrl: ''
        };
        var param = {
            linkCode: "5678"
        }

        credSDKApiCall(param,pathname,true).then(resp => {
            setLoader(false);
            setShowError(false)
            if (resp.statusCode === "SUCCESS") {
                jsonResponse = {
                    statusCode: resp['statusCode'],
                    statusMessage: resp['statusMessage'],
                    sdkUrl: resp['sdkUrl']
                };
                
                let getToken = resp['sdkUrl'].split('?token=')
                let removeBuss = getToken[1].split("&")
                let sdkToken = removeBuss[0];
                let redirectUrl = "";
                if(pathname === '/demo'){
                    redirectUrl = 'https://enterprise.boost.money/download'
                    //redirectUrl = 'http://localhost:3000/download'
                }else{
                    redirectUrl = 'https://enterprise.boost.money/uat-download'
                    //redirectUrl = 'http://localhost:3000/uat-download'
                }
                var url = new URL(redirectUrl);
                url.searchParams.set('token', sdkToken);
                const isIframe = '&isIframe=true';
                const overrideSdkOnboardingOtpByPass = '&overrideSdkOnboardingOtpByPass=true';
                var urlData = jsonResponse.sdkUrl.split('?')
              //  var sdkUrl = 'http://localhost:3005/sdk/onboard?' + urlData[1] + isIframe;
                 var sdkUrl = jsonResponse.sdkUrl + isIframe;
                //const linkDownload = url.href;
                //console.log(linkDownload)
                //setDownloadLink(linkDownload)
                setB2BLink(sdkUrl)
                //window.open(jsonResponse.sdkUrl+'&redirectUrl='+url, '_blank');
            }
        }).catch(error => {
            setShowError(true)
            setLoader(false);
        })
    }

    const FipTspModel = (props) => {
        return <div className='tsp-iframe-popup text-center'>
          {/* <span id="close-icon" className={classOnLoadAA ? 'bi bi-x tsp-close-btn ' + classOnLoadAA : 'bi bi-x tsp-close-btn'} onClick={() => {
                closeTspPopup()
            }}></span> */}
          <iframe
            allowFullScreen
            height="650px" 
            src={props.src}
            width="100%"  
            id="tspiframe"
            allow='popup'
            sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
          />
        </div>
      }

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container-fluid mt-3'>
                    <div className='row g-3 mb-1 align-items-center ms-5'>
                        <div className='col-5'>
                        {/* <a className='navbar-brand logo-image logo-images-boxes' href='/'> */}
                                <img src={Logo} className='img-fluid' />
                        {/* </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="middle-content">
                <div className="profile-main pt-1">
                    <img src={BMLOGO} className='img-fluid profile-logo' />

                    <p className="pro-sub-line">Welcome</p>
                </div>
                <ErrorMessage message={message}/>
                <MiddleContentCD onChildClick={clickOnMiddleBtn} BtnLabel={"Start your journey"} />

                {openSDKIframe &&
                <div className='open-b2bsdk'>
                  <ReactDialogBox
                    headerBackgroundColor='white'
                    headerTextColor='white'
                    closeButtonColor='white'
                    bodyBackgroundColor='white'
                    bodyTextColor='white'
                    bodyHeight={dialogHeight}
                    borderRadius='0px'
                    headerHeight='0'
                  >
                    {callSDKB2B ?
                      <FipTspModel src={b2bLink} />
                      : null
                    }
                  </ReactDialogBox>
                </div>
              }

                {showError && <div id='result' className="mt-4 mb-3">
                    <div className="alerts danger" role="alert">
                        Something went wrong, please try again!
                        <span className="close" onClick={() => setShowError(false)}>×</span>
                    </div>
                </div>}

            </section >

            <div className='footer-box bottom-0 position-fixed'>
                <Footer />
            </div>
        </>
    );
}

export default Demo; 