import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import './bank-account-statements.css';
import { flushSync } from 'react-dom';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom'
import './accordition-upload-banks.css'
import '../../../App.css'
import AsyncSelect from 'react-select/async';
import MenuBar from '../Menubar/menubar';
import Sidebar from '../SideBar/sidebar';

import Loader from '../../loader/loader';
// import { uuid } from '../../Utils/uuid';
import { v4 as uuidv4 } from 'uuid';
import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import $ from 'jquery';
import { getBankListBSA, multipleUploadStatementBSA } from '../../../redux/actions/bsa_b2b_action';
import SuccessFailDialog from '../../Component/dialogs/success-fail-dialog';
import { Modal } from 'react-responsive-modal';
import { getGenetateBSAClientToken } from '../../Component/GenetateBSAClientToken';
import { getBankListProdBSA, multipleUploadStatementProdBSA } from '../../../redux/actions/bsa_b2b_prod_action';

// const Back = require("../../../images/assets/back.png")
const Close = require("../../../images/assets/close.png");

const IC_EYE = require("../../../images/assets/ic_eye.png")
const IC_EYE_OFF = require("../../../images/assets/ic_eye_close.png")
const IC_ARROW = require("../../../images/assets/ic-arrow.png")
const DELETE_IC = require("../../../images/assets/delete.png")


const UploadStatements = (props) => {
    const navigate = useNavigate();
    const [isFromProd, setFromProd] = useState(false);
    const [selectedBank, setSelectedBank] = useState();
    const [selectedFiles, setSelectedFiles] = useState();
    const [loader, setLoader] = useState(false);
    const [scoreError, setScoreError] = useState();
    const [showError, setShowError] = useState(false);
    const [bankList, setBankList] = useState();
    const [uploadError, setUploadError] = useState(false);
    const [uploadErrMsg, setUploadErrMsg] = useState();
    // const [passwordDt, setPasswordDt] = useState('');
    // const [passType, setPasswordType] = useState('password');
    // const [eyeIcon, setPasswordIcon] = useState(IC_EYE_OFF);
    // const [selectedAccountType, setSelectedAccountType] = useState('');
    // const [openMultipleScreen, setOpenMultipleScreen] = useState('');
    const [submitBtnEnable, setSubmitBtnEnable] = useState(false);
    /// accordionItems
    const [accordionItems, setAccordionItems] = useState([]);
    const [accordionErrors, setAccordionErrors] = useState({});
    const [fiuPartialMsg, setFiuPartialMsg] = useState({ msg: '', uuid: '' });
    const [openResSPFDialog, setOpenStatmentResSPFDialog] = useState(false);
    const [fiuMultiBankSPF, setFiuMultiBankSPF] = useState('');

    let businessUserId
    let startFlow = props?.startFlow

    useEffect(() => {
        const fetchData = async () => {
            ReactGA.event({
                category: "Component/Page_Render",
                action: "multi_bank_upload_component_render",
                label: "multi_bank_upload_component_render",
            });

            setLoader(true);

            let isProd = window.location.search === '?prod';
            setFromProd(isProd);

            if (accordionItems.length === 0) {
                addAccordionItem();
            }

            const uuid = uuidv4();
            const param = {
                "businessUserId": '9c0a1822-e012-4d4b-a191-48067f387dc9'
            };

            await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay

            if (isProd) {  // Use isProd instead of isFromProd (which updates asynchronously)
                fetchBankList(getGenetateBSAClientToken, getBankListProdBSA, param, uuid);
            } else {
                fetchBankList(getGenetateBSAClientToken, getBankListBSA, param, uuid);
            }
        };

        fetchData();  // Call the async function

    }, []);  // Empty dependency array to run only once

    const closeStatmentResSPFDialog = () => {
        resetFields();
        setOpenStatmentResSPFDialog(false)
    }

    const openStatmentResSPFDialog = () => {
        setOpenStatmentResSPFDialog(true)
    }


    const fetchBankList = async (getToken, getBankListFunction, param, uuid) => {
        let isProd = window.location.search === '?prod';
        setFromProd(isProd);
        await getToken(isProd, () => {

        }, errorMessage => {
            setLoader(false)
            console.error('Error:', errorMessage); // Handle error
        });

        await new Promise(resolve => setTimeout(resolve, 2000)); // 2-second delay


        getBankListFunction(param, uuid)
            .then(resp => {
                setLoader(false);
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_get_bank_list_prod' : "api_call_get_bank_list_sandbox",
                    label: props.openFromProd ? 'api_call_get_bank_list_prod_success' : "api_call_get_bank_list_sandbox_success",
                });
                if (resp.status === "SUCCESS") {
                    const asyncSelectOptions = resp.data.map(item => ({
                        value: item.bankCode,
                        label: item.bankName
                    }));

                    setBankList(asyncSelectOptions);

                }

            })
            .catch(error => {
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_get_bank_list_prod' : "api_call_get_bank_list_sandbox",
                    label: props.openFromProd ? 'api_call_get_bank_list_prod_fail' : "api_call_get_bank_list_sandbox_fail",
                });
                setLoader(false);
                console.error('Error:', error); // Handle error
            });

    };

    /// check validation for button enable/disable
    useEffect(() => {
        checkValidForButtonEnable();
        setAccordionErrors({});
    }, [accordionItems]); /// check validation for button enable/disable



    const addAccordionItem = () => {
        const newItem = {
            id: accordionItems.length + 1,
            selectedBank: '',
            selectedAccountType: '',
            selectedEmpDetail: '',
            selectedAccNumber: '',
            selectedODLimit: '',
            selectedFiles: [],
            passwordDt: '',
            showError: false,
            passType: 'password',
            eyeIcon: IC_EYE_OFF
        };
        /// HIDE ALL PANELS
        if (accordionItems.length !== 0) {
            $('.accordion__panel').attr('hidden', true);
        }
        setAccordionItems([...accordionItems, newItem]);
    };

    const deleteAccordionItem = (id) => {

        // Filter out the item with the specified ID
        const updatedAccordionItems = accordionItems.filter(item => item.id !== id);
        //  console.log(" updatedAccordionItems ", updatedAccordionItems)
        // Update the IDs of the remaining items
        const updatedItems = updatedAccordionItems.map((item, index) => ({
            ...item,
            id: index + 1 // Update the ID based on the new index
        }));

        //  console.log(" updatedItems ", updatedItems)
        setAccordionItems(updatedItems);
    };

    const loadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filterOptions = bankList.filter(option => (option.value.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
            callback(filterOptions);
        }, 1000)
    };


    const onChangeAccountType = (value) => {
        setUploadError(false)

        let valData = value ? value.value : "";
        // setSelectedAccountType(valData)
    }

    const skipBtn = (event) => {
        props.closeMultiBankStmDialog()
    }

    const backFiuErrorScreen = () => {
        props.setFiuErrorScreen(true);
        props.setFiuErrorModel(true);
        props.setIsFiuOpenDialog(false);
        props.setFipTspModel(false);
    }

    const updateAccordionItemData = (id, newData) => {
        setAccordionItems(prevItems => {
            return prevItems.map(item => {
                if (item.id === id) {
                    return { ...item, ...newData };
                }
                return item;
            });
        });
    };

    const checkValidForButtonEnable = () => {
        let isValid = true;
        accordionItems.forEach(item => {
            if (item.selectedBank === undefined || item.selectedBank === ''
                || item.selectedAccountType === undefined || item.selectedAccountType === ''
                || item.selectedFiles.length === 0) {
                isValid = false;
            }
        });
        setSubmitBtnEnable(isValid);
    }

    const sumbitBankStatement = () => {
        setUploadErrMsg(false)
        let isValid = true;
        const errors = {};

        accordionItems.forEach(item => {
            if (!item.selectedBank || item.selectedBank === '') {
                errors[item.id] = {
                    selectedBank: 'Please select a bank'
                };
                isValid = false;
            }
            if (!item.selectedAccountType || item.selectedAccountType === '') {
                errors[item.id] = { ...errors[item.id], selectedAccountType: 'Please select account type' };
                isValid = false;
            }
            if (!item.selectedFiles || item.selectedFiles.length === 0) {
                errors[item.id] = { ...errors[item.id], selectedFiles: 'Please select file(s)' };
                isValid = false;
            }
            // Set the errors state
            setAccordionErrors(errors);
        });
        if (isValid) {

            var param = new FormData();
            const uuid = uuidv4();
            // param.append("business-user-id", uuid);
            //param.append("isFromSDK", true);
            // console.log("Accordion items:", accordionItems);
            for (let i = 0; i < accordionItems.length; i++) {
                const item = accordionItems[i];

                param.append("statement[" + i + "].bank", item.selectedBank);
                param.append("statement[" + i + "].accountType", item.selectedAccountType);
                param.append("statement[" + i + "].password", item.passwordDt);
                param.append("statement[" + i + "].employerDetails", item.selectedEmpDetail);
                param.append("statement[" + i + "].accNo", item.selectedAccNumber);

                let filesArray = Array.from(item.selectedFiles);
                filesArray.forEach((file, fileIndex) => {
                    param.append(`statement[${i}].bankStmt[${fileIndex}]`, file);
                });
            }


            setLoader(true)

            const sumbitBankStatement = isFromProd ? multipleUploadStatementProdBSA(param, uuid) : multipleUploadStatementBSA(param, uuid);
            // const sumbitBankStatement = props.openFromProd ? multipleUploadStatementProd(param, uuid) : multipleUploadStatement(param, uuid);
            sumbitBankStatement.then(resp => {

                setLoader(false);
                // props.closeBankStmDialog()
                // props.getTestUserList()

                if (resp.statusCode === "SUCCESS" || resp.statusCode === "PARTIAL_SUCCESS") {
                    ReactGA.event({
                        category: "API_CALL",
                        action: props.openFromProd ? 'api_call_submit_bank_multi_prod' : "api_call_submit_bank_multi_sandbox",
                        label: props.openFromProd ? 'api_call_submit_bank_multi_prod_success' : "api_call_submit_bank_multi_sandbox_success",
                    });
                } else {
                    ReactGA.event({
                        category: "API_CALL",
                        action: props.openFromProd ? 'api_call_submit_bank_multi_prod' : "api_call_submit_bank_multi_sandbox",
                        label: props.openFromProd ? 'api_call_submit_bank_multi_prod_fail' : "api_call_submit_bank_multi_sandbox_fail",
                    });
                }


                if (resp.status === "SUCCESS") {
                    setFiuMultiBankSPF("SUCCESS");
                } else if (resp.status === "PROCESSING_ASYNC") {
                    setFiuMultiBankSPF("PROCESSING_ASYNC");
                } else if (resp.status === "ERROR") {
                    setFiuMultiBankSPF("ERROR");
                }
                //  
                setFiuPartialMsg({ msg: resp.message, uuid: uuid })
                openStatmentResSPFDialog();

            }).catch((error) => {
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_submit_bank_multi_prod' : "api_call_submit_bank_multi_sandbox",
                    label: props.openFromProd ? 'api_call_submit_bank_multi_prod_fail' : "api_call_submit_bank_multi_sandbox_fail",
                });
                setLoader(false)
                if (error?.errorCode === "B2B_STATEMENT_UPLOAD_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_STATEMENT_UPLOAD_500") {
                    setUploadErrMsg(true)
                    setUploadErrMsg(error.errorMessage);
                }
            })
        }


    };

    const resetFields = () => {
        setAccordionItems([]); // Clear all items

        setTimeout(() => {
            setAccordionItems([
                {
                    id: 1,
                    selectedBank: '',
                    selectedAccountType: '',
                    selectedEmpDetail: '',
                    selectedAccNumber: '',
                    selectedODLimit: '',
                    selectedFiles: [],
                    passwordDt: '',
                    showError: false,
                    passType: 'password',
                    eyeIcon: IC_EYE_OFF
                }
            ]);
        }, 100); // Small delay to ensure state is cleared
    };

    const closeSDK = () => {
        var url = new URL(window.location.href)
        var searchParams = url.searchParams
        var businessUserId = searchParams.get('businessUserId')
        var redirectUrl = searchParams.get('redirectUrl')
        if (redirectUrl) {
            window.open(redirectUrl + '?status=CANCEL&businessUserId=' + businessUserId + '&referenceId=' + uuidv4, '_blank')
        }
    }
    const btnEnableDisable = {
        opacity: submitBtnEnable ? 1.0 : 0.5
    };

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    {loader && <Loader />}

                    <div className='content bsa-upload'>
                        <div className='mt-4 mt-sm-2'>
                            <section className='' id='creditscore'>
                                <div className='container-lg container-fluid mt-3 my-sm-3'>
                                    {/* <div className='row align-items-start g-1 g-sm-2 my-2 mb-0 px-sm-2 px-2 px-md-0 '>
                                        <div className='col-5'>
                                            <div className='ps-sm-2'>
                                                <h1 className='username-text mb-2 mb-sm-3'>Upload Bank Statements</h1>
                                            </div>
                                        </div>

                                        <div className='col-5'>
                                            <div className='ps-sm-2'>
                                                <h1 className='username-text mb-2 mb-sm-3'>Upload Bank Statements</h1>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='row g-1 g-sm-2 my-2 mb-0 px-sm-2 px-2 px-md-0 d-flex
                                     flex-column flex-md-row justify-content-md-between align-items-center'>
                                        <div className='col-12 col-md-auto'>
                                            <div className='text-center text-md-start'>
                                                <h1 className='p-0 m-0 username-text'>Upload Bank Statements</h1>
                                            </div>
                                        </div>

                                        <div className='col-12 col-md-auto p-0 m-0'>
                                            <div className='ps-sm-2 text-center text-md-end'>
                                                <button className="add-user-btn mx-2" type="button"
                                                    onClick={() => {
                                                        if (isFromProd) {
                                                            navigate('/bank-account-list?prod')
                                                        } else {
                                                            navigate('/bank-account-list?uat')
                                                        }

                                                    }}>Bank Statements List</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div >



                        <div className='mx-2'>

                            <form className="row g-2 needs-validation" noValidate >
                                <div className='accordition-content accordition-content-big'>
                                    <Accordion
                                        allowZeroExpanded={true}
                                        className=''>
                                        {accordionItems.map(item => (
                                            <AccordionItems
                                                key={item.id}
                                                item={item}
                                                deleteItem={() => deleteAccordionItem(item.id)}
                                                bankList={bankList}
                                                loadOptions={loadOptions}
                                                updateItemData={updateAccordionItemData}
                                                errors={accordionErrors[item.id]}
                                                uuid={item.id}
                                                accordionItems={accordionItems}
                                            // onSubmit={(data) => onSubmit(data)}

                                            />
                                        ))}
                                    </Accordion>
                                </div>

                                {uploadError && <span className="demo-tetx mt-2">{uploadErrMsg}</span>}
                                {uuidv4 && (uploadError || scoreError) && <p className='reference-info'>Reference ID : {uuidv4}</p>}

                                <div className='d-lg-flex flex-lg-wrap justify-content-lg-between align-items-lg-center
                                d-md-flex flex-md-wrap justify-content-md-between align-items-md-center'>
                                    <div className='p-0 m-0'>
                                        <button className="add-user-btn mx-2" type="button" onClick={() => {
                                            addAccordionItem();
                                        }}>+ Add more accounts</button>
                                    </div>

                                    <div className='d-flex flex-wrap justify-content-between align-items-center'>
                                        <div className='p-3'>
                                            <button className="upload-statement-button mx-2" style={btnEnableDisable} type="button" onClick={() => {
                                                sumbitBankStatement()
                                            }}>Submit</button>
                                        </div>
                                        <div className=''>
                                            <button className="reset-bank-btn" type="button" onClick={() => {
                                                resetFields();
                                            }}>Reset</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>


                        {openResSPFDialog && <Modal open={true} center classNames={{
                            modal: 'spf-modal',
                        }}>
                            <SuccessFailDialog
                                fiuMultiBankSPF={fiuMultiBankSPF}
                                fiuPartialMsg={fiuPartialMsg}
                                closeStatmentResSPFDialog={closeStatmentResSPFDialog}>
                            </SuccessFailDialog>
                        </Modal>}
                    </div>


                </div>
            </div>

        </>
    )

}

const AccordionItems = ({ item, deleteItem, bankList, loadOptions, updateItemData, errors, accordionItems }) => {

    const { id, selectedBank, selectedAccountType, selectedFiles, passwordDt, showError,
        selectedEmpDetail, selectedAccNumber, selectedODLimit
    } = item;
    const [localSelectedBank, setLocalSelectedBank] = useState(selectedBank);
    const [localSelectedAccountType, setLocalSelectedAccountType] = useState(selectedAccountType);
    const [localSelectedFiles, setLocalSelectedFiles] = useState(selectedFiles);
    const [localEmpDetail, setEmpDetail] = useState(selectedEmpDetail);
    const [localAccNo, setAccNumber] = useState(selectedAccNumber);
    const [localODLimit, setODLimit] = useState(selectedODLimit);
    const [localPasswordDt, setLocalPasswordDt] = useState(passwordDt);
    const [passType, setPasswordType] = useState('password');
    const [eyeIcon, setPasswordIcon] = useState(IC_EYE_OFF);
    const accountTypes = [
        { value: 'SAVING', label: 'Saving' },
        { value: 'CURRENT', label: 'Current' }
    ];
    const [dragging, setDragging] = useState(false);



    const handleSubmit = () => {
        const newData = {
            selectedBank: localSelectedBank == null ? '' : localSelectedBank.value,
            selectedAccountType: localSelectedAccountType == null || localSelectedAccountType === undefined
                ? '' : localSelectedAccountType.value,
            selectedFiles: localSelectedFiles ?? [],
            passwordDt: localPasswordDt ?? '',
            showError: false,
            selectedEmpDetail: localEmpDetail ?? '',
            selectedAccNumber: localAccNo ?? '',
            selectedODLimit: localODLimit ?? '',
            passType: 'password',
            eyeIcon: IC_EYE_OFF
        };


        updateItemData(item.id, newData);
    }; // Added dependencies

    const localState = useMemo(() => ({
        localSelectedBank: item.selectedBank,
        localSelectedAccountType: item.selectedAccountType,
        localSelectedFiles: item.selectedFiles,
        localEmpDetail: item.selectedEmpDetail,
        localAccNo: item.selectedAccNumber,
        localODLimit: item.selectedODLimit,
        localPasswordDt: item.passwordDt,
    }), [item]);

    useEffect(() => {
        handleSubmit();
    }, [localSelectedBank,
        localSelectedAccountType,
        localSelectedFiles,
        localPasswordDt,
        localODLimit,
        localAccNo,
        localEmpDetail]);



    const onBankChange = (selectedOption) => {
        //   console.log("selectedOption ", selectedOption);
        setLocalSelectedBank(selectedOption);
    };

    const onChangeAccountType = (selectedOption) => {
        setLocalSelectedAccountType(selectedOption);
    };

    const onFileSelect = (files) => {
        // if (event.target.files.length > 0) {
        // }
        setLocalSelectedFiles(files);
    };

    const clearSelectedFiles = (ids) => {
        var fileInput = document.getElementById(`file-${ids}`);
        fileInput.value = ''
        setLocalSelectedFiles([]);

    };

    const handleTogglePassword = () => {
        const newPassType = passType === 'password' ? 'text' : 'password';
        setPasswordIcon(passType === 'password' ? IC_EYE : IC_EYE_OFF);
        setPasswordType(newPassType);

    };

    const handleAccordionClick = (id, expanded) => {
        var $icArrow = $(`#arrow-${id}`);
        if (expanded) {
            if ($icArrow.length > 0) {
                $icArrow.addClass('rotate-ic-min90')
            }
            const $panel = $(`#accordionItem-${id}`);
            if ($panel.length > 0) {
                const offset = $panel.offset();
                if (offset) {
                    const scrollTopValue = offset.top - $('.accordition-content').offset().top + $('.accordition-content').scrollTop();
                    $('.accordition-content').animate({
                        scrollTop: scrollTopValue
                    }, 500);
                } else {
                    console.log("Offset is undefined.");
                }
            } else {
                // console.log("Panel element not found.");
            }

        } else {
            if ($icArrow.length > 0) {
                $icArrow.removeClass('rotate-ic-min90')
            }
        }
    };

    const fileInputRef = useRef(null);

    const handleChooseFilesClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            onFileSelect(files);
        }
    };

    const handleRemoveFile = (id) => {
        const filesArray = Array.from(localSelectedFiles);
        if (id >= 0 && id < filesArray.length) {
            filesArray.splice(id, 1); // Remove the file at the specified index
        }
        setLocalSelectedFiles(filesArray);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        // console.log(event.dataTransfer.files)
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            onFileSelect(files);
        }
    };

    return (
        <AccordionItem key={item.id} dangerouslySetExpanded={accordionItems.length === 1 ? true : null}
            uuid={`${item.id}`} className='pb-2' id={`accordionItem-${id}`}>
            <div className='accordion__button__items '>
                <AccordionItemButton
                    className={errors ? 'accordion__button d-flex flex-wrap flex-row justify-content-between error-border' :
                        'accordion__button d-flex flex-wrap flex-row justify-content-between label-label-up'}>
                    Account {id}
                    <AccordionItemState >
                        {({ expanded }) => {
                            handleAccordionClick(id, expanded)
                            // Ensure you return null or a JSX element here
                        }}
                    </AccordionItemState>

                    {id !== 1 &&
                        <div className=''>
                            <img onClick={(e) => {
                                e.preventDefault();
                                deleteItem(id)
                            }}
                                src={DELETE_IC}
                                alt='ic_arrow' className='accordion__del_ic m-0 p-0' />
                        </div>
                    }
                </AccordionItemButton>


            </div>

            <AccordionItemPanel>

                <div className='col-12 d-flex flex-wrap'>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12  ps-1 pe-lg-1 mt-2">
                        <div htmlFor="validationCustom04" className="label-label-up mb-2"
                        >Bank Name</div>
                        <AsyncSelect loadOptions={loadOptions}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    minHeight: "32px", // Reduce height
                                    height: "48px", // Ensure it applies
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px 5px",
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px",
                                }),
                            }}
                            onChange={onBankChange}
                            className={{}}
                            defaultOptions={bankList}
                            defaultValue={localState.localSelectedBank}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select your bank" />
                        {errors && errors.selectedBank && <span className="demo-tetx">{errors.selectedBank}</span>}
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 ps-1 pe-lg-1 mt-2">
                        <div htmlFor="validationCustom04" className="label-label-up mb-2">Account Type</div>
                        <AsyncSelect options={accountTypes}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    minHeight: "32px", // Reduce height
                                    height: "48px", // Ensure it applies
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px 5px",
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    fontFamily: 'Poppins',
                                    margin: "0px", // Prevent extra height
                                    padding: "0px",
                                }),
                            }}
                            defaultValue={localState.localSelectedAccountType ?? ''}
                            onChange={onChangeAccountType}
                            isSearchable={false}
                            defaultOptions={accountTypes}
                            isClearable={true}
                            placeholder="Select account type" />
                        {errors && <span className="demo-tetx">{errors.selectedAccountType}</span>}
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 ps-1 pe-lg-1 mt-2">
                        <div className="label-label-up mb-2">File Password</div>
                        <div style={{ position: 'relative' }}>
                            <input
                                placeholder='Enter file password (Optional)'
                                id={`passType-${id}-${uuidv4()}`} type={passType}
                                value={localState.localPasswordDt || ''} onChange={(e) => {
                                    setLocalPasswordDt(e.target.value)
                                }}
                                className='input-box form-control'

                            />
                            <span>
                                <img
                                    onClick={handleTogglePassword}
                                    src={eyeIcon}

                                    className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1"
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                    }}
                                />
                            </span>

                        </div>
                        {/* <div className="">
                           
                            <div className='d-flex'>
                                <input id={`passType-${id}`} type={passType} className='input-box col-11 form-control'
                                    placeholder='Enter file password (Optional)' value={localPasswordDt || ''} onChange={(e) => {
                                        setLocalPasswordDt(e.target.value)
                                    }} />
                                <div className='d-flex flex-row align-items-center'>
                                    <img src={eyeIcon} className="eye-icon-pass" onClick={handleTogglePassword} />
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>

                <div className='col-12 d-flex flex-wrap mt-2'>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 ps-1 pe-lg-1 mt-2">
                        <div className="label-label-up mb-2">Employer Details</div>
                        <div className="">
                            <div className='d-flex'>
                                <input id={`passType-${id}-${uuidv4()}`} type='text' className='input-box col-11  form-control'
                                    placeholder='Enter Employer Details (Optional)' value={localState.localEmpDetail || ''}
                                    onChange={(e) => {
                                        setEmpDetail(e.target.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 ps-1 pe-lg-1 mt-2">
                        <div className="label-label-up mb-2">OD Limit</div>
                        <div className="">
                            <div className='d-flex'>
                                <input id={`passType-${id}-${uuidv4()}`} type='number' className='input-box col-11 form-control'
                                    placeholder='OD Limit (Optional)' value={localState.localODLimit || ''}
                                    onChange={(e) => {
                                        setODLimit(e.target.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 ps-1 pe-lg-1 mt-2">
                        <div className="label-label-up mb-2">Account Number</div>
                        <div className="">
                            <div className='d-flex'>
                                <input id={`passType-${id}-${uuidv4()}`} type='number' className='input-box col-11 form-control'
                                    placeholder='Enter Account Number (Optional)' value={localState.localAccNo || ''}
                                    onChange={(e) => {
                                        setAccNumber(e.target.value);
                                    }} />

                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-12 mt-2">
                    <div className="label-label-up ps-1 mb-2">Select File</div>
                    <div className={`drag-root-area ${dragging ? 'drag-over' : ''}`}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>

                        <div className='file-area ' onClick={handleChooseFilesClick}>
                            {localState.localSelectedFiles.length > 0 &&
                                <div className="file-list" >
                                    {Array.from(localState.localSelectedFiles).map((localSelectedFiles, idx) =>
                                        <div className='d-flex flex-row file-item-upload' key={idx}>
                                            <p className='m-0 p-0'>{localSelectedFiles.name}</p>
                                            <img src={Close} className="close-file-ic ms-2"
                                                onClick={() => handleRemoveFile(idx)}>
                                            </img>
                                        </div>
                                    )}
                                </div>}
                            <div className='custom-file-input'>
                                <input
                                    id={`file-${id}-${uuidv4}`}
                                    type="file"
                                    multiple
                                    className="m-0 p-0"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    style={{ display: 'none' }}
                                />
                                <div className='d-flex flex-row justify-content-center position-sticky'>
                                    <p className="blue-light-text btn-upload-statements p-0 m-0"

                                    > Choose files</p>
                                    <p className='btn-upload-statements ps-2'>or drag it here</p>
                                </div>

                            </div>
                        </div>

                    </div>

                    {errors && errors.selectedFiles && <span className="demo-tetx mt-2">{errors.selectedFiles}</span>}
                </div>

                {/* {uploadError && <span className="demo-tetx mt-1">{uploadErrMsg}</span>} */}

                {/* {id != 1 && <div className="d-flex flex-wrap pt-2 pb-2 justify-content-center ">
                    <p className="blue-light-text btn-upload-statements"
                        onClick={() => deleteItem(id)}>Delete account
                    </p>
                </div>} */}
            </AccordionItemPanel>
        </AccordionItem>
    );


}

export default UploadStatements;