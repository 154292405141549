import React, { useEffect, useState } from 'react';
const IC_INFO = require("../../../images/assets/info-ic.png");
const GreenIC = require("../../../images/assets/green-ic.png");

const PasswordValidation = ({ requirements }) => {
    const [strengthStatus, setStrengthStatus] = useState(''); // Track strength status

    // Function to calculate password strength status
    const calculateStrength = () => {
        const totalRequirements = Object.values(requirements).filter(Boolean).length;
        const strengthPercentage = (totalRequirements / 6) * 100;

        if (strengthPercentage === 100) {
            setStrengthStatus('Strong');
        } else if (strengthPercentage >= 60) {
            setStrengthStatus('Average');
        } else {
            setStrengthStatus('Poor');
        }
    };

    // Recalculate password strength whenever the 'requirements' change
    useEffect(() => {
        calculateStrength();
    }, [requirements]); // Runs when 'requirements' state changes

    // Determine the strength color based on the status
    const getStatusColor = () => {
        if (strengthStatus === 'Strong') return 'green';
        if (strengthStatus === 'Average') return 'orange';
        return 'red';
    };

    // Check if all requirements are fulfilled
    const allRequirementsMet = Object.values(requirements).every(Boolean);

    // Only show feedback if not all requirements are met
    if (allRequirementsMet) return null;

    return (
        <div style={{ marginTop: '8px', color: 'gray' }}>
            <p className='p-0 m-0'  >
                Password strength: <span style={{ marginTop: '4px', color: getStatusColor() }}>
                    {strengthStatus}</span>
            </p>
            <div className='text-start justify-content-center p-0 m-0'>
                <div style={{ color: requirements.length ? 'black' : '#969BA3' }}>
                    {requirements.length ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />} <span className='p-0 m-0 ms-1'> 8-20 characters long</span>
                </div>
                <div className='' style={{ color: requirements.uppercase ? 'black' : '#969BA3' }}>
                    {requirements.uppercase ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />} <span className='p-0 m-0 ms-1'>One uppercase character</span>
                </div>
                <div style={{ color: requirements.lowercase ? 'black' : '#969BA3' }}>
                    {requirements.lowercase ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />} <span className='p-0 m-0 ms-1'>One lowercase character</span>
                </div>
                <div style={{ color: requirements.specialChar ? 'black' : '#969BA3' }}>
                    {requirements.specialChar ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />}<span className='p-0 m-0 ms-1'> One special character</span>
                </div>
                <div style={{ color: requirements.number ? 'black' : '#969BA3' }}>
                    {requirements.number ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />}<span className='p-0 m-0 ms-1'> One number</span>
                </div>
                <div style={{ color: requirements.noSpaces ? 'black' : '#969BA3' }}>
                    {requirements.noSpaces ? <img src={GreenIC} className='green-tick-ic' /> : <img src={IC_INFO}
                        className='info-ic' />} <span className='p-0 m-0 ms-1'> No blank spaces</span>
                </div>
                {/* Add any additional requirement checks here */}
            </div>
        </div>
    );
};

export default PasswordValidation;
