
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getConfiguration } from '../../redux/actions/dashboard_action';
import { bsaLogin } from '../../redux/actions/bsa_login';
import { setBSAAuthHeader } from '../../config/bsa_b2b_action';
import { setBSAAuthProdHeader } from '../../config/bsa_b2b_prod_action';
import { getProdConfiguration } from '../../redux/actions/prod_action';


export const getGenetateBSAClientToken = async (isFromProd, successCallback, errorCallback) => {
    try {
        const resp = isFromProd ? await getProdConfiguration() : await getConfiguration();
       // console.log("resp ", resp)
        if (resp) {
            localStorage.setItem('clientId', resp.clientId);
            const params = {
                clientId: resp.clientId,
                clientSecret: resp.clientSecret
            };

            // BSA Login for get  Access Token
            const bsaResp = await bsaLogin(params, isFromProd);

            if (bsaResp.access_token) {
                if (isFromProd) {
                    AsyncStorage.setItem('bsaProdtoken', bsaResp.access_token);
                    setBSAAuthProdHeader(bsaResp.access_token)
                } else {
                    AsyncStorage.setItem('bsatoken', bsaResp.access_token);
                    setBSAAuthHeader(bsaResp.access_token)
                }
            }
            successCallback(); // Invoke success callback
        }
    } catch (error) {
        if (
            error?.response.data &&
            (error.response.data.errorCode === 'CLIENT_DETAILS_NOT_FOUND' ||
                error.response.data.errorCode === 'B2B_UPLOAD_CREDIT_REPORT_404' ||
                error.response.data.errorCode === 'B2B_GET_CLIENT_CREDENTIALS_404')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        } else if (
            error?.response.data &&
            (error.response.data.errorCode === 'B2B_LOGIN_400' ||
                error.response.data.errorCode === 'B2B_UPLOAD_CREDIT_REPORT_404' ||
                error.response.data.errorCode === 'B2B_LOGIN_500')
        ) {
            errorCallback(error.response.data.errorMessage); // Invoke error callback
        } else {
            errorCallback();
        }

    }
};