import React from 'react';

const SvgCopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.5001 6.99996C14.9883 7.48812 14.9883 8.27959 14.5001 8.76769L9.884 13.3839C9.3958 13.872 8.6044 13.872 8.1162 13.3839L6.0001 11.2677C5.93908 11.2067 5.88568 11.141 5.83992 11.0717C5.51957 10.5865 5.57296 9.92709 6.0001 9.49999C6.48825 9.01179 7.27971 9.01179 7.76786 9.49999L9.0001 10.7322L12.7323 6.99996C13.2205 6.51181 14.0119 6.51181 14.5001 6.99996Z" fill="#4243F1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85787 17.5 2.5 14.1421 2.5 10C2.5 5.85787 5.85787 2.5 10 2.5C14.1421 2.5 17.5 5.85787 17.5 10Z" fill="#4243F1" />
        </svg>
    );
};

export default SvgCopyIcon;