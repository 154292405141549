import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import '../credit-report/credit-report.css'
import '../../../App.css'
import 'react-dropdown/style.css'
import Loader from '../../loader/loader'
import { createCustomer } from '../../../redux/actions/b2b_action';
import { setB2BAuthHeaderProd } from '../../../config/prod-b2b';
import { createCustomerProd } from '../../../redux/actions/prod-b2b_action';

const CreateCustomer = (props) => {
    const [loader, setLoader] = useState(false);
    const [custName, setCustName] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (props.openFromProd) {
            setB2BAuthHeaderProd("");
            setLoader(false);
        }
        ReactGA.event({
            category: "Component/Page_Render",
            action: "create_customer_page_render",
            label: `create_customer_page_render`,
        });

    }, []);


    const submitCustomer = () => {

        setShowError(false)
        if (!custName || custName === '') {
            setShowError(true)
        } else {
            const custparam = {
                name: custName
            }
            // Create Test User
            createCustomerApi(custparam)

        }
    }
    const createCustomerApi = async (custparam) => {
        try {
            const createCustomerFunction = props.openFromProd ? createCustomerProd : createCustomer;
            const resp = await createCustomerFunction(custparam);

            if (resp.statusCode === "SUCCESS") {
                ReactGA.event({
                    category: "API_CALL",
                    action: props.openFromProd ? 'api_call_create_customer_prod' : "api_call_create_customer_sandbox",
                    label: props.openFromProd ? 'api_call_create_customer_prod_success' : "api_call_create_customer_sandbox_success",
                });
                props.closeTestUserDialog();
                // props.setOpenGenReqURLDialog({
                //     isOpen: true,
                //     showUploadDocs: true,
                //     dataRow: {
                //         "businessUserId": resp.businessUserId,
                //         "firstName": null,
                //         "lastName": null,
                //         "mobileNumber": null,
                //         "email": null,
                //     }
                // });
                props.setBusinessId(resp.businessUserId);
                props.setIsOpenDialog(true);
                // props.getTestUserList();
                props.setMessage({ success: resp.statusMessage });
                // props.scrollToTop();
            }
        } catch (error) {
            ReactGA.event({
                category: "API_CALL",
                action: props.openFromProd ? 'api_call_create_customer_prod' : "api_call_create_customer_sandbox",
                label: props.openFromProd ? 'api_call_create_customer_prod_fail' : "api_call_create_customer_sandbox_fail",
            });
            if (error?.response?.data?.errorCode === "B2B_CREATE_TEST_USER_409" || error?.response?.data?.errorCode === "B2B_CREATE_TEST_USER_404") {
                setErrorMessage(error.response.data.errorMessage);
            }
        }
    };
    return (
        <>
            {loader && <Loader />}
            <div className='container-box'>
                <form className="row g-2 needs-validation" noValidate>
                    <div className='col-12'>
                        <div className='label-label mb-2'>Enter Name</div>
                        <div className='password-box'>
                            <input
                                name="name"
                                placeholder="Enter Customer Name"
                                type="text"
                                value={custName}
                                onChange={e => setCustName(e.target.value)}
                                className='form-control input-box'
                            />
                        </div>
                        {(showError && (custName === '')) && <span className="demo-tetx">Please enter customer name</span>}
                        {(errorMessage) && <span className="demo-tetx">{errorMessage}</span>}

                    </div>
                    <div className="col-12 text-center">
                        <button className="upload-statement-butoon mt-10" type="button" onClick={() => {
                            submitCustomer()
                        }} >Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateCustomer;