
import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MenuBar from "../Menubar/menubar";
import Sidebar from "../SideBar/sidebar";
import Footer from "../../landing-page/Footer/Footer";
import { getProfileData } from "../../../redux/actions/dashboard_action";
import ProfileHeaderBanner from "./profile_component/profile_header";
import ProfileDropdown from "./profile_component/profile_dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./profile.css";
import { Link } from "react-router-dom";

const Avatar_logo = require("../../../images/avatar-profile.png");

const Profile = () => {
  const [fullName, setFullName] = useState()
  const [email, setEmail] = useState()
  const [businessName, setBusinessName] = useState()

  useEffect(() => {

    setFullName(localStorage.getItem("fullName"))
    setEmail(localStorage.getItem("email"))
    setBusinessName(localStorage.getItem("businessName"))

    // getProfileData()
    // .then((resp) => {
    //    if(resp.statusCode === "SUCCESS") {
    //     setFullName(resp.fullName)
    //     setEmail(resp.email)
    //     setBusinessName(resp.businessName)
    //    }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });       
  })

  return (
    <>
      <MenuBar />
      <div className="sidebar-box sidnav-box">
        <Sidebar></Sidebar>

        <div className="sidebat-inner-box sidnav-inner-box">
          <div id="main_section" className="content ">
            <ProfileHeaderBanner name={fullName} businessName={businessName}></ProfileHeaderBanner>
            <section className="profile-sub-section">
              <p className="profile-sub-section-label">Account Details</p>
              <div className="profile-sub-section-divider"></div>
            </section>
            <section className="profile-text-form col-12 col-sm-12 col-md-6 col-lg-5">
              <p className="profile-text-field-label">Full Name</p>
              <input
                type="text"
                className="profile-text-field-layout"
                placeholder={fullName}
              ></input>

              <p className="profile-text-field-label">Email</p>
              <input
                type="text"
                className="profile-text-field-layout"
                placeholder={email}
              ></input>

              <p className="profile-text-field-label">Business Name</p>
              <input
                type="text"
                className="profile-text-field-layout"
                placeholder={businessName}
              ></input>

              <p className="profile-text-field-label">Password</p>
              <Link className="gradient-text mt-1" to="/change-password">Change Password</Link>
              {/* <div className="profile-passowrd-field-layout col-12 col-sm-12 col-md-6 col-lg-5">
              <div className="col-12 row m-0 p-0">
                <input
                  type="password"
                  className="password-input ps-3 col-8"
                  placeholder="*****"
                ></input>
                <p className="gradient-text">Change Password</p>
              </div>
            </div> */}
            </section>
            <section className="space"></section>
          </div>
          {/* <Footer /> */}
        </div>
      </div>




    </>
  );
};
export default Profile;
