import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import '../../../App.css';
import '../../Dashboard/dashboard.css';
import ScoreCards from '../Header-Page/Scroe-card';
import MenuBar from '../Menubar/menubar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from '../SideBar/sidebar';
import Footer from '../../landing-page/Footer/Footer';
import ProdUsersList from './prod-component/prod-user-list';


function ProdUsers() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        }
    }, [])

    const scrollToTop = () => {
        var d = document.getElementById("boostScore");
        d.scrollTo({ top: 0, behavior: 'smooth' })
    };

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    <div className='content'>
                        <ProdUsersList />
                        <ToastContainer />
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
}

export default ProdUsers;