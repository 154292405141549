import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../App.css';
import { authCode, emailVerification } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import './email.css'
import Footer from '../../landing-page/Footer/Footer'

const Logo = require("../../../images/assets/Logo.png");



function Email() {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const navigate = useNavigate();

    useEffect(() => {

        if (window.location.search && window.location.search !== "" && window.location.search.includes("state")) {
            if (!isVerified) {
                setIsVerified(true)
                var data = window.location.search.split("&");
                var state = data[0].split("=")[1];
                var code = data[1].split("=")[1];
                var param = {
                    "oauthCode": code
                }
                setLoader(true);
                authCode(param).then(resp => {
                    setLoader(false);
                    if (resp.statusCode === "ERROR") {
                        navigate('/users', { state: { "error": resp.statusMessage } });
                    } else {
                        navigate('/users', { state: { "success": resp.statusMessage } })
                    }
                }).catch(error => {
                    setLoader(false);
                    if (error.statusCode === "ERROR") {
                        navigate('/users', { state: { "error": error.statusMessage } });
                    }
                })

            }
        }
    }, []);

    const verifyEmail = async () => {
        setUserExists('')
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setShowError(false)
        if (!email || email === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setUserExists('Please enter valid email address');
        } else {
            var param = {
                "email": email
            }
            setLoader(true);
            emailVerification(param).then(resp => {
                setLoader(false);
                if (resp.statusCode === "SUCCESS") {
                    window.location = resp.url;
                }
            }).catch(error => {
                setLoader(false);
                if (error.statusCode === "ERROR") {
                    navigate('/users', { states: error.statusMessage });
                }
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyEmail()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 >
                                        Email Verification
                                    </h1>
                                    <p>
                                        Provide email authorization for us to analyze your credit report!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 form-main-box'>
                            <div className='p-1'>
                                <div className='form-box row'>
                                    <div className='col-md-8 offset-md-2'>
                                        <h1 className='text-start'>
                                            Email Verification
                                        </h1>
                                        <p className='mt-md-3'>
                                            Provide email authorization for us to analyze
                                            your credit report!
                                        </p>
                                    </div>
                                    <div className='form-input-box col-md-8 offset-md-2 email-main-box'>
                                        <div className='form-inner-box my-1 my-md-5'>
                                            <div className='mb-1 mb-md-2'>
                                                <div className='label-label'>Email id </div>
                                            </div>
                                            <div>
                                                <input type="text" className='input-box form-control' value={email} onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {(showError && (!email || email === '')) && <span className="demo-tetx">Please enter your email</span>}
                                                {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                            </div>
                                        </div>
                                        <div className='form-inner-box my-1 my-md-5'>
                                            <div className='button-boxes email-button-box'>
                                                <div className='m-3 order-sm-first order-md-last'>
                                                    <button type='button' className='next-button' onClick={() => {
                                                        verifyEmail()
                                                    }}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default Email;