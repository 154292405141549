import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import config from './../../config.json'

const BSA_URL = config.bsaUrl;
const BSA_PROD_URL = config.bsaProdUrl;

const bsaLogin = (data, isFromProd) =>
    new Promise((resolve, reject) => {
        const uuid = uuidv4();
        axios.post(isFromProd ? `${BSA_PROD_URL}/api/v1/client/login` :
            `${BSA_URL}/api/v1/client/login`, data, {
            headers: { "x-reference-id": uuid }
        }).then(resp => resolve(resp.data))
            .catch(error => reject(error));

    });

export {
    bsaLogin
}