import '../../App.css';
import '../creddharma/creddharma.css'
import { useEffect, useState } from 'react';
import Footer from '../landing-page/Footer/Footer';
import MiddleContentCD from '../landing-page/Component/middle-cd';
import Loader from '../loader/loader';
import ErrorMessage from '../Dashboard/error-message/error-message';
import getSDKError from '../../Common/getSDKError';
import { reportDownload, downloadExcelReport} from '../../redux/actions/demo_sdk';

const Logo = require("../../images/assets/Logo.png");
const BMLOGO = require("../../images/assets/bm.png");
const PDFLogo = require("../../images/assets/pdf.png");
const ExcelLogo = require("../../images/assets/excel.png");


function Download(props) {
    
    const [loader, setLoader] = useState(false);
    
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    let message = '';

    const tokenParam = searchParams.get('token')
    const businessUserId = searchParams.get('businessUserId')
    const referenceId = searchParams.get('referenceId')
    const statusParam = tokenParam ? tokenParam.split('?status=') : ""
    const status = statusParam ? statusParam[1] : ""

    if(status === "SUCCESS"){
        message = {success: 'Your request processed successfully.'}
    }else if(status === "ERROR"){
        message = {error: 'We have encountered an error. Please try again. Reference Id :'+' '+ referenceId}  
    }else if(status === "CANCEL"){
        message = {error: 'User cancelled. Reference Id :'+' '+ referenceId}   
    }else if(status === "UNAUTHORIZED"){
        message = {error: 'Your token is expired. Please re initiate the request again. Reference Id :'+' '+ referenceId}   
    }else{
        message = {error: 'Something went wrong!'}    
    }

    const downloadReport = () => {

    if(status === "SUCCESS" ){
        message='';
        setLoader(true);
        const param = {
            businessUserId: businessUserId
        }
        reportDownload(param, referenceId)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                // Create a temporary <a> element to initiate the download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'boostreport.pdf';
                document.body.appendChild(a);
                a.click();
                // Cleanup the temporary URL and <a> element
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                message = {error: 'Error downloading the PDF file'}   
                console.error('Error downloading the PDF file:', error);
            })
        }
   }

    const downloadExcel = () => {

        if(status === "SUCCESS" ){
            message='';
            setLoader(true);
            const param = {
            businessUserId: businessUserId
            }
            downloadExcelReport(param, referenceId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'boost_transaction_report.xls';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    setLoader(false);
                }).catch(error => {
                    setLoader(false);
                    message = {error: 'Error downloading the Excel file'}  
                    console.error('Error downloading the Excel file:', error);
                })
        }
    }

    const btnEnableDisable = {
            opacity: status !== "SUCCESS" ? 0.5 : 1.0
    };
    
    return (
        <>
            {loader && <Loader/>}
            <div style={{ marginBottom: 0 }}>
                <div className='container-fluid mt-3'>
                    <div className='row g-3 mb-1 align-items-center ms-5'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-image logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
            </div>

             <section className='mt-2'>
                <ErrorMessage message={message} />
            </section>

            <section id='loginapi'>
                            <div className='container-lg container-fluid my-3 mt-4'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="acc-info-text" >Download Reports</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='account-accordion-box'>
                                                            <div className="accordion " id="accordionFlushExample">
                                                                <div className='row'>
                                                                       <div className='col-md-6'>
                                                                       <div className='account-details-box p-0 m-0 py-2 ps-2 pe-3'>
                                                                            <h4 className="guide-title mb-4">
                                                                                <img src={PDFLogo} /> <span className="ps-2">Boost Report{" "}</span>
                                                                            </h4>
                                                                            <p className="guidline-txt pb-2">
                                                                                Consolidated credit bureau and boost report with insights  in PDF format.
                                                                            </p>

                                                                            <div className="dwn-btn">
                                                                                <div className="py-4">
                                                                                    <div id="callApiOpenSdk" className="button-header" style={btnEnableDisable} onClick={() => downloadReport()}>Download Boost Report</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                       </div>
                                                                       <div className='col-md-6 box-space'>
                                                                       <div className='account-details-box p-0 m-0 py-2 ps-2 pe-3'>
                                                                            <h4 className="guide-title mb-4">
                                                                                        <img src={ExcelLogo} /> <span className="ps-2">Excel Report{" "}</span>
                                                                            </h4>
                                                                            <p className="guidline-txt pb-2">
                                                                            List of all your categorised bank transactions with date in Excel format.
                                                                            </p>

                                                                            <div className="dwn-btn">
                                                                                <div className="py-4">
                                                                                    <div id="callApiOpenSdk" className="button-header" style={btnEnableDisable} onClick={() => downloadExcel()}>Download Excel Report</div>
                                                                                </div>
                                                                            </div>
                                                                       </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

            <div className='footer-box bottom-0 position-fixed'>
                <Footer />
            </div>
        </>
    );
}

export default Download; 