import React from 'react';

const CardCounter = ({ logo, count, label, successCount, failCount }) => {
    return (
        // <div className="col-12 col-lg-3 col-md-3 col-sm-3 p-0 m-0 my-2 px-lg-2 px-md-2 px-sm-2">

        // </div>
        <div className="card-counter d-flex flex-row justify-content-starts align-items-centert">
            <div className="pe-1"> <img src={logo} alt="logo" className="admin-cards-icons" /></div>
            <div className="text-start ps-1 flex-grow-1">
                <p className="count-numbers p-0 m-0" >{count}</p>
                <p className="count-name p-0 m-0">{label}</p>
                <div className='d-flex flex-row p-0 m-0 align-items-center'>
                    <p className="count-status p-0 m-0">Success: <span className='success-text'>{successCount}</span></p>
                    <p className="count-status p-0 m-0 ps-1">Failed: <span className='error-text'>{failCount}</span></p>
                </div>
            </div>
        </div>

    );
};

export default CardCounter;
