import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactDialogBox } from 'react-js-dialog-box'
import AIInfo from '../AI-Info/ai-info';
import '../boost-ai.css'
import ChatAI from '../Chat/chatai';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';
import { getTokenApiBoostAI } from '../../../redux/actions/b2b_action';
import { getTokenApiBoostProdAI } from '../../../redux/actions/prod-b2b_action';
const BankBoostMoney = require("../../../images/assets/bm.png");
const AILink = require("../../../images/assets/ai-link.png");

function ChatIcon(props) {
    const navigate = useNavigate();
    const [showChatIcon, setShowChatIcon] = useState(false)  // Boost AI Icon
    const [showChatAIInfoBox, setShowChatAIInfoBox] = useState(false) // Manage Screen AI Info OR Chat AI

    const [showAIInfoBox, setShowAIInfoBox] = useState(false) // Manage Info Screen
    const [showGenAI, setShowGenAI] = useState(false) // Manage Gen AI Screen

    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')
    const [accessToken, setAccessToken] = useState('')


    useEffect(() => {
        setShowChatIcon(true)

    }, [])

    const getTokenAiB2B = (getToken, getTokenApiBoostAI, param) => {
        getToken(() => {
            getTokenApiBoostAI(param)
                .then(resp => {
                    if (resp != null) {
                        if (resp.access_token != null && resp.access_token != '') {
                            //console.log('getTokenApiBoostAI : ' + resp.access_token)
                            setAccessToken(resp.access_token);
                        }
                    }
                    //console.log('getTokenApiBoostAI : ', resp); // Handle error
                })
                .catch(error => {
                    console.error('Error:', error); // Handle error
                });
        }, errorMessage => {
            console.error('Error:', errorMessage); // Handle error
        });
    };

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const openChatAIInfo = () => {
        const param = {
            businessUserId: props.businessId
        }
        if (props.openFromProd) {
            getTokenAiB2B(getCommonProdClientTokenB2B, getTokenApiBoostProdAI, param)
        } else {
            getTokenAiB2B(getCommonUATClientTokenB2B, getTokenApiBoostAI, param)
        }
        setShowChatAIInfoBox(true)
        setShowAIInfoBox(true)
        setShowChatIcon(false)
    }

    const closeInfoDialog = () => {
        setShowChatAIInfoBox(false)
        setShowChatIcon(true);

        setShowAIInfoBox(false)
        setShowGenAI(false)

        setDialogTitle('BOOST AI')
        setDialogHeight('auto')
    }

    return (
        <>
            {showChatIcon && <div className='chat-box' onClick={() => { openChatAIInfo() }}>
                <img src={AILink} alt="DropdownHamburger"
                    id="dropdownMenuButton"
                    className="drop-down-hamburger" />
            </div>}

            {showChatAIInfoBox && (
                <div className='chat-dialog'>
                    <ReactDialogBox
                        headerBackgroundColor='white'
                        headerTextColor='black'
                        closeButtonColor='black'
                        bodyBackgroundColor='white'
                        bodyTextColor='black'
                        bodyHeight={dialogHeight}
                        borderRadius='8px'
                        headerHeight='65px'
                        headerText="BOOST AI"
                        closeBox={closeInfoDialog}
                    >

                        {showAIInfoBox &&
                            <AIInfo name={props.name} setShowChatAIInfoBox={setShowChatAIInfoBox} setShowChatIcon={setShowChatIcon} setShowAIInfoBox={setShowAIInfoBox} setShowGenAI={setShowGenAI}></AIInfo>
                        }

                        {showGenAI &&
                            <ChatAI openFromProd={props.openFromProd} token={accessToken} closeInfoDialog={closeInfoDialog}></ChatAI>
                        }
                    </ReactDialogBox> </div>)}
        </>
    );
}

export default ChatIcon;