import './loader.css';
const LoaderImg = require("../../images/assets/loader.gif");
function Loader() {

    return (
        <>
            {/* <div className="loading">Loading&#8230;</div> */}
            <div className="image-container">
                <img src={LoaderImg} alt="loading" className="img-loading" />
            </div>
        </>
    );
}

export default Loader;