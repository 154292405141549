import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './credit-report.css'
import '../../../App.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Loader from '../../loader/loader';
import { creditReportService } from '../../../redux/actions/b2b_action';
import { creditReportServiceProd } from '../../../redux/actions/prod-b2b_action';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import { getCommonProdClientTokenB2B } from '../../Component/GenetateProdClientTokenB2B';

const CreditReport = (props) => {

    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedBureau, setSelectedBureau] = useState();
    const [bureauError, setBureauError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [showError, setShowError] = useState(false);
    const [uploadError, setuploadError] = useState()


    const [creditBureau, setCreditBureau] = useState([{
        label: 'Experian',
        value: 'EXPERIAN'
    },
    {
        label: 'TransUnion - CIBIL',
        value: 'CIBIL'
    },
    {
        label: 'CRIF',
        value: 'CRIF'
    },
    {
        label: 'Equifax',
        value: 'EQUIFAX'
    }]);

    const onBureauChange = (value) => {
        setSelectedBureau(value.value)
    }

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const skipBtn = (event) => {
        props.setIsOpenBankStmDialog(true)
        props.setIsOpenDialog(false)
        // props.closeDialog()
    }


    const sumbitCreditBureau = () => {
        setShowError(false)

        if (!selectedBureau || selectedBureau === '' || !selectedFiles || selectedFiles === '') {
            setShowError(true)
        } else {

            if (props.openFromProd) {
                fetchCreditReport(getCommonProdClientTokenB2B, selectedFiles, selectedBureau, props, pwd);
            } else {
                fetchCreditReport(getCommonUATClientTokenB2B, selectedFiles, selectedBureau, props, pwd);
            }
            // getCommonUATClientTokenB2B(
            //     async () => {
            //         var param = new FormData();
            //         param.append("file", selectedFiles[0]);
            //         param.append("bureauName", selectedBureau);
            //         param.append("businessUserId", props.businessId);
            //         param.append("isFromSDK", false);
            //         param.append("password", pwd);
            //         const uuid = uuidv4();

            //         setLoader(true)
            //         creditReportService(param, uuid).then(resp => {
            //             setLoader(false)
            //             if (resp.statusCode === "SUCCESS") {
            //                 if (props.businessId !== "") {
            //                     props.setIsOpenBankStmDialog(true)
            //                 }
            //             }
            //             props.closeDialog()
            //             props.getTestUserList()
            //             props.setMessage({ success: resp.statusMessage })
            //             props.scrollToTop()
            //             window.scrollTo({
            //                 top: 0,
            //                 behavior: "smooth",
            //             });
            //         }).catch((error) => {
            //             setLoader(false);
            //             if (error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404") {
            //                 setuploadError(error.errorMessage);
            //             }
            //         })
            //     },
            //     (errorMessage) => {
            //         console.error('Error:', errorMessage); // Handle error
            //     }
            // );

        }
    }

    const fetchCreditReport = async (getToken, selectedFiles, selectedBureau, props, pwd) => {
        try {
            setuploadError("");
            await getToken(async () => {
                const param = new FormData();
                param.append("file", selectedFiles[0]);
                param.append("bureauName", selectedBureau);
                param.append("businessUserId", props.businessId);
                param.append("isFromSDK", false);
                param.append("password", pwd);
                const uuid = uuidv4();

                setLoader(true);
                let resp;

                try {
                    resp = props.openFromProd
                        ? await creditReportServiceProd(param, uuid)
                        : await creditReportService(param, uuid);
                } catch (axiosError) {
                    setLoader(false);

                    if (axiosError.response) {
                        const errorData = axiosError.response.data;
                        if (errorData.errorCode) {

                            setuploadError(errorData.errorMessage);
                            return;
                        } else {
                            setuploadError('An error occurred while uploading the report.');
                            return;
                        }
                    } else if (axiosError.request) {
                        console.log("axiosError response " + axiosError.request)
                        setuploadError('No response received from server.');
                        return;
                    } else {
                        // Other errors
                        setuploadError('An error occurred while uploading the report.');
                        return;
                    }
                }

                setLoader(false);

                if (resp.statusCode === "SUCCESS") {
                    if (props.businessId !== "") {
                        props.setIsOpenBankStmDialog(true);
                    }
                } else {
                    // Handle different response codes if needed
                    throw new Error(resp.statusMessage || 'Unknown error');
                }

                props.closeDialog();
                props.getTestUserList();
                props.setMessage({ success: resp.statusMessage });
                // props.scrollToTop();
                // window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                // });
            }, (errorMessage) => {
                setLoader(false);
            });
        } catch (error) {
            setLoader(false);
            if (error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_400" || error?.errorCode === "B2B_RESOURCE_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404" || error?.errorCode === "B2B_UPLOAD_CREDIT_REPORT_404") {
                setuploadError(error.errorMessage);
            }
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className='container'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>
                    <div className="col-12">
                        <div htmlFor="validationCustom04" className="label-label mb-2">Credit Bureau</div>
                        <Dropdown options={creditBureau} onChange={(value) => {
                            onBureauChange(value)
                        }} value={selectedBureau} placeholder="Select credit bureau" />
                        {(showError && (!selectedBureau || selectedBureau === '')) && <span className="demo-tetx">Please select your credit bureau</span>}
                    </div>
                    <div className="col-12 my-2">
                        <div className="label-label mb-2">Upload File</div>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please upload file</span>}
                    </div>
                    <div className='col-12 '>
                        <div className='label-label mb-2'>Password</div>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter Password"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>
                    <div>
                        <p className='demo-tetx'>{bureauError === undefined ? '' : bureauError}</p>
                    </div>
                    <div>
                        <p className='demo-tetx'>{uploadError != "" ? uploadError : ''}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className='skip-link ps-1' onClick={() => {
                            skipBtn()
                        }}>Skip</p>
                        <button className="upload-statement-butoon me-0" type="button" onClick={() => {
                            sumbitCreditBureau()
                        }} >Continue</button>
                    </div>
                </form>}
            </div>
        </>
    )
}

export default CreditReport;