import React, { useState, useRef, forwardRef } from "react";

import '../../Component/download-report-btn/download-report-btn.css'
import DLReportOptionItems from "../../Component/download-report-btn/dl-report-option-items";
import withClickOutside from "../../Component/download-report-btn/WithClickOutside";
const DL_IC = require('../../../images/assets/dl_ic.png');
const SHARE_IC = require('../../../images/assets/share_ic.png');

const DownloadInvoiceBtn = forwardRef(({ showDropDown, setShowDropDown, btnLabel, clickOnDownload, clickOnExcel }, ref) => {
    const clickDownload = (e) => {
        setShowDropDown(!showDropDown);
        clickOnDownload(e);
    }
    const clickExcel = (e) => {
        setShowDropDown(!showDropDown);
        clickOnExcel(e);
    }
    return (
        <section ref={ref}>
            <div className="dropdown" >
                <div className='download-btn-dropdown dropdown-toggle'
                    onClick={() => setShowDropDown(!showDropDown)}
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}>
                    {btnLabel}
                </div>
                <div className={"dropdown-menu" + (showDropDown ? " show" : "")} aria-labelledby="dropdownMenuButton">
                    <div className="dl-report-options">
                        <DLReportOptionItems icon={DL_IC} label="Invoice PDF" subline="Download invoice report in PDF format."
                            setClickOnItem={(e) => {
                                clickDownload(e)
                            }}>
                        </DLReportOptionItems>
                        <div className="dl-break-line"></div>
                        <DLReportOptionItems icon={DL_IC} label="Invoice Excel" subline="List of all your invoice records
                                    in Excel format."
                            setClickOnItem={(e) => {
                                clickExcel(e)
                            }}>
                        </DLReportOptionItems>

                    </div>
                </div>
            </div>
        </section >
    );
});

export default withClickOutside(DownloadInvoiceBtn);